import { ActionIcon, Button, Collapse, Group, Tabs, Text } from "@mantine/core";
import { IconFilter, IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { HistoryList } from "../../components/resource/history";
import { MovementList } from "../../components/resource/movement";
import { RemainderList } from "../../components/resource/remainder";
import { SettingsList } from "../../components/resource/settings";
import { useScreenDetector } from "../../components/screen-detector";

export const ResourceList = () => {
  const ERP_STOCK = usePermission("ERP_STOCK");
  const ERP_STOCK_ADJ = usePermission("ERP_STOCK_ADJ");
  const ERP_STOCK_MVM = usePermission("ERP_STOCK_MVM");

  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState<string>(location.state?.key || "1");
  const { isMobile } = useScreenDetector();

  const title = [
    {
      key: "1",
      title: "Үлдэгдэл нөөц",
      subTitle: "Бараа бүтээгдэхүүний одоогийн үлдэгдэлийн мэдээлэл",
    },
    {
      key: "2",
      title: "Нөөц хөдөлгөөн",
      subTitle: "Нөөц хөдөлгөөн",
    },
    {
      key: "3",
      title: "Нөөцийн үлдэгдэл тохиргоо",
      subTitle: "Барааны нөөцийн үлдэгдэлд тохируулга хийх",
    },
    {
      key: "4",
      title: "Нөөцийн түүх",
      subTitle: "Сонгосон барааны нөөцийн түүхийг харах",
    },
  ];
  console.log(location.state?.key);

  return (
    <PageLayout
      title={title.find((item: any) => item.key === key)?.title || ""}
      subTitle={title.find((item: any) => item.key === key)?.subTitle || ""}
      breadcrumb={breadcrumbs}
      extra={[
        <div key={0}>
          {
            <Group hidden={!ERP_STOCK_ADJ.isCreate}>
              {!isMobile ? (
                <Collapse in={key === "3"}>
                  <Button onClick={() => navigate("/resource/new")}>Шинэ тохируулга</Button>
                </Collapse>
              ) : (
                <ActionIcon disabled={key !== "3"} size={36} color="" variant="filled">
                  <IconPlus />
                </ActionIcon>
              )}
              <ActionIcon size={36} color="" variant="light">
                <IconFilter />
              </ActionIcon>
            </Group>
          }
        </div>,
      ]}>
      <Tabs
        value={key}
        onChange={(e: string | null) => {
          if (e) setKey(e);
        }}
        defaultValue="1">
        <Tabs.List>
          <Tabs.Tab py="md" value="1">
            <Text fw={500} c={key === "1" ? "violet" : ""}>
              Үлдэгдэл нөөц
            </Text>
          </Tabs.Tab>
          <Tabs.Tab py="md" value="3">
            <Text fw={500} c={key === "3" ? "violet" : ""}>
              Үлдэгдэл тохиргоо
            </Text>
          </Tabs.Tab>
          <Tabs.Tab py="md" value="4">
            <Text fw={500} c={key === "4" ? "violet" : ""}>
              Нөөцийн түүх
            </Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="1" pt="xs">
          {key === "1" && ERP_STOCK.hasAccess ? <RemainderList /> : ERP_STOCK.accessDenied()}
        </Tabs.Panel>
        <Tabs.Panel value="2" pt="xs">
          {key === "2" && <MovementList />}
        </Tabs.Panel>
        <Tabs.Panel value="3" pt="xs">
          {key === "3" && ERP_STOCK_ADJ.hasAccess ? <SettingsList /> : ERP_STOCK_ADJ.accessDenied()}
        </Tabs.Panel>
        <Tabs.Panel value="4" pt="xs">
          {key === "4" && ERP_STOCK_MVM.hasAccess ? <HistoryList /> : ERP_STOCK_MVM.accessDenied()}
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/recourse",
    label: "Барааны нөөц",
  },
];
