import { Paper, Tabs } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useNavigate } from "react-router";
import { GoodsApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { PackageForm } from "../../components/package-product/form";
import { usePermission } from "../../components/permission";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export const EditPackage = () => {
  const { theme } = useStyle();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabIndex] = React.useState("1");
  const ERP_GDS = usePermission("ERP_GDS");

  const onMainSubmit = async (data: any, setLoading: React.Dispatch<React.SetStateAction<boolean>>, isNext: boolean) => {
    try {
      setLoading(true);
      const res = await GoodsApi.create({
        ...data,
        coverImages: data?.coverImages || [
          {
            isMain: true,
            url: "/",
          },
        ],
        detailImages: data?.detailImages || ["/"],
      });

      if (isNext) navigate(`/product/edit/${res.id}/2`);
      else navigate("/product");
      setLoading(false);
      message.success("Амжилттай бараа нэмлээ!");
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <PageLayout title="Багц засах" subTitle={"Бараа багц бүтээгдэхүүн засах"} breadcrumb={breadcrumbs} bgColor={theme.colors.gray[0]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={tabIndex}>
          <Tabs.List>
            <Tabs.Tab value="1">Үндсэн мэдээлэл</Tabs.Tab>
            <Tabs.Tab value="2">Динамик мэдээлэл</Tabs.Tab>
            <Tabs.Tab value="3">Захиалга тохиргоо</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">{ERP_GDS.isCreate ? <PackageForm onSubmit={onMainSubmit} editable={["create"]} /> : ERP_GDS.accessDenied()}</Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
};

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/product",
    label: "Бүтээгдэхүүн",
  },
  {
    label: "Багц засах",
  },
];
