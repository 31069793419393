import { Badge, Button, CloseButton, Group, LoadingOverlay, Modal, MultiSelect, Paper, Space, Tabs, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { OptionApi } from "../../apis";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { FormLayout, PageLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";

import * as yup from "yup";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { TextField } from "../form/text-field";
import { usePermission } from "../permission";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна.").nullable(),
  isGoods: yup.boolean(),
  isService: yup.boolean(),
  values: yup.array().of(yup.string().required("Заавал бөглөнө!")).min(2, "Бага даа 2 утга оруула уу!").required("Заавал бөглөнө!"),
});

type IForm = {
  name: string;
  isGoods: boolean;
  isService: boolean;
  values: string[];
};

export function OptionPage() {
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState({ query: "", isGoods: true, isService: false });
  const [action, setAction] = React.useState<any[]>([]);
  const ref = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState(false);
  const ERP_REF_OPTION = usePermission("ERP_REF_OPTION");

  const [optionData, setOptionData] = useState<any>([]);

  const [data] = React.useState<any>({
    id: null,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    businessId: null,
    name: null,
    isGoods: true,
    isService: false,
    isPublic: true,
    isDehub: true,
    values: [],
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (action[1]) {
        await OptionApi.update(action[1]?.id, { name: data.name, isGoods: data.isGoods, isService: data?.isService, values: data?.values || [] });
      } else await OptionApi.create({ name: data.name, isGoods: data.isGoods, isService: data?.isService, values: data?.values || [] });
      message.success("Үйлдэл амжилттай");
      reloadTable && reloadTable();
      onCancel && onCancel();
    } catch (error: any) {
      message.error(error.message || "Үйлдэл амжилтгүй.");
    }
    setLoading(false);
  };

  const columns = useHeader({
    actionAccess: ERP_REF_OPTION,
    onClick: async (key, item: any) => {
      switch (key) {
        case "edit": {
          let res = await OptionApi.get(item.id);
          console.log(res);

          setAction(["update", { ...res, values: res.values.map((i: any) => i.name) }]);
          setOptionData(
            res?.values?.map((item: any) => {
              return {
                label: `${item.name}`,
                value: `${item.name}`,
              };
            }) || [],
          );
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await OptionApi.remove(item.id);
                  ref.current?.reload();
                  message.success("Амжилттай устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reloadTable = () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбар"
        subTitle="Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбар "
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference")}>
            Буцах
          </Button>,
          <Button
            hidden={!ERP_REF_OPTION.isCreate}
            key={2}
            onClick={() => {
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Paper withBorder mt={"xs"}>
          <Tabs
            onChange={(e) => {
              setFilters({ ...filters, isGoods: e === "goods", isService: e === "service" });
            }}
            py={"sm"}
            value={filters.isGoods ? "goods" : "service"}>
            <Tabs.List>
              <Tabs.Tab value="goods">Бараа бүтээгдэхүүн хувилбар</Tabs.Tab>
              <Tabs.Tab value="service">ажил үйлчилгээний хувилбар</Tabs.Tab>
            </Tabs.List>
          </Tabs>
          {ERP_REF_OPTION.isView ? (
            <Table minWidth={"max-content"} ref={ref} name="option.list" columns={columns} filters={filters} loadData={(data) => OptionApi.list(data!)} />
          ) : (
            ERP_REF_OPTION.accessDenied()
          )}
        </Paper>
      </PageLayout>

      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} size="70rem" centered>
        {action[0] && (
          <Form validationSchema={schema} onSubmit={onSubmit} initialValues={{ ...data, values: data?.values || [], ...(action[1] && action[1]) }}>
            {({ values, setFieldValue, errors }) => {
              return (
                <FormLayout
                  title="Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбар"
                  subTitle="Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбар мэдээлэл оруулна уу."
                  my={0}
                  extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
                  <LoadingOverlay visible={loading} />
                  <TextField name="name" label="Нэр" placeholder="Нэр" />
                  <Space h={"xs"} />
                  <MultiSelect
                    error={errors.values}
                    clearable
                    value={values?.values?.map((item: any) => item) || []}
                    label="Сонголтын төрөл нэмэх"
                    data={optionData}
                    placeholder="Сонгох"
                    nothingFoundMessage="Олдсонгүй"
                    searchable
                    onOptionSubmit={(query) => {
                      const item = { value: query || "", label: query || "" };
                      setOptionData((current: any) => [...current, item]);
                      return item;
                    }}
                    onChange={(e) => {
                      setFieldValue("values", e || []);
                    }}
                  />

                  <Space h={"xs"} />
                  <CheckboxField label="Бараа бүтээгдэхүүн эсэх" name="isGoods" />
                  <Space h={"xs"} />
                  <CheckboxField label="ажил үйлчилгээ эсэх" name="isService" />

                  <Group justify="right" mt={20}>
                    <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                      Болих
                    </Button>
                    <Button key={2} type="submit">
                      Хадгалах
                    </Button>
                  </Group>
                </FormLayout>
              );
            }}
          </Form>
        )}
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IForm) => void;
  actionAccess: {
    isDelete: boolean;
    isEdit: boolean;
    isView: boolean;
  };
};

const useHeader = ({ onClick, actionAccess }: HeaderProps): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бараа эсэх",
    dataIndex: "isGoods",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isGoods ? "green.5" : "gray.5"}>
          {record.isGoods ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "ажил үйлчилгээ эсэх",
    dataIndex: "isService",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isService ? "green.5" : "gray.5"}>
          {record.isService ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Нээлттэй",
    dataIndex: "isPublic",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isPublic ? "green.5" : "gray.5"}>
          {record.isPublic ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "DeHub",
    dataIndex: "isDehub",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isDehub ? "green.5" : "gray.5"}>
          {record.isDehub ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Group gap={10}>
          <Tooltip label="Засах">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} hidden={!actionAccess.isEdit}>
              <IconEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Устгах">
            <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)} hidden={!actionAccess.isDelete}>
              <IconTrash />
            </Button>
          </Tooltip>
        </Group>
      );
    },
  },
];
