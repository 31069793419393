import { Box, Button, FileButton, Image, LoadingOverlay } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconPhotoPlus } from "@tabler/icons-react";
import React from "react";
import { MediaApi } from "../../apis";
import { message } from "../../utils/message";

export type IFile = {
  uploading: boolean;
  url?: string;
  file: Blob | null;
};

type Props = {
  value?: string | undefined | null;
  error?: string;
  onChange?: (file: IFile) => void;
  width?: any;
  height?: any;
  icon?: any;
};

export function ImageUpload({ onChange, error, value, width, height, icon }: Props) {
  const { classes, cx } = useStyle();
  const [file, setFile] = React.useState<IFile>();
  const resetRef = React.useRef<() => void>(null);

  const onFileUpload = (blob: Blob | null) => {
    if (!blob) return;

    if (blob.size < 10485760) {
      (async () => {
        try {
          const form = new FormData();
          form.append("file", blob);

          const res = await MediaApi.uploadImage(form);

          if (onChange)
            onChange({
              file: blob,
              url: res.url,
              uploading: false,
            });

          setFile({
            file: blob,
            url: res.url,
            uploading: false,
          });
        } catch (err) {
          console.log(err);
        }
      })();

      setFile({
        file: blob,
        uploading: true,
      });
    } else {
      message.error("Файлын хэмжээ хэтэрсэн байна!");

      setFile({
        file: blob,
        uploading: false,
      });
    }
  };

  React.useEffect(() => {
    value &&
      setFile({
        file: null,
        url: value,
        uploading: false,
      });
  }, [value]);

  console.log(file?.file);

  return (
    <div className={classes.multiImageUpload}>
      <FileButton resetRef={resetRef} onChange={onFileUpload} accept="image/png,image/jpeg">
        {(props) => {
          if (file)
            return (
              <Box className={cx(classes.imageBox)} {...props}>
                <Image className={classes.image} width={width || 80} height={height || 80} src={file.file ? `${file.file}` : value} alt="" />
                <LoadingOverlay visible={file.uploading} opacity={0.3} loaderProps={{ size: "sm" }} />
              </Box>
            );
          else
            return (
              <Button variant="light" className={cx(classes.uploadButton, error && "error")} {...props}>
                <IconPhotoPlus size={20} />
              </Button>
            );
        }}
      </FileButton>
    </div>
  );
}

const useStyle = createStyles((theme, _params, getRef) => ({
  multiImageUpload: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 5,
    gap: 15,
  },
  imageBox: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    height: 80,
    cursor: "pointer",
    borderRadius: 4,
    overflow: "hidden",
    "&.selected": {
      borderWidth: 3,
      borderStyle: "solid",
      borderColor: theme.colors.indigo[6],
    },
    [`&:hover .${"actionBox"}`]: {
      display: "flex!important",
    },
  },
  image: {
    position: "absolute",
  },
  uploadButton: {
    width: 80,
    height: 80,
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "&.error": {
      backgroundColor: theme.colors.red[1],
      color: theme.colors.red[6],
    },
  },
}));
