/* eslint-disable react-hooks/exhaustive-deps */
import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Grid, Group, Modal, Paper, Table, Text, TextInput, Tooltip } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { randomId } from "@mantine/hooks";
import { IconCopy, IconDeviceFloppy, IconEdit, IconPhoto, IconTrash, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import * as yup from "yup";
import { VariantApi } from "../../apis";
import { Dialog } from "../../utils/confirm-modal";
import { message } from "../../utils/message";
import { Form } from "../form";
import { ImageFieldProduct } from "../form/image-field-product";

type IProps = {
  reload: () => Promise<void>;
  variantUpdate: {
    refCode: string;
    goodsId: string;
    skuCode: string;
    barCode: string;
    erpCode: string;
    nameApp: string;
    nameWeb: string;
    nameBill: string;
    image: string;
    status: string;
    isEdit: string;
    optionValues: {
      id: string;
      optionId: string;
      name: string;
    }[];
  }[];
  options: {
    id: string;
    name: string;
    values: {
      optionId: string;
      id: string;
      name: string;
    }[];
  }[];
};

type IOptionValuesItem = {
  optionId: null | string;
  name: null | string;
};

type IForm = {
  variants: {
    isLoading: boolean;
    id?: null | string | undefined;
    status: null | string;
    refCode: null | string;
    goodsId: null | string;
    skuCode: null | string;
    barCode: null | string;
    erpCode: null | string;
    nameApp: null | string;
    nameWeb: null | string;
    nameBill: null | string;
    image: null | string;
    isEdit: boolean;
    optionValues: IOptionValuesItem[];
  }[];
};

const schema = Yup.object().shape({
  variants: Yup.array()
    .of(
      Yup.object().shape({
        isEdit: Yup.boolean(),
        refCode: Yup.string().optional(),
        goodsId: Yup.string().when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required() : schema.notRequired();
        }),
        skuCode: Yup.string().when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required() : schema.notRequired();
        }),
        barCode: Yup.string().when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required() : schema.notRequired();
        }),
        erpCode: Yup.string().when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required() : schema.notRequired();
        }),
        nameApp: Yup.string().when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required() : schema.notRequired();
        }),
        nameWeb: Yup.string().when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required() : schema.notRequired();
        }),
        nameBill: Yup.string().nullable().optional(),
        image: Yup.string().nullable().optional(),
      }),
    )
    .required("Required"),
});

const VariantsTable: React.FC<IProps> = React.memo(({ variantUpdate, options, reload }) => {
  const params = useParams();
  const [action, setAction] = useState<any[]>([]);
  const [copy, setCopy] = useState<{
    skuCode: string | number | readonly string[] | undefined;
    barCode: string | number | readonly string[] | undefined;
    erpCode: string | number | readonly string[] | undefined;
    nameApp: string | number | readonly string[] | undefined;
    nameWeb: string | number | readonly string[] | undefined;
    nameBill: string | number | readonly string[] | undefined;
  }>({
    skuCode: "",
    barCode: "",
    erpCode: "",
    nameApp: "",
    nameWeb: "",
    nameBill: "",
  });

  const form = useForm<IForm>({
    initialValues: {
      variants: [],
    },
    validate: yupResolver(schema),
  });

  const areArraysEqual = (arr1: any[], arr2: any[]) => {
    return arr1.length === arr2.length && arr1.every((obj1) => arr2.some((obj2) => Object.keys(obj1).every((key) => obj1[key] === obj2[key])));
  };

  useEffect(() => {
    const tempArr: [IOptionValuesItem][] = options
      ?.map((item: any) => {
        return [...item.values];
      })
      ?.reduce((accumulator: any, currentArray) => accumulator.flatMap((el1: any) => currentArray.map((el2) => [...el1, el2])), [[]]);

    let test: any[] = tempArr?.map((item: IOptionValuesItem[]) => {
      const randId = randomId();

      if (
        variantUpdate &&
        variantUpdate?.find((som: any) =>
          areArraysEqual(
            som?.optionValues?.map((item: any) => ({ name: item.name, optionId: item.optionId })) || [],
            item?.map((item: any) => ({ name: item.name, optionId: item.optionId })) || [],
          ),
        )
      ) {
        return {
          ...variantUpdate?.find((som: any) =>
            areArraysEqual(
              som.optionValues.map((item: any) => ({ name: item.name, optionId: item.optionId })),
              item.map((item: any) => ({ name: item.name, optionId: item.optionId })),
            ),
          ),
        };
      } else
        return {
          id: "new: " + randId,
          status: "DRAFT",
          refCode: "",
          goodsId: params.id,
          skuCode: "",
          barCode: "",
          erpCode: "",
          nameApp: "",
          nameWeb: "",
          nameBill: "",
          image: "",
          isEdit: false,
          isLoading: false,
          optionValues: item.map((item: any) => ({ name: item.name, optionId: item.optionId })),
        };
    });

    const uniqueMap = new Map();

    [...(variantUpdate || []), ...(test || [])]?.forEach((item: any) => uniqueMap.set(item.id, item));

    const uniqueArray = Array.from(uniqueMap.values());

    form.setFieldValue(`variants`, uniqueArray);
  }, [options, params.id, variantUpdate]);

  const onSubmit = async (index: number) => {
    let isValid = form.validate();

    if (!isValid.hasErrors) {
      const isNew = `${form.values.variants[index].id}`.split(": ")[0];
      const data = form.values.variants[index];

      if (isNew === "new") {
        let res = await VariantApi.add({
          goodsId: data?.goodsId || "",
          id: "",
          image: data?.image || "",
          optionValues: data?.optionValues?.map((item) => ({ optionId: item.optionId, name: item.name })) || [],
          skuCode: data?.skuCode || "",
          barCode: data?.barCode || "",
          erpCode: data?.erpCode || "",
          nameApp: data?.nameApp || "",
          nameWeb: data?.nameWeb || "",
          nameBill: data?.nameBill || "",
        });
        console.log("created: ", res);
      } else {
        let res = await VariantApi.add({
          goodsId: data?.goodsId || "",
          id: data.id,
          image: data?.image || "",
          optionValues: data?.optionValues?.map((item) => ({ optionId: item.optionId, name: item.name })) || [],
          skuCode: data?.skuCode || "",
          barCode: data?.barCode || "",
          erpCode: data?.erpCode || "",
          nameApp: data?.nameApp || "",
          nameWeb: data?.nameWeb || "",
          nameBill: data?.nameBill || "",
        });
        console.log("updated: ", res);
      }
      await reload();
      message.success("Амжилттай хадгаллаа!");
      form.setFieldValue(`variants.${index}.isEdit`, false);
    } else console.log("isValid: ", isValid);
  };

  const tableActions = async (key: string, index: number) => {
    switch (key) {
      case "save":
        form.setFieldValue(`variants.${index}.isLoading`, true);
        onSubmit(index);
        form.setFieldValue(`variants.${index}.isLoading`, false);
        break;
      case "edit":
        await form.clearErrors();
        form.setFieldValue(
          `variants`,
          form.values?.variants?.map((item2: any, index2: number) => {
            return {
              ...item2,
              isEdit: index === index2,
            };
          }),
        );
        break;
      case "cancel":
        form.setFieldValue(`variants.${index}.isEdit`, false);
        await form.clearErrors();

        break;
      case "remove":
        Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
          switch (key) {
            case "confirm": {
              try {
                await VariantApi.remove(form.values.variants[index].id);
                message.success("Үйлдэл амжилттай.");
                await reload();
              } catch (error: any) {
                message.error(error?.message || "Үйлдэл амжилтгүй!");
              }
              break;
            }
            default:
          }
        });

        break;

      default:
        break;
    }
  };

  const rows = form.values?.variants?.map((element, index) => (
    <tr key={index}>
      <td>
        <Flex gap={"sm"}>
          {!form.values.variants[index].isEdit ? (
            <>
              <ActionIcon onClick={() => tableActions("edit", index)} variant="light" color="">
                <IconEdit size={"1.2rem"} />
              </ActionIcon>
              {element.status === "ACTIVE" && (
                <ActionIcon
                  onClick={() => tableActions("remove", index)}
                  variant="light"
                  color="red"
                  disabled={form.values.variants.some((item: any) => item?.isEdit || false)}>
                  <IconTrash size={"1.2rem"} />
                </ActionIcon>
              )}
            </>
          ) : (
            <>
              <ActionIcon onClick={() => tableActions("save", index)} variant="light" color="" loading={form?.values?.variants[index]?.isLoading}>
                <IconDeviceFloppy size={"1.2rem"} />
              </ActionIcon>
              <ActionIcon onClick={() => tableActions("cancel", index)} variant="light" color="gray">
                <IconX size={"1.2rem"} />
              </ActionIcon>
            </>
          )}
        </Flex>
      </td>
      <td>
        <Badge color={element.status === "ACTIVE" ? "green" : "gray"} variant="outline">
          {element.status === "ACTIVE" ? "Идэвхтэй" : "Идэвхгүй"}
        </Badge>
      </td>
      <td>
        <Tooltip label={form?.values?.variants[index].isEdit ? "Солих" : "Үзэх"}>
          <ActionIcon
            onClick={() => {
              setAction(["upload", index]);
            }}>
            <Avatar src={form?.values?.variants[index].image}>
              <IconPhoto />
            </Avatar>
          </ActionIcon>
        </Tooltip>
      </td>
      <td>
        <Flex gap="sm" align="center">
          {form?.values?.variants[index]?.optionValues?.map((item: any, index: number) => {
            return <Badge key={index}>{item.name}</Badge>;
          })}
        </Flex>
      </td>
      <td>{form?.values?.variants[index]?.refCode}</td>
      <td>
        <TextInput
          size="xs"
          placeholder="skuCode"
          {...form.getInputProps(`variants.${index}.skuCode`)}
          disabled={!form?.values?.variants[index]?.isEdit || false}
        />
      </td>
      <td>
        <TextInput
          size="xs"
          placeholder="barCode"
          {...form.getInputProps(`variants.${index}.barCode`)}
          disabled={!form?.values?.variants[index]?.isEdit || false}
        />
      </td>
      <td>
        <TextInput
          size="xs"
          placeholder="erpCode"
          {...form.getInputProps(`variants.${index}.erpCode`)}
          disabled={!form?.values?.variants[index]?.isEdit || false}
        />
      </td>
      <td>
        <TextInput
          size="xs"
          placeholder="nameApp"
          {...form.getInputProps(`variants.${index}.nameApp`)}
          disabled={!form?.values?.variants[index]?.isEdit || false}
        />
      </td>
      <td>
        <TextInput
          size="xs"
          placeholder="nameWeb"
          {...form.getInputProps(`variants.${index}.nameWeb`)}
          disabled={!form?.values?.variants[index]?.isEdit || false}
        />
      </td>
      <td>
        <TextInput
          size="xs"
          placeholder="nameBill"
          {...form.getInputProps(`variants.${index}.nameBill`)}
          disabled={!form?.values?.variants[index]?.isEdit || false}
        />
      </td>
    </tr>
  ));

  return (
    <Paper>
      <Divider />
      <Grid>
        <Grid.Col>
          <Box style={{ overflowX: "auto" }} py="xs">
            <Table w="100%" miw="max-content">
              <thead>
                <tr>{th}</tr>
              </thead>

              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <TextInput
                      value={copy.skuCode}
                      onChange={async (e) => {
                        setCopy({ ...copy, skuCode: e.target.value });
                      }}
                      size="xs"
                      placeholder="skuCode"
                      rightSectionWidth={30}
                      rightSection={
                        <ActionIcon
                          onClick={async () => {
                            form.setFieldValue(
                              "variants",
                              form.values?.variants?.map((item: any) => {
                                return {
                                  ...item,
                                  skuCode: copy.skuCode,
                                };
                              }),
                            );
                            if (copy.skuCode) await form.clearFieldError(`variants.${form.values.variants.findIndex((item: any) => item.isEdit)}.skuCode`);
                          }}
                          size={"md"}
                          color=""
                          radius={3}
                          variant="subtle">
                          <IconCopy />
                        </ActionIcon>
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      value={copy.barCode}
                      onChange={async (e) => {
                        setCopy({ ...copy, barCode: e.target.value });
                      }}
                      size="xs"
                      placeholder="barCode"
                      rightSectionWidth={30}
                      rightSection={
                        <ActionIcon
                          onClick={async () => {
                            form.setFieldValue(
                              "variants",
                              form.values?.variants?.map((item: any) => {
                                return {
                                  ...item,
                                  barCode: copy.barCode,
                                };
                              }),
                            );
                            if (copy.barCode) await form.clearFieldError(`variants.${form.values.variants.findIndex((item: any) => item.isEdit)}.barCode`);
                          }}
                          size={"md"}
                          color=""
                          radius={3}
                          variant="subtle">
                          <IconCopy />
                        </ActionIcon>
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      value={copy.erpCode}
                      onChange={async (e) => {
                        setCopy({ ...copy, erpCode: e.target.value });
                      }}
                      size="xs"
                      placeholder="erpCode"
                      rightSectionWidth={30}
                      rightSection={
                        <ActionIcon
                          onClick={async () => {
                            form.setFieldValue(
                              "variants",
                              form.values?.variants?.map((item: any) => {
                                return {
                                  ...item,
                                  erpCode: copy.erpCode,
                                };
                              }),
                            );
                            if (copy.erpCode) await form.clearFieldError(`variants.${form.values.variants.findIndex((item: any) => item.isEdit)}.erpCode`);
                          }}
                          size={"md"}
                          color=""
                          radius={3}
                          variant="subtle">
                          <IconCopy />
                        </ActionIcon>
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      value={copy.nameApp}
                      onChange={async (e) => {
                        setCopy({ ...copy, nameApp: e.target.value });
                        if (e.target.value) {
                          await form.clearFieldError(`variants.${form.values.variants.findIndex((item: any) => item.isEdit)}.nameApp`);
                        }
                      }}
                      size="xs"
                      placeholder="nameApp"
                      rightSectionWidth={30}
                      rightSection={
                        <ActionIcon
                          onClick={async () => {
                            form.setFieldValue(
                              "variants",
                              form.values?.variants?.map((item: any) => {
                                return {
                                  ...item,
                                  nameApp: copy.nameApp,
                                };
                              }),
                            );
                            if (copy.nameApp) await form.clearFieldError(`variants.${form.values.variants.findIndex((item: any) => item.isEdit)}.nameApp`);
                          }}
                          size={"md"}
                          color=""
                          radius={3}
                          variant="subtle">
                          <IconCopy />
                        </ActionIcon>
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      value={copy.nameWeb}
                      onChange={async (e) => {
                        setCopy({ ...copy, nameWeb: e.target.value });
                      }}
                      size="xs"
                      placeholder="nameWeb"
                      rightSectionWidth={30}
                      rightSection={
                        <ActionIcon
                          onClick={async () => {
                            form.setFieldValue(
                              "variants",
                              form.values?.variants?.map((item: any) => {
                                return {
                                  ...item,
                                  nameWeb: copy.nameWeb,
                                };
                              }),
                            );
                            if (copy.nameWeb) await form.clearFieldError(`variants.${form.values.variants.findIndex((item: any) => item.isEdit)}.nameWeb`);
                          }}
                          size={"md"}
                          color=""
                          radius={3}
                          variant="subtle">
                          <IconCopy />
                        </ActionIcon>
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      value={copy.nameBill}
                      onChange={async (e) => {
                        setCopy({ ...copy, nameBill: e.target.value });
                      }}
                      size="xs"
                      placeholder="nameBill"
                      rightSectionWidth={30}
                      rightSection={
                        <ActionIcon
                          onClick={async () => {
                            form.setFieldValue(
                              "variants",
                              form.values?.variants?.map((item: any) => {
                                return {
                                  ...item,
                                  nameBill: copy.nameBill,
                                };
                              }),
                            );
                          }}
                          size={"md"}
                          color=""
                          radius={3}
                          variant="subtle">
                          <IconCopy />
                        </ActionIcon>
                      }
                    />
                  </td>
                </tr>
                {rows}
              </tbody>
            </Table>
          </Box>
        </Grid.Col>

        <Modal
          title={
            <Text fw={600} fz={"lg"}>
              Зураг
            </Text>
          }
          opened={action[0] === "upload"}
          centered
          onClose={() => setAction([])}>
          <Form
            validationSchema={yup.object({
              image: yup.string().required("Заавал бөглөнө!"),
            })}
            onSubmit={(values) => {
              // form.setFieldValue(`variants.${action[1]}.image`, values?.image);
              // form.setFieldValue(`variants.${action[1]}.isEdit`, true);
              form.setFieldValue(
                `variants`,
                form.values?.variants?.map((item: any, index: number) => {
                  if (index === action[1]) {
                    return { ...item, isEdit: true, image: values.image };
                  } else return { ...item, isEdit: false };
                }),
              );
              setAction([]);
            }}
            initialValues={{ image: form.values.variants[action[1]]?.image }}>
            {() => {
              return (
                <div>
                  <ImageFieldProduct name="image" />
                  <Divider my="lg" />
                  <Group align="right">
                    <Button onClick={() => setAction([])} variant="default">
                      Болих
                    </Button>
                    <Button type="submit">Оруулах</Button>
                  </Group>
                </div>
              );
            }}
          </Form>
        </Modal>
      </Grid>
    </Paper>
  );
});

const th = [
  "Үйлдэл",
  "Төлөв",
  "Зураг",
  "Хувилбарын сонголт",
  "DeHUB код",
  "SKU код",
  "Barcode",
  "ERP код",
  "Апп-д гарах нэр",
  "Веб-д гарах нэр",
  "Падаанд харуулах нэр",
].map((element, index) => <th key={index}>{element}</th>);

export { VariantsTable };
