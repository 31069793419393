import { Avatar, Button, Flex, Grid, Modal, Title } from "@mantine/core";
import { IconCheck, IconEdit, IconPhoto, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import React, { useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { SpecialPriceApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { Dialog } from "../../utils/confirm-modal";
import { message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { NumberField } from "../form/number-field";
import { ColumnType, Table } from "../table";
import { VariantSelect } from "./special-variant-select";

type Props = {
  initData?: any;
  initValues: any;
};

export type IDynamicRef = {
  submit: () => Promise<{
    fields: {
      minQuantity: string | undefined;
      basePriceType: string | undefined;
      changeValue: string | undefined;
      perType: string | undefined;
      changeType: string | undefined;
      currency: string | undefined;
      priceStartDate: string | undefined;
      salesPrice: string | number | undefined;
    }[];
  }>;
};

export const SpecialDynamicFieldForm = React.forwardRef(({ initData, initValues }: Props, ref: React.Ref<IDynamicRef>) => {
  const formRef = React.useRef<IFormRef<{ fields: any }>>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const [filter, setFilter] = React.useState<any>({
    perType: initData.perType,
    perValue: initData.perValue,
    changeType: initData.changeType,
    limit: 10,
  });

  const [variantSelectList, setVariantSelectList] = React.useState<any>([]);
  const { perTypes, basePriceTypes, priceChangeTypes, currencies } = useSelector((state: { general: IGeneral }) => state.general);

  const [data, setFormData] = React.useState<{ fields: any }>({
    fields: [
      ...initData?.unitVariants?.map((c: any) => ({
        barCode: c?.barCode,
        erpCode: c?.erpCode,
        id: c?.id,
        image: c?.image,
        nameApp: c?.nameApp,
        nameBill: c?.nameBill,
        nameEng: c?.nameEng,
        nameMon: c?.nameMon,
        nameWeb: c?.nameWeb,
        price: c?.price,
        refCode: c?.refCode,
        skuCode: c?.skuCode,
        standardPrice: c?.standardPrice,
        unitId: c?.unitId,
        unitName: c?.unitName,
      })),
    ],
  });

  const schema = yup.object({
    fields: yup.array().of(
      yup.object({
        price: yup.number()?.moreThan(0, "0-с их байна").required("Заавал бөглөнө!"),
      }),
    ),
  });

  const columns = useHeader({
    action: action,
    basePriceTypes,
    perTypes,
    currencies,
    priceChangeTypes,
    initValues,
    setFormData,
    fields: data.fields,

    onCreate: (option, index) => {
      const fields = (data?.fields || [])?.reduce((accumulator: any, iterator: any, i: any) => {
        if (i === index) {
          return [
            ...accumulator,
            {
              ...iterator,
              values: [...(iterator.values || []), {}],
            },
          ];
        }

        return [...accumulator, iterator];
      }, [] as any);

      setFormData({
        fields: fields,
      });
    },

    onClick: async (key, record, index) => {
      switch (key) {
        case "cancel": {
          const errors = await formRef.current?.validate();

          if (errors && Object.keys(errors).length > 0) {
            formRef.current?.setFormData((state) => ({
              fields: state?.fields?.filter((_i: any, i: any) => i !== index),
            }));

            setAction([]);
          } else setAction([]);
          break;
        }
        case "edit": {
          setAction(["edit", `${index}`]);
          break;
        }
        case "save": {
          const errors = await formRef.current?.validate();

          if (errors && Object.keys(errors).length === 0) {
            setAction([]);
          }

          break;
        }
        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", (key) => {
            switch (key) {
              case "confirm": {
                formRef.current?.setFormData((state) => ({
                  fields: state?.fields?.filter((_i: any, i: any) => i !== index),
                }));
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  useImperativeHandle(ref, () => ({
    submit() {
      return formRef.current?.submit();
    },
  }));

  const selectVariants = async (query: string) => {
    try {
      const res = await SpecialPriceApi.variantSelect({ query: query, ...filter });
      setVariantSelectList(res);
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  return (
    <Form ref={formRef} validationSchema={schema} initialValues={data}>
      {({ values }) => {
        const addToList = (ids: any) => {
          // console.log(";", values?.fields?.concat(variantSelectList?.filter((f: any) => ids?.includes(f?.id))));
          formRef.current?.setFormData((state) => ({
            fields: (values?.fields || [])?.concat(variantSelectList?.filter((f: any) => ids?.includes(f?.id))),
          }));
        };
        return (
          <>
            <Flex direction="row" justify="space-between" align="center" mb="md">
              <Title size="md" c="dark.3">
                Тооноос хамаарах стандарт үнэ
              </Title>

              <Button
                leftSection={<IconPlus size={20} />}
                onClick={() => {
                  setAction(["select"]);
                  selectVariants("");
                }}>
                Шинэ талбар
              </Button>
            </Flex>

            <Grid>
              <Grid.Col span={12}>
                <Table name="product.sellingprice.list.fields" columns={columns} dataSource={values.fields} pagination={false} />
              </Grid.Col>
              <Modal size="50%" centered withCloseButton={false} opened={action[0] === "select"} onClose={() => setAction([])}>
                <VariantSelect
                  setFilter={() => {
                    setFilter((state: any) => ({ ...state, limit: state?.limit + 5 }));
                  }}
                  filter={filter}
                  selectVariants={selectVariants}
                  addItemToList={addToList}
                  selected={values?.fields?.map((c: any) => c.id)}
                  onClose={() => setAction([])}
                  data={variantSelectList}
                />
              </Modal>
            </Grid>
          </>
        );
      }}
    </Form>
  );
});

type HeaderProps = {
  onClick: (key: string, record: any, index: number) => void;
  basePriceTypes: any;
  action: string[];
  fields: any;
  initValues: any;
  currencies: any;
  priceChangeTypes: any;
  perTypes: any;

  setFormData: any;
  onCreate: (option: { label: string; value: string }, index: number) => void;
};

const useHeader = ({ action, onClick }: HeaderProps): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Price Code",
    dataIndex: "barCode",
    width: 180,
    render: (record, index) => {
      return record.barCode || "-";
    },
  },
  {
    title: "Зураг",
    dataIndex: "image",
    width: 120,
    render: (record, index) => {
      return (
        <Avatar src={record.image} size="md">
          <IconPhoto />
        </Avatar>
      );
    },
  },
  {
    title: "Нэр",
    dataIndex: "nameMon",
    width: 260,
    render: (record, index) => {
      return record?.nameMon || "-";
    },
  },
  {
    title: "DeHub Code",
    dataIndex: "refCode",
    width: 240,
    render: (record, index) => {
      return record?.refCode || "-";
    },
  },
  {
    title: "SKU Code",
    dataIndex: "skuCode",
    width: 140,
    render: (record, index) => {
      return record?.skuCode || "-";
    },
  },
  {
    title: "ERP Code",
    dataIndex: "erpCode",
    width: 140,
    render: (record, index) => {
      return record?.erpCode || "-";
    },
  },
  {
    title: "Борлуулах нэгж",
    dataIndex: "unitName",
    width: 180,
    render: (record, index) => {
      return record.unitName || "-";
    },
  },

  {
    title: "Стандарт үнэ",
    dataIndex: "standardPrice",
    width: 180,
    render: (record, index) => {
      return record.standardPrice || "-";
    },
  },
  {
    title: "Тусгай үнэ",
    dataIndex: "price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`) return <NumberField name={`fields[${index}].price`} placeholder="Минимум тоо" noError />;

      return record.price || "-";
    },
  },

  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      if (action[0] === "edit" && action[1] === `${index}`)
        return (
          <Flex gap="sm">
            <Button variant="filled" radius={100} w={30} h={30} p={0} onClick={() => onClick("save", record, index)}>
              <IconCheck />
            </Button>

            <Button variant="default" radius={100} w={30} h={30} p={0} onClick={() => onClick("cancel", record, index)}>
              <IconX />
            </Button>
          </Flex>
        );

      return (
        <Flex gap="sm">
          <Button variant="light" radius={100} w={30} h={30} p={0} onClick={() => onClick("edit", record, index)}>
            <IconEdit />
          </Button>
          <Button variant="light" color="red" radius={100} w={30} h={30} p={0} onClick={() => onClick("remove", record, index)}>
            <IconTrash />
          </Button>
        </Flex>
      );
    },
    width: "120px",
  },
];
