import { Button, CloseButton, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { UnitApi } from "../../apis";
import { IUnit } from "../../interfaces/IUnit";
import { updateUnits } from "../../store/general";
import { HttpHandler } from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  title: string;
  subTitle: string;
  action: string[];
  payload?: IUnit;
  reloadTable?: any;
};

type IFormData = {
  name: string;
};

export function UnitForm({ onCancel, title, subTitle, action, payload, reloadTable }: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    name: "",
    ...(payload ? payload : {}),
  });

  const onSubmit = async (data: IFormData) => {
    try {
      setLoading(true);

      if (payload) {
        await UnitApi.update(payload.id, {
          name: data.name,
        });
      } else {
        await UnitApi.create({
          name: data.name,
        });
      }
      reloadTable();
      setLoading(false);

      message.success("Таны хүсэлт амжилттай!");

      onCancel && onCancel();

      let res = await UnitApi.select();

      dispatch(updateUnits(res));
    } catch (err) {
      setLoading(false);

      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />

            <Flex gap={15} direction="column">
              <TextField name="name" label="Нэр" placeholder="Нэр" />
            </Flex>

            <Group justify="right" mt={20}>
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
}
