import { ActionIcon, Badge, Box, Button, Divider, Grid, Group, Input, LoadingOverlay, Paper, Text, Tooltip } from "@mantine/core";
import { IconCopy, IconPlus } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { Form, IFormRef } from "../form";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";
import { IFile } from "../upload/multi-image-upload";
import { PackageListForm } from "./package-list";

const schema = yup.object({
  skuCode: yup.string().required("Заавал бөглөнө!").nullable(),
  barCode: yup.string().required("Заавал бөглөнө!").nullable(),
  erpCode: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  nameMon: yup.string().required("Заавал бөглөнө!").nullable(),
  nameEng: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  nameBill: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  nameWeb: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  nameApp: yup.string().required("Заавал бөглөнө!").nullable().optional(),
});

export type IFormDataGood = {
  skuCode: string | undefined;
  barCode: string | undefined;
  erpCode: string | undefined;
  nameMon: string | undefined;
  nameEng: string | undefined;
  nameBill: string | undefined;
  nameWeb: string | undefined;
  nameApp: string | undefined;
  brandId: string | undefined;
  supplierId: string | undefined;
  manufacturerId: string | undefined;
  originCountry: string | undefined;
  importerCountry: string | undefined;
  distributorId: string | undefined;
  itemTypeId: string | undefined;
  classificationId: string | undefined;
  subClassificationId: string | undefined;
  categoryId: string | undefined;
  subCategoryId: string | undefined;
  tagId: string | undefined;
  description: string | undefined;
  coverImages: IFile[] | string[] | any[];
  detailImages: IFile[] | string[] | any[];
  itemStatus?: string | undefined;
};

type MainFormProps = {
  editable: any[];
  onSubmit: (values: IFormDataGood, setLoading: React.Dispatch<React.SetStateAction<boolean>>, isNext: boolean) => void;
};

export function PackageForm({ onSubmit: _onSubmit, editable }: MainFormProps) {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const refFrom: React.Ref<IFormRef<any>> = useRef(null);

  const { itemStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormDataGood>({
    skuCode: undefined,
    barCode: undefined,
    erpCode: undefined,
    nameMon: undefined,
    nameEng: undefined,
    nameBill: undefined,
    nameWeb: undefined,
    nameApp: undefined,
    brandId: undefined,
    supplierId: undefined,
    manufacturerId: undefined,
    originCountry: undefined,
    importerCountry: undefined,
    distributorId: undefined,
    itemTypeId: undefined,
    classificationId: undefined,
    subClassificationId: undefined,
    categoryId: undefined,
    subCategoryId: undefined,
    tagId: undefined,
    description: undefined,
    coverImages: [],
    detailImages: [],
    ...(editable && editable[0] === "update"
      ? { ...editable[1], coverImages: editable[1]?.coverImages?.map((c: any) => ({ isMain: c?.isMain || false, url: c?.url || "/" })) }
      : {}),
  });

  return (
    <Paper>
      <Form ref={refFrom} validationSchema={schema} onSubmit={(e) => ""} initialValues={data}>
        {({ values, setFieldValue }) => {
          return (
            <FormLayout
              my={20}
              title="Бүртгэлийн мэдээлэл"
              extra={[
                <Button
                  onClick={() => {
                    navigate("/product");
                  }}
                  key={1}
                  variant="default">
                  Болих
                </Button>,
                <Button
                  type="submit"
                  onClick={async () => {
                    let isValid = await refFrom.current?.submit();
                    if (isValid) {
                      _onSubmit(isValid, setLoading, false);
                    }
                  }}
                  variant="outline"
                  key={2}>
                  Хадгалах
                </Button>,
                <Button
                  type="submit"
                  onClick={async () => {
                    let isValid = await refFrom.current?.submit();
                    if (isValid) _onSubmit(isValid, setLoading, true);
                  }}
                  key={3}>
                  Үргэлжлүүлэх
                </Button>,
              ]}>
              <LoadingOverlay visible={loading} />

              <Divider mb="lg" />

              <Grid>
                <Grid.Col span={4}>
                  <TextField name="dehubCode" label="Багцын код:" placeholder="Багцын код" disabled />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бүртгэлийн статус" required>
                    <Box style={{ alignItems: "center" }}>
                      <Badge size="md" color={itemStatus.find((item: IReference) => item.code === data.itemStatus)?.name || "cyan"}>
                        {itemStatus.find((item: IReference) => item.code === data.itemStatus)?.name || "Түр төлөв"}
                      </Badge>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бүртгэсэн ажилтан" required>
                    <Input placeholder="Систем авто" disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="skuCode" label="Багц үүсгэсэн огноо:" placeholder="Багц үүсгэсэн огноо" required disabled />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="barCode" label="Багцын нэр /Монгол хэлээр/" placeholder="Багцын нэр /Монгол хэлээр/" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField
                    rightSection={
                      <Tooltip label="Хуулах">
                        <ActionIcon
                          color="primary"
                          onClick={() => {
                            setFieldValue("nameWeb", values.nameMon);
                            setFieldValue("nameApp", values.nameMon);
                            setFieldValue("nameBill", values.nameMon);
                          }}>
                          <IconCopy />
                        </ActionIcon>
                      </Tooltip>
                    }
                    name="nameMon"
                    label="Багцын нэр /Англи, эсвэл латин үсгээр/"
                    placeholder="Багцын нэр /Англи, эсвэл латин үсгээр/"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="nameWeb" label="Веб-д гарах нэр" placeholder="Веб-д гарах нэр" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="nameApp" label="Апп-д гарах нэр" placeholder="Апп-д гарах нэр" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="nameBill" label="Падаанд харуулах нэр" placeholder="Падаанд харуулах нэр" />
                </Grid.Col>
                <Grid.Col>
                  <Group align="center">
                    <Text fw={500} fz={"lg"}>
                      Багцад орох бараа
                    </Text>
                    <Button variant="outline" leftSection={<IconPlus size={`1.2rem`} />} size="xs">
                      Мөр нэмэх
                    </Button>
                  </Group>
                  <Divider my={"md"} />
                  <PackageListForm setFieldValue={setFieldValue} values={values} />
                </Grid.Col>
              </Grid>
            </FormLayout>
          );
        }}
      </Form>
    </Paper>
  );
}
