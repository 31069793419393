import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const variantSelect = async (data: { warehouseId: string; categories?: string[] } | any) => {
  return httpRequest.get("/erp/stock/adjustment/variant_select", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/stock/adjustment", { offset, filter });
};

export const category = async (data: any) => {
  return httpRequest.get("/erp/stock/adjustment/category", data);
};

export const adjust = async (data: {
  warehouseId: string;
  description: string;
  reference: string;
  variants: {
    variantId: string;
    movementType: string;
    quantity: number;
    adjustmentNoteId: string;
  }[];
  confirm: boolean;
}) => {
  return httpRequest.put("/erp/stock/adjustment/adjust", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/stock/adjustment/:${id}`);
};
