export function CommingSoon({
  position = "right",
  children,
  visible = false,
}: {
  position: "top" | "right" | "left" | "bottom";
  children: JSX.Element;
  visible?: boolean;
}) {
  if (!visible) return children;

  return (
    <>
      {/* <Popover width={400} position={position} withArrow shadow="sm">
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown p={0}>
        <Alert icon={<IconAlertCircle size={16} />} title="Санамж!" style={{ backgroundColor: "#fff" }} color="indigo.7">
          Тун удахгүй ...
        </Alert>
      </Popover.Dropdown>
    </Popover> */}
    </>
  );
}
