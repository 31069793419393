import { IPackage } from "./../interfaces/IPackage";
import { createSlice } from "@reduxjs/toolkit";
import { IBrand } from "../interfaces/IBrand";
import { ICategory } from "../interfaces/ICategory";
import { IDistributor } from "../interfaces/IDistributor";
import { IGeneral } from "../interfaces/IGeneral";
import { IItemType } from "../interfaces/IItemType";
import { IManufacturer } from "../interfaces/IManufacturer";
import { ISupplier } from "../interfaces/ISupplier";
import { ITag } from "../interfaces/ITag";
import { IUnit } from "../interfaces/IUnit";
import { IDelivery } from "../interfaces/IDelivery";

const initialState: IGeneral = {
  types: [],
  basePriceTypes: [],
  businessTypes: [],
  categoryTypes: [],
  currencies: [],
  fieldTypes: [],
  groupPriceStatus: [],
  itemImageTypes: [],
  itemStatus: [],
  numberValueLabels: [],
  perTypes: [],
  priceChangeTypes: [],
  priceEndTypes: [],
  returnTypes: [],
  unitConvertTypes: [],
  unitSpaceLabels: [],
  unitWeightLabels: [],
  specialPriceStatus: [],
  brands: [],
  manufacturers: [],
  countries: [],
  distributors: [],
  tags: [],
  categories: [],
  suppliers: [],
  itemTypes: [],
  sections: [],
  numberUnits: [],
  deliveryTypes: [],
  packageTypes: [],
  departmentUnits: [],
  units: [],
  priceTypes: [],
  tiers: [],
  groupPrices: [],
  specialPrices: [],
  supplierBusinesses: [],
  options: [],
  priceGroupTypes: [],
  warehouses: [],
  stockStatus: [],
  vatTypes: [],
  additionalUnits: [],
  supplierTypes: [],
  variantPriceHistoryStatus: [],
  priceGroupStatus: [],
  priceGroups: [],
  stockMovementTypes: [],
  adjustmentStatus: [],
  permissions: [],
  managers: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        types,
        basePriceTypes,
        businessTypes,
        categoryTypes,
        currencies,
        fieldTypes,
        groupPriceStatus,
        itemImageTypes,
        itemStatus,
        numberValueLabels,
        perTypes,
        priceChangeTypes,
        priceEndTypes,
        returnTypes,
        unitConvertTypes,
        unitSpaceLabels,
        unitWeightLabels,
        specialPriceStatus,
        brands,
        manufacturers,
        countries,
        distributors,
        tags,
        categories,
        suppliers,
        itemTypes,
        sections,
        numberUnits,
        deliveryTypes,
        packageTypes,
        departmentUnits,
        units,
        priceTypes,
        tiers,
        groupPrices,
        specialPrices,
        supplierBusinesses,
        options,
        priceGroupTypes,
        warehouses,
        stockStatus,
        vatTypes,
        additionalUnits,
        supplierTypes,
        variantPriceHistoryStatus,
        priceGroupStatus,
        priceGroups,
        stockMovementTypes,
        adjustmentStatus,
        permissions,
      } = payload;

      state.types = types;
      state.basePriceTypes = basePriceTypes;
      state.businessTypes = businessTypes;
      state.categoryTypes = categoryTypes;
      state.currencies = currencies;
      state.groupPriceStatus = groupPriceStatus;
      state.itemImageTypes = itemImageTypes;
      state.fieldTypes = fieldTypes;
      state.itemStatus = itemStatus;
      state.numberValueLabels = numberValueLabels;
      state.perTypes = perTypes;
      state.priceChangeTypes = priceChangeTypes;
      state.priceEndTypes = priceEndTypes;
      state.returnTypes = returnTypes;
      state.unitConvertTypes = unitConvertTypes;
      state.unitSpaceLabels = unitSpaceLabels;
      state.unitWeightLabels = unitWeightLabels;
      state.specialPriceStatus = specialPriceStatus;
      state.priceTypes = priceTypes;
      state.brands = brands;
      state.manufacturers = manufacturers;
      state.countries = countries;
      state.distributors = distributors;
      state.tags = tags;
      state.categories = categories;
      state.suppliers = suppliers;
      state.itemTypes = itemTypes;
      state.sections = sections;
      state.numberUnits = numberUnits;

      state.deliveryTypes = deliveryTypes;
      state.packageTypes = packageTypes;
      state.departmentUnits = departmentUnits;
      state.units = units;
      state.tiers = tiers;
      state.groupPrices = groupPrices;
      state.specialPrices = specialPrices;
      state.supplierBusinesses = supplierBusinesses;

      state.options = options;
      state.priceGroupTypes = priceGroupTypes;
      state.warehouses = warehouses;
      state.stockStatus = stockStatus;
      state.vatTypes = vatTypes;
      state.additionalUnits = additionalUnits;
      state.supplierTypes = supplierTypes;
      state.variantPriceHistoryStatus = variantPriceHistoryStatus;
      state.priceGroupStatus = priceGroupStatus;
      state.priceGroups = priceGroups;
      state.stockMovementTypes = stockMovementTypes;
      state.adjustmentStatus = adjustmentStatus;
      state.permissions = permissions;

      return state;
    },

    updateSections: (state, { payload }: { payload: any[] }) => {
      state.sections = payload;
      return state;
    },
    updateDeliveryTypes: (state, { payload }: { payload: IDelivery[] }) => {
      state.deliveryTypes = payload;
      return state;
    },
    updateBrands: (state, { payload }: { payload: IBrand[] }) => {
      state.brands = payload;

      return state;
    },
    updateSuppliers: (state, { payload }: { payload: ISupplier[] }) => {
      state.suppliers = payload;

      return state;
    },
    updateManufacturers: (state, { payload }: { payload: IManufacturer[] }) => {
      state.manufacturers = payload;

      return state;
    },
    updateDistributors: (state, { payload }: { payload: IDistributor[] }) => {
      state.distributors = payload;

      return state;
    },
    updateItemTypes: (state, { payload }: { payload: IItemType[] }) => {
      state.itemTypes = payload;

      return state;
    },
    updateCategories: (state, { payload }: { payload: ICategory[] }) => {
      state.categories = payload;

      return state;
    },
    updateTags: (state, { payload }: { payload: ITag[] }) => {
      state.tags = payload;

      return state;
    },

    updateUnits: (state, { payload }: { payload: IUnit[] }) => {
      state.units = payload;

      return state;
    },

    updatepackageTypes: (state, { payload }: { payload: IPackage[] }) => {
      state.packageTypes = payload;

      return state;
    },

    updateManagers: (state, { payload }: { payload: any }) => {
      state.managers = payload;

      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const {
  initGeneral,
  updateUnits,
  updateBrands,
  updateSuppliers,
  updateManufacturers,
  updateDistributors,
  updateItemTypes,
  updateCategories,
  updateTags,
  updatepackageTypes,
  updateDeliveryTypes,
  updateSections,
  updateManagers,
} = generalSlice.actions;
