import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { authChange, authMe } from "../../store/auth";
import qs from "qs";
import { AuthApi, GeneralApi } from "../../apis";
import { initGeneral } from "../../store/general";

export function Access() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        const params = qs.parse(location.search);
        dispatch(authChange({ accessToken: params["?token"] } as any));

        const res = await GeneralApi.init();
        dispatch(initGeneral(res));

        const auth = await AuthApi.me();
        dispatch(authMe(auth));
        navigate("/");
      } catch (err) {
        console.log(err);
      }
    })();
  }, [dispatch, location, navigate]);

  return <LoadingOverlay visible />;
}
