import { Anchor, Avatar, Box, Button, Checkbox, Divider, Flex, Grid, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { useDebouncedState } from "@mantine/hooks";
import { IconCalendar, IconHistory, IconPhoto, IconReportSearch } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { CategoryApi, StockApi, WarehouseApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { usePermission } from "../permission";
import { SelectFilter } from "../select-filter";

const filterInit = {
  variantId: "",
  warehouseId: "",
  categoryId: "",
  subCategoryId: "",
  startDate: "",
  endDate: "",
};

type IFilterInit = {
  variantId: string;
  warehouseId: string | null;
  categoryId: string | null;
  subCategoryId: string | null;
  startDate: string | Date | any;
  endDate: string | Date | any;
};

export const HistoryList = () => {
  const [filters, setFilters] = useState<IFilterInit>(filterInit);
  const ERP_STOCK_ADJ = usePermission("ERP_STOCK_ADJ");
  const { stockMovementTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [historyFilter, setHistoryFilter] = useState<{
    variantId: null | string;
    startDate: null | string;
    endDate: null | string;
  }>({
    variantId: null,
    startDate: null,
    endDate: null,
  });

  const columns = useHeader({ stockMovementTypes });

  const [warehouseFilterDelay, setWarehouseFilterDelay] = useDebouncedState<any>({ query: "" }, 800);
  const { theme } = useStyle();
  const { data: warehouseSelects = [] } = useSwr<any[]>(
    ERP_STOCK_ADJ.isEdit && `/erp/warehouse/select?query=${JSON.stringify(warehouseFilterDelay)}`,
    async () =>
      await WarehouseApi.select({
        query: warehouseFilterDelay.query || "",
        limit: 10,
      }),
    {
      fallback: [],
    },
  );

  const [variantsQueryDelay, setVariantsQueryDelay] = useDebouncedState<string>("", 800);
  const [variantsFilter, setVariantsFilter] = useState<{
    warehouseId: null | string;
    warehouseUserId: null | string;
    categoryId: null | string;
    subCategoryId: null | string;
    name: null | string;
    limit: number;
  }>({ warehouseId: "", warehouseUserId: null, categoryId: "", subCategoryId: "", name: "", limit: 10 });

  const { data: variantsSelects = [] } = useSwr<any[]>(
    `/erp/stock/variant_select?query=${JSON.stringify({ ...variantsFilter, query: variantsQueryDelay })}`,
    async () =>
      StockApi.variant_select({
        ...variantsFilter,
        name: variantsQueryDelay,
        limit: 10,
      }),
    {
      fallback: [],
    },
  );

  const [categoriesQueryDelay, setCategoriesQueryDelay] = useDebouncedState<string>("", 800);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categoriesFilter, setCategoriesFilter] = useState<any>({ query: "", type: "CATEGORY", itemTypeId: null, parentId: "" });
  const { data: categoriesSelects = [] } = useSwr<any[]>(
    ERP_STOCK_ADJ.isEdit && `/erp/category/select?query=${JSON.stringify({ ...categoriesFilter, query: categoriesQueryDelay })}`,
    async () =>
      await CategoryApi.select({
        ...categoriesFilter,
        query: categoriesQueryDelay,
        limit: 10,
      }),
    {
      fallback: [],
    },
  );

  const [subCategoriesQueryDelay, setSubCategoriesQueryDelay] = useDebouncedState<string>("", 800);
  const [subCategoriesFilter, setSubCategoriesFilter] = useState<any>({ query: "", type: "SUB_CATEGORY", itemTypeId: null, parentId: "" });
  const { data: subCategoriesSelects = [] } = useSwr<any[]>(
    `/erp/category/select?query=${JSON.stringify({ ...subCategoriesFilter, query: subCategoriesQueryDelay })}`,
    async () =>
      await CategoryApi.select({
        ...subCategoriesFilter,
        query: subCategoriesQueryDelay,
        limit: 10,
      }),
    {
      fallback: [],
    },
  );

  return (
    <>
      <Grid>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectFilter
            onSearchChange={(e) => {
              setWarehouseFilterDelay({
                ...warehouseFilterDelay,
                query: e,
              });
            }}
            label="Агуулах"
            value={`${filters?.warehouseId || ""}`}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                warehouseId: e,
              });
              setVariantsFilter({ ...variantsFilter, warehouseId: e });
            }}
            data={
              warehouseSelects?.map((item: any) => {
                return {
                  name: item?.name || "",
                  code: item?.id || "",
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectFilter
            disabled
            iconWidth={180}
            label="Хариуцсан нярав"
            value={`${filters?.warehouseId || ""}`}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                warehouseId: e,
              });
              setVariantsFilter({ ...variantsFilter });
            }}
            data={
              [{ name: "Бүгд", id: "" }]?.map((item: any) => {
                return {
                  name: item?.name || "",
                  code: item?.id || "",
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectFilter
            onSearchChange={(e) => {
              setCategoriesQueryDelay(e);
            }}
            label="Категори"
            value={filters?.categoryId || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                categoryId: e,
                subCategoryId: null,
              });
              setSubCategoriesFilter({
                ...subCategoriesFilter,
                parentId: e,
              });
              setVariantsFilter({ ...variantsFilter, categoryId: e, subCategoryId: null });
            }}
            data={
              categoriesSelects?.map((item: any) => {
                return {
                  name: item.name,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectFilter
            onSearchChange={(e) => setSubCategoriesQueryDelay(e)}
            disabled={!subCategoriesFilter.parentId}
            label="Дэд категори"
            value={filters?.subCategoryId || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                subCategoryId: e,
              });
              setVariantsFilter({ ...variantsFilter, subCategoryId: e });
            }}
            data={
              subCategoriesSelects?.map((item: any) => {
                return {
                  name: item.name,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <SelectFilter
            onSearchChange={(e) => {
              setVariantsQueryDelay(e);
            }}
            label="Барааны нэр"
            value={filters?.variantId || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                variantId: e,
              });
            }}
            data={
              variantsSelects?.map((item: any) => {
                return {
                  name: item.nameMon,
                  code: item.id,
                };
              }) || []
            }
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <DatePickerInput
            type="range"
            value={[filters?.startDate || null, filters.endDate || null]}
            onChange={(e) => {
              console.log(e);
              setFilters({ ...filters, startDate: e[0], endDate: e[1] });
            }}
            valueFormat="YYYY-MM-DD"
            leftSection={<IconCalendar size={"1.2rem"} />}
            placeholder="Эхлэх / Дуусах огноо сонгох"
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
          <Button
            disabled={!filters.endDate || !filters.startDate || !filters.variantId}
            onClick={() =>
              setHistoryFilter({
                endDate: filters?.endDate || null,
                startDate: filters?.startDate || null,
                variantId: filters.variantId,
              })
            }
            variant="light"
            leftSection={<IconHistory size={"1.2rem"} />}>
            Түүх харах
          </Button>
        </Grid.Col>
      </Grid>
      <Divider mt={"md"} />
      <Table
        placeholder={<Text c="gray">{historyFilter.variantId ? "Юу ч олдсонгүй" : "Нөөцийн түүх харах бараа сонгоогүй байна."} </Text>}
        placeholderIcon={<IconReportSearch size={36} color={theme.colors.gray[4]} />}
        name="stock.history.list"
        columns={columns}
        filters={{ variantId: historyFilter?.variantId, startDate: historyFilter.startDate, endDate: historyFilter.endDate }}
        loadData={async (data) => (historyFilter?.variantId ? StockApi.history(data!) : null)}
      />
    </>
  );
};

const useStyle = createStyles({});

const useHeader = ({ stockMovementTypes }: { stockMovementTypes: any[] }): ColumnType<any>[] => [
  {
    title: "",
    actionTitle() {
      return <Checkbox />;
    },
    render: (record) => <Checkbox value={record?.id} />,
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    render: (record) => (
      <Text w="max-content" size="sm">
        {dateTimeFormat(`${new Date()}`)}
      </Text>
    ),
  },
  {
    title: "Гүйлгээ №",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.adjustment?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Гүйлгээ төрөл",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => <Text size="sm">{stockMovementTypes.find((item: any) => item.code === record?.movementType)?.name ?? "-"}</Text>,
  },
  {
    title: "Inbound User",
    sorter: true,
    dataIndex: "category",
    render: (record) =>
      record?.inboundUser ? (
        <Flex gap={10} align="center">
          <Avatar src={record.inboundUser?.avatar} />
          <div>
            <Text size="sm">{record.inboundUser?.lastName ? record.inboundUser?.lastName[0] + "." : ""}</Text>
            <Text size="sm">{record.inboundUser?.firstName ?? "-"}</Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Outbound User",
    sorter: true,
    dataIndex: "price",
    render: (record) =>
      record?.outboundUser ? (
        <Flex gap={10} align="center">
          <Avatar src={record.outboundUser?.avatar} />
          <div>
            <Text>{record.outboundUser?.lastName ? record.outboundUser?.lastName[0] + "." : ""}</Text>
            <Text>{record.outboundUser?.firstName ?? "-"}</Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Ad.User",
    sorter: true,
    dataIndex: "price",
    render: (record) =>
      record?.adjustment?.regUser ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.adjustment?.regUser?.avatar} />
          <div>
            <Text size="sm">{record?.adjustment?.regUser?.lastName ? record?.adjustment?.regUser?.lastName[0] + "." : ""}</Text>
            <Text size="sm">{record?.adjustment?.regUser?.firstName || "-"}</Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Код #",
    sorter: true,
    render: (record) =>
      record?.variant?.refCode ? (
        <Text c="primary" w="max-content" size="sm">
          #{record?.variant?.refCode ?? "-"}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "SKU",
    sorter: true,
    render: (record) =>
      record?.variant?.skuCode ? (
        <Text c="primary" w="max-content" size="sm">
          #{record?.variant?.skuCode ?? "-"}
        </Text>
      ) : (
        "-"
      ),
  },
  {
    title: "Барааны нэр",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) =>
      record?.variant?.nameMon ? (
        <Flex gap={10} align="center">
          <Avatar src={record?.variant?.image ?? "-"} radius="xs">
            <IconPhoto />
          </Avatar>
          <Box w="max-content">
            <Text size="sm">{record?.variant?.nameMon ?? "-"}</Text>
            <Anchor size={"xs"} c="primary">
              #{record?.variant?.refCode ?? "-"}
            </Anchor>
          </Box>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Агуулах",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) =>
      record?.adjustment?.warehouse ? (
        <div>
          <Text size="sm" w="max-content">
            {record?.adjustment?.warehouse?.name}
          </Text>
          <Anchor size="sm" w="max-content">
            #{record?.adjustment?.warehouse?.refCode}
          </Anchor>
        </div>
      ) : (
        "-"
      ),
  },
  {
    title: "Эхний үлдэгдэл",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => <Text size="sm">{record?.firstQuantity ?? "-"}</Text>,
  },
  {
    title: "Тохиргоо тоо",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => <Text size="sm">{record?.quantity ?? "-"}</Text>,
  },
  {
    title: "Нэгж үнэ",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => (
      <Text ta="end" size="sm">
        {currencyFormat(record?.price)}
      </Text>
    ),
  },
  {
    title: "Нийт дүн",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => (
      <Text size="sm" ta="end">
        {currencyFormat(record?.price * (record?.quantity ?? 0))}
      </Text>
    ),
  },
  {
    title: "Эцсийн үлдэгдэл",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => <Text size="sm">{record?.lastQuantity ?? "-"}</Text>,
  },
  {
    title: "Шалтгаан",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.adjustmentNote ?? "-"}
      </Text>
    ),
  },
  {
    title: "Reference",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.reference ?? "-"}
      </Text>
    ),
  },
];
