import { Button, CloseButton, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { GeneralApi, SectionApi } from "../../apis";
import { IBrand } from "../../interfaces/IBrand";
import { updateSections } from "../../store/general";
import { HttpHandler } from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";
import { DynamicFieldForm, IDynamicRef } from "./dynamic-field-form";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  logo: yup.string().required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  action: string[];
  payload?: IBrand;
  reload: any;
};

type IFormData = {
  id?: string;
  name: string;
  fieldIds: string;
};

const SectionFieldForm = React.memo(({ onCancel, action, payload, reload }: Props) => {
  const dynamicRef = React.useRef<IDynamicRef>(null);
  // const { fields } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [data] = React.useState<IFormData>({
    name: "",
    fieldIds: "",

    ...(payload ? payload : {}),
  });

  const onSubmit = async (data: IFormData) => {
    let res;
    let valid = await dynamicRef.current?.submit();
    if (!valid) {
      return message.error("Динамик талбарууд алдаатай байна!");
    } else {
      if (valid?.fields?.length > 0) {
        try {
          setLoading(true);

          if (payload) {
            await SectionApi.update(payload.id, {
              name: data.name,
            });
            setLoading(false);
          } else {
            await SectionApi.create({
              name: data.name,
              sectionFields: (valid?.fields || [])
                .sort((a, b) => a.sort - b.sort)
                .map((field) => ({
                  name: field.name!,
                  type: field.type!,
                  numberUnit: field.numberUnit!,
                  values: (field.values || [])?.map((value: string) => ({
                    name: value,
                    isDefault: false,
                  })),
                })),
            });
            setLoading(false);
          }

          res = await GeneralApi.init();
          dispatch(updateSections(res?.sections));

          onCancel && onCancel();
          message.success("Жагсаалт амжилттай хадгаллаа");
          reload && reload();
        } catch (err) {
          setLoading(false);

          message.error((err as HttpHandler)?.message!);
        }
      } else {
        message.error("Жагсаалт хоосон байж болохгүй");
      }
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout
            title="Барааны нэмэлт мэдээлэл"
            subTitle="Барааны нэмэлт мэдээлэл оруулна уу."
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Flex gap={15} direction="column">
              <TextField name="name" label="Нэр" placeholder="Нэр" />
              <DynamicFieldForm ref={dynamicRef} />
            </Flex>

            <Group justify="right" mt={20}>
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
});

SectionFieldForm.displayName = "SectionFieldForm";

export { SectionFieldForm };
