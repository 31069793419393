import { ActionIcon, Avatar, Flex, Grid, Group, LoadingOverlay, Pagination, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useColorScheme } from "@mantine/hooks";
import { IconChevronsUpRight, IconPackage, IconPhoto, IconStar } from "@tabler/icons-react";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";

export * from "./RowAction";

export type ColumnType<T> = {
  title: string;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  filters?: { [key: string]: string | number | any };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  onClick: any;
};

export type ITableRef = { reload: () => void };

export const SuppTable = React.forwardRef(({ name, filters = {}, pagination = true, dataSource = [], loadData, onClick }: Props, ref: React.Ref<ITableRef>) => {
  const { classes } = useStyles();
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [offset, setOffset] = React.useState({ page: 1, limit: 12 });

  const { data, mutate, isLoading } = useSwr(
    `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
    async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
    {
      fallbackData: { count: dataSource.length, rows: dataSource },
    },
  );

  const onScroll = () => {};

  useImperativeHandle(ref, () => ({
    reload() {
      return mutate();
    },
  }));

  // const mockdata = [
  //   { label: "SKU 21213", icon: IconBarcode },
  //   { label: "250гр", icon: IconScale },
  //   { label: "Говь брэнд", icon: IconMoodSmile },
  //   { label: "Electric", icon: IconFileText },
  // ];

  // function FeaturesCard({ item, index }: any) {
  //   const { classes } = useStyles();

  //   const features = mockdata.map((feature) => (
  //     <Center key={feature.label}>
  //       <feature.icon size="1.05rem" className={classes.icon2} stroke={1.5} />
  //       <Text size="xs">{feature.label}</Text>
  //     </Center>
  //   ));

  //   return (
  //     <Card withBorder radius="md" className={classes.card} key={index}>
  //       <Image
  //         h={"100%"}
  //         fit="cover"
  //         className={classes.imageSection}
  //         w={"100%"}
  //         width="100%"
  //         src={item.image || item?.logo || "http://i.imgur.com/ZL52Q2D.png"}
  //         alt="Tesla Model S"
  //       />
  //       <Group align="apart" mt="md">
  //         <div>
  //           <Text fw={500}>{item.nameApp || "-"}</Text>
  //           <Text fz="xs" c="dimmed">
  //             <Group align="center">
  //               <IconStar stroke={1.5} size="1rem" className={classes.icon} />
  //               <IconStar stroke={1.5} size="1rem" className={classes.icon} />
  //               <IconStar stroke={1.5} size="1rem" className={classes.icon} />
  //               <IconStar stroke={1.5} size="1rem" className={classes.icon} />
  //               <Text size="sm" c="dimmed">
  //                 (11248)
  //               </Text>
  //             </Group>
  //           </Text>
  //         </div>
  //         {item.hasUnit ? (
  //           <Badge color={"indigo"} variant="outline">
  //             би зардаг
  //           </Badge>
  //         ) : (
  //           <ActionIcon onClick={() => onClick(index, item)} color="indigo" variant="light">
  //             <IconPlus />
  //           </ActionIcon>
  //         )}
  //       </Group>

  //       <Card.Section className={classes.section}>
  //         <Group>
  //           {item?.standardPrice === item?.customPrice ? (
  //             <>
  //               <div>
  //                 <Text fz="xl" fw={600} sx={{ lineHeight: 1 }}>
  //                   {currencyFormat(item?.standardPrice)}
  //                 </Text>
  //                 <Text fz="sm" c="dimmed" fw={400} sx={{ lineHeight: 1 }} mt={3}>
  //                   Үндсэн үнэ
  //                 </Text>
  //               </div>
  //             </>
  //           ) : (
  //             <>
  //               <div>
  //                 <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>
  //                   {currencyFormat(item?.customPrice || 0)}
  //                 </Text>
  //                 <Text fz="sm" c="dimmed" fw={400} sx={{ lineHeight: 1 }} mt={3}>
  //                   Хямдрал
  //                 </Text>
  //               </div>
  //               <div>
  //                 <Text td="line-through" c={"gray"} fz="xl" fw={600} sx={{ lineHeight: 1 }}>
  //                   {currencyFormat(item?.standardPrice)}
  //                 </Text>
  //                 <Text fz="sm" c="dimmed" fw={400} sx={{ lineHeight: 1 }} mt={3}>
  //                   Үндсэн үнэ
  //                 </Text>
  //               </div>
  //             </>
  //           )}
  //         </Group>
  //       </Card.Section>
  //     </Card>
  //   );
  // }

  function PartnerCard({ item, index }: any) {
    return (
      <Paper withBorder h="100%">
        <Flex align="start" justify="space-between" h={"100%"}>
          <Group align="start" p="md">
            <Avatar src={item?.logo || item?.image} size={45} radius="xl">
              <IconPhoto />
            </Avatar>
            <div>
              <Text fz="lg" fw={600}>
                {item?.profileName}
              </Text>
              <Text fz="xs" tt="uppercase" fw={500} c="dimmed">
                {item?.partner?.businessName}
              </Text>

              <Group mt={3} align="center">
                <IconStar stroke={1.5} size="1rem" className={classes.icon} />
                <IconStar stroke={1.5} size="1rem" className={classes.icon} />
                <IconStar stroke={1.5} size="1rem" className={classes.icon} />
                <IconStar stroke={1.5} size="1rem" className={classes.icon} />
                <Text size="sm" c="dimmed">
                  (11248)
                </Text>
              </Group>
            </div>
          </Group>
          <ActionIcon onClick={() => onClick(item)} m="6px" variant="light" color={""}>
            <IconChevronsUpRight />
          </ActionIcon>
        </Flex>
      </Paper>
    );
  }

  return (
    <div className={classes.container}>
      <div ref={scrollRef} onScroll={onScroll} className={classes.scroll}>
        <Grid>
          {data?.rows?.map((item: any, index: number) => {
            return (
              <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4 }} key={index}>
                <PartnerCard item={item} index={index} />
              </Grid.Col>
            );
          })}

          {data?.rows?.length === 0 && (
            <div className={classes.notFound}>
              <Flex direction="column" align="center">
                <IconPackage size={"50px"} color="gray" stroke="1" />
                <Text c="gray" size="md">
                  Өгөгдөл олдсонгүй.
                </Text>
              </Flex>
            </div>
          )}
        </Grid>
        <LoadingOverlay visible={isLoading} />
      </div>

      {pagination && (
        <div className={classes.pagination}>
          <Pagination
            onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
            total={data.count / offset.limit + (data.count % offset.limit > 0 ? 1 : 0)}
          />
        </div>
      )}
    </div>
  );
});

const useStyles = createStyles((theme) => ({
  container: {},
  notFound: {
    width: "100%",
    height: "400px",
    padding: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.colors.gray[0],
  },
  scroll: {
    overflowX: "hidden",
    overflowY: "hidden",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
  },
  icon: {
    color: theme.colors.yellow[5],
  },

  name: {},

  card: {
    backgroundColor: useColorScheme() === "dark" ? theme.colors.dark[7] : theme.white,
    height: "100%",
  },

  imageSection: {
    borderBottom: `1px solid ${useColorScheme() === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]}`,
    maxHeight: "220px",
    minHeight: "220px",
    overflow: "hidden",
    width: "100%",
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${useColorScheme() === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]}`,
  },

  icon2: {
    marginRight: "80px",
    color: useColorScheme() === "dark" ? theme.colors.dark[2] : theme.colors.gray[5],
  },
}));
