import { Anchor, Avatar, Badge, Divider, Flex, Grid, Group, Paper, Skeleton, Space, Tabs, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { IconAccessible, IconArrowDown, IconArrowUp, IconCalendar, IconCaretRight, IconPackage, IconPackages, IconPhoto } from "@tabler/icons-react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import useSwr from "swr";
import { DashboardApi } from "../../apis";
import { usePermission } from "../../components/permission";
import { useScreenDetector } from "../../components/screen-detector";
import { StockLineChart } from "./approved-invoice-chart";
import { MostSoldTable } from "./most-sold-table";

export function Dashboard() {
  const ERP_DASH = usePermission("ERP_DASH");
  const { classes, theme } = useStyles();
  const { isMobile } = useScreenDetector();

  const [filter, setFilter] = useState<{
    date: any | Date;
    startDate: any | Date;
    endDate: any | Date;
    rowDate: any | Date;
  }>({
    date: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    rowDate: new Date(),
  });

  const { data: dataMain } = useSwr(
    `/api/main?${JSON.stringify(filter)}`,
    async () =>
      await DashboardApi.main({
        ...filter,
        date: dayjs(filter.date).format(),
        startDate: dayjs(new Date()).subtract(14, "day").format(),
        endDate: dayjs(filter.endDate).format(),
        rowDate: dayjs(filter.rowDate).format(),
      }),
    {
      fallback: [],
    },
  );

  const getOptions = () => ({
    chart: {
      zoomType: "xy",
      type: "bar",
    },
    title: {
      text: "",
      align: "bottom",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: ["Бараа нэр 1", "Бараа нэр 2", "Бараа нэр 3", "Бараа нэр 4", "Бараа нэр 5", "Бараа нэр 6", "Бараа нэр 7"],
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        title: {
          text: "",
          style: {
            color: "green",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "black",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      // x: 80,
      // verticalAlign: "top",
      y: 20,
      // floating: true,
      // backgroundColor: "rgba(255,255,255,0.25)",
    },
    series: [
      {
        name: "Бодит үлдэгдэл",
        color: "#6043C3",
        type: "column",
        data: [30, 20, 40, 26, 50, 61, 25],
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Заавал байх үлдэгдэл",
        type: "column",
        color: "#BEB3E6",
        data: [20, 10, 20, 23, 40, 60, 20],
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
  });

  if (!ERP_DASH?.hasAccess) return <Paper p={"sm"}>{ERP_DASH.accessDenied()}</Paper>;
  else
    return (
      <div className={classes.root}>
        <Group align="apart" mb={"xs"}>
          <Text size={"xl"} fw={600}>
            Тоон тойм
          </Text>

          <DatePickerInput
            excludeDate={(date) => {
              return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
            }}
            value={filter.date}
            onChange={(e: any) => {
              if (e)
                setFilter({
                  ...filter,
                  date: e,
                });
              else
                setFilter({
                  ...filter,
                  date: new Date(),
                });
            }}
            style={{ borderRadius: "5px" }}
            variant="unstyled"
            bg={"#F7F6FD"}
            miw={isMobile ? "100%" : "240px"}
            placeholder={dayjs().format("YYYY-MM-DD")}
            leftSection={<IconCalendar size={"1.2rem"} />}
            valueFormat="YYYY-MM-DD"
            mr={isMobile ? 0 : "sm"}
          />
        </Group>

        <Grid>
          {dataMain?.numberSurvey ? (
            dataMain?.numberSurvey
              ?.map((item: any, index: number) => {
                return {
                  title: item.name,
                  value: item.count,
                  diff: item.changedCount,
                  key: index,
                  icon: IconArrowDown,
                };
              })
              .map((stat: any, index: number) => {
                const DiffIcon = stat.diff > 0 ? IconArrowUp : IconArrowDown;

                return (
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
                    <Paper withBorder key={stat.key} radius={"md"}>
                      <Group p={"sm"} pb={"xs"}>
                        {index === 0 && <IconPackages size={"1.2rem"} color={theme.colors.gray[6]} />}
                        {index === 1 && <IconAccessible size={"1.2rem"} color={theme.colors.gray[6]} />}
                        {index === 2 && <IconPackage size={"1.2rem"} color={theme.colors.gray[6]} />}
                        <Text fz={"sm"} fw={600} c={theme.colors.gray[6]}>
                          {stat.title}
                        </Text>
                      </Group>
                      <Group>
                        <div className={classes.grdnt}></div>
                        <Space />
                        <Text fz={"xl"} fw={700}>
                          {stat.value}
                        </Text>
                      </Group>
                      <Group align="right" p={"sm"}>
                        <Text size={"xs"} c={theme.colors.gray[6]}>
                          Өмнөх өдрөөс
                        </Text>
                        <Badge color={stat.diff > 0 ? "green" : "red"} className={stat.diff > 0 ? classes.green : classes.red}>
                          <Flex align={"center"} gap={8}>
                            <DiffIcon size={"0.8rem"} stroke={3} />
                            <Text>{Math.abs(stat.diff)} </Text>
                          </Flex>
                        </Badge>
                      </Group>
                    </Paper>
                  </Grid.Col>
                );
              })
          ) : (
            <>
              {[1, 2, 3].map((index: number) => {
                return (
                  <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} key={index}>
                    <Skeleton h={"121px"} w={"100%"} />
                  </Grid.Col>
                );
              })}
            </>
          )}
          <Grid.Col>
            <Paper withBorder py={"xs"}>
              <Flex justify={"space-between"} align={"center"} gap={"xs"} wrap={"wrap"}>
                <Group w={"max-content"}>
                  <div className={classes.grdnt}></div>
                  <Text component="h1">Нөөцөд анхаарах бараа</Text>
                </Group>
                <div>
                  <DatePickerInput
                    type="range"
                    excludeDate={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
                      return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
                    }}
                    onChange={(e: any) => {
                      if (e[0] && e[1]) {
                        const firstDate = new Date(e[0]);
                        const secondDate = new Date(e[1]);

                        const firstTime = firstDate.getTime();
                        const secondTime = secondDate.getTime();

                        if (firstTime < secondTime) {
                          setFilter({ ...filter, startDate: e[0], endDate: e[1] });
                        } else if (firstTime > secondTime) {
                          setFilter({ ...filter, startDate: e[1], endDate: e[2] });
                        } else setFilter({ ...filter, startDate: e[0], endDate: e[1] });
                      }
                      if (!e[0] && !e[1])
                        setFilter({
                          ...filter,
                          startDate: dayjs(new Date()).subtract(14, "day").format(),
                          endDate: dayjs(new Date()).format(),
                        });
                    }}
                    style={{ borderRadius: isMobile ? 0 : "5px" }}
                    variant="unstyled"
                    bg={"#F7F6FD"}
                    w={isMobile ? "100%" : "240px"}
                    placeholder="Огноо хайх"
                    leftSection={<IconCalendar size={"1.2rem"} />}
                    valueFormat="YYYY-MM-DD"
                    mr={isMobile ? 0 : "sm"}
                  />
                </div>
              </Flex>
              <div>
                <Divider />
                <Tabs defaultValue={"1"}>
                  <Tabs.List>
                    <Tabs.Tab value="1">Бодит</Tabs.Tab>
                    <Tabs.Tab value="2">Жишээ</Tabs.Tab>
                  </Tabs.List>
                  <Tabs.Panel value="1">
                    <StockLineChart data={dataMain?.stockInfo} key={0} />
                  </Tabs.Panel>
                  <Tabs.Panel value="2">
                    <HighchartsReact highcharts={Highcharts} options={getOptions()} />
                  </Tabs.Panel>
                </Tabs>

                <Group align="right" style={{ marginTop: -20 }}>
                  <Text size={"sm"} c="dimmed" style={{ zIndex: 2 }} bg={"white"}>
                    Хэмжих нэгж: ширхгээр
                  </Text>
                </Group>
              </div>
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ md: 12, lg: 8, xl: 9 }}>
            <Paper withBorder py={"4px"}>
              <Group align="apart">
                <Group align="center">
                  <div className={classes.grdnt}></div>
                  <Text component="h1">Өндөр борлуулалттай</Text>
                </Group>
                {!isMobile ? (
                  <Anchor size={"xs"} px={"xs"}>
                    Бүгдийг харах
                  </Anchor>
                ) : (
                  <Anchor px={"xs"}>
                    <IconCaretRight />
                  </Anchor>
                )}
              </Group>
              <Paper px={"lg"} style={{ overflow: "auto" }}>
                <MostSoldTable data={dataMain?.mostSold || []} />
              </Paper>
            </Paper>
          </Grid.Col>
          <Grid.Col span={{ md: 12, lg: 4, xl: 3 }}>
            <Paper p={"sm"} withBorder>
              <Text fz={"lg"} fw={600}>
                Шинэ бараа
              </Text>
              <Divider my={"xs"} />
              <Group align="right">
                <DatePickerInput
                  excludeDate={(rowDate) => {
                    return dayjs(rowDate).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
                  }}
                  value={filter.rowDate}
                  onChange={(e: any) => {
                    if (e)
                      setFilter({
                        ...filter,
                        rowDate: e,
                      });
                    else
                      setFilter({
                        ...filter,
                        rowDate: new Date(),
                      });
                  }}
                  style={{ borderRadius: "5px" }}
                  variant="unstyled"
                  bg={"#F7F6FD"}
                  w={isMobile ? "100%" : "220px"}
                  placeholder={dayjs().format("YYYY-MM-DD")}
                  leftSection={<IconCalendar size={"1.2rem"} />}
                  valueFormat="YYYY-MM-DD"
                  mr={isMobile ? 0 : "sm"}
                />
              </Group>
              <Space h={"sm"} />
              <Flex direction={"column"} gap={"sm"}>
                {dataMain?.newProducts?.map((item: any, index: number) => {
                  return (
                    <Group key={index} align="start">
                      <Group>
                        <Avatar src={item?.image}>
                          <IconPhoto />
                        </Avatar>
                        <div>
                          <Text fw={600}>{item?.nameMon}</Text>
                        </div>
                      </Group>
                      <Text fz={"sm"} c="gray">
                        {item.updatedAt ? dayjs(item.updatedAt).format("YYYY-MM-DD HH:mm") : "-"}
                      </Text>
                    </Group>
                  );
                })}
              </Flex>
            </Paper>
          </Grid.Col>
        </Grid>
      </div>
    );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: `${theme.spacing.xl} * 1.5`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: theme.colors.violet[6],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
