import { ActionIcon, Avatar, Badge, Box, Flex, Group, Text } from "@mantine/core";
import { IconPhoto, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { NumberCurrencyField } from "../form/number-currency-field";
import { ColumnType, Table } from "../table";
import { Tag } from "../tags";

function ValuesVariantsList({ variants, setFieldValue }: { variants: any; setFieldValue: any }) {
  const { tiers, currencies, variantPriceHistoryStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    variantPriceHistoryStatus,
    onClick: (key, record) => {
      switch (key) {
        case "remove":
          setFieldValue(
            "variants",
            variants.filter((item: any) => item.id !== record.id),
          );
          break;

        default:
          break;
      }
    },
    tiers,
    currencies,
    Tag,
  });

  return (
    <Table
      placeholder="Та нэг ч бараа сонгож оруулаагүй байна. Үнэ өөрчлөх бараагаа сонгож оруулна уу. "
      name={`group.list.variants.${JSON.stringify(variants.map((item: any) => item?.id))}`}
      columns={columns}
      pagination={false}
      dataSource={variants || []}
    />
  );
}

const useHeader = ({
  onClick,
  tiers,
  currencies,
  variantPriceHistoryStatus,
  Tag,
}: {
  onClick: (key: string, record: any) => void;
  tiers: any;
  currencies: IReference[];
  variantPriceHistoryStatus: IReference[];
  Tag: any;
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record) => (
      <Flex align={"center"} gap={"xs"} pl={"xs"} miw={60}>
        <ActionIcon onClick={() => onClick("remove", record)} variant="light" color="">
          <IconTrash size={"1.2rem"} />
        </ActionIcon>
      </Flex>
    ),
  },
  {
    title: "Үнийн код",
    dataIndex: "refCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.refCode || "-"}
      </Text>
    ),
  },
  {
    title: "Үнийн өөрчлөлт нэр",
    dataIndex: "name",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Үнэ статус",
    dataIndex: "historyStatus",
    render: (record) =>
      Tag(
        variantPriceHistoryStatus.find((item: IReference) => item.code === record.historyStatus)?.color || "gray",
        variantPriceHistoryStatus.find((item: IReference) => item.code === record.historyStatus)?.name || record.historyStatus || "Үүсээгүй",
      ),
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record.image} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Бараа, бүтээгдэхүүний нэр",
    dataIndex: "nameMon",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.nameMon || "-"}
      </Text>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => (
      <Text size="sm" w="max-content" c="violet">
        {record?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unitName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitName || "-"}
      </Text>
    ),
  },
  {
    title: "Үндсэн нэгж эсэх",
    dataIndex: "isBaseUnit",

    render: (record) => {
      return (
        <Badge variant="outline" color={record.isBaseUnit ? "green.5" : "gray.5"}>
          {record.isBaseUnit ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үндсэн нэгжээр тоо",
    dataIndex: "unitConvertValue",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitConvertValue || 1}
      </Text>
    ),
  },
  {
    title: "Үнэ тооцох тоо",
    dataIndex: "-",
    render: (record) => (
      <Text size="sm" w="max-content">
        1
      </Text>
    ),
  },
  {
    title: "Валют",
    dataIndex: "currency",
    render: (record) => (
      <Text size="sm" w="max-content">
        {currencies.find((item: IReference) => item.code === record.currency)?.name || "Төгрөг"}
      </Text>
    ),
  },
  {
    title: "Стандарт үнэ",
    dataIndex: "standardPrice",
    render: (record, index) => (
      <Group justify="right">
        <NumberCurrencyField size="xs" name={`variants[${index}].standardPrice`} placeholder="0.00" />
      </Group>
    ),
  },
  {
    title: tiers?.find((item: any) => item.tierNo === 1)?.name || "Tier 1",
    dataIndex: "tier1Price",
    render: (record, index) => (
      <Box w="max-content">{record?.tier1Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier1Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers?.find((item: any) => item.tierNo === 2)?.name || "Tier 2",
    dataIndex: "tier2Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier2Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier2Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers?.find((item: any) => item.tierNo === 3)?.name || "Tier 3",
    dataIndex: "tier3Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier3Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier3Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 4)?.name || "Tier 4",
    dataIndex: "tier4Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier4Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier4Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 5)?.name || "Tier 5",
    dataIndex: "tier5Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier5Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier5Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 6)?.name || "Tier 6",
    dataIndex: "tier6Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier6Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier6Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 7)?.name || "Tier 7",
    dataIndex: "tier7Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier7Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier7Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 8)?.name || "Tier 8",
    dataIndex: "tier8Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier8Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier8Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 9)?.name || "Tier 9",
    dataIndex: "tier9Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier9Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier9Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 10)?.name || "Tier 10",
    dataIndex: "tier10Price",
    render: (record, index) => (
      <Box w="max-content">{record.tier10Price ? <NumberCurrencyField size="xs" name={`variants[${index}].tier10Price.value`} placeholder="0.00" /> : "-"}</Box>
    ),
  },
];

export default ValuesVariantsList;
