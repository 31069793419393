import { store } from "../store";
import { authChange } from "../store/auth";
import { HttpHandler } from "./http-handler";
import { HttpRequest as BaseHttpRequest } from "./http-request";

export class HttpRequest extends BaseHttpRequest {
  store = store;
  errorHandler = (statusCode: number, error: HttpHandler): HttpHandler => {
    if (statusCode === 401) {
      store.dispatch(authChange());

      window.location.href = "https://cp.dehub.mn/login";
    }

    throw error;
  };
}

function currencyFormat(num: number, position?: "left" | "right") {
  if (position === "left")
    return (
      "₮ " +
      parseFloat(`${num}`)
        ?.toFixed(2)
        ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  else {
    return (
      parseFloat(`${num}`)
        ?.toFixed(2)
        ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " ₮"
    );
  }
}
const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export { currencyFormat, regexExp };
