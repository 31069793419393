import { ActionIcon, Avatar, Badge, Flex, Grid, Group, Paper, SegmentedControl, Select, Text, Tooltip } from "@mantine/core";
import { IconChevronRight, IconFilterOff, IconHistory, IconPhoto } from "@tabler/icons-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PriceGroupGoodsApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { usePermission } from "../permission";

type IFilterTypes = {
  name: string;
  filter: string;
  select: {
    label: string;
    value: string;
    color?: string;
  }[];
  type: "select" | "segmented";
};

const filterInit = {
  supplierType: null,
  itemTypeId: null,
  classificationId: null,
  subClassificationId: null,
  categoryId: null,
  subCategoryId: null,
};

export function GroupPriceList() {
  const { itemTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const ERP_STD_PRICE = usePermission("ERP_STD_PRICE");

  const tableRef = React.useRef<any>(null);
  const [filters, setFilters] = useState<any>(filterInit);
  const { tiers, categories, currencies, supplierTypes, variantPriceHistoryStatus } = useSelector((state: { general: IGeneral }) => state?.general);
  const navigate = useNavigate();

  const filtersValues: IFilterTypes[] = [
    {
      name: "Захиалгын төрөл:",
      filter: "supplierType",
      select:
        [...supplierTypes]?.map((item: IReference) => {
          return {
            label: item?.name || "",
            value: item?.code,
            color: item?.color || undefined,
          };
        }) || [],
      type: "segmented",
    },
    {
      name: "Нэр төрөл",
      filter: "itemTypeId",
      select:
        itemTypes?.map((item: IReference) => {
          return {
            label: item?.name || "",
            value: item?.id || "",
          };
        }) || [],
      type: "select",
    },
    {
      name: "Ангилал",
      filter: "classificationId",
      select:
        categories
          ?.filter((f) => f.type === "CLASSIFICATION")
          ?.map((item: IReference) => {
            return {
              label: item?.name || "",
              value: item?.id || "",
            };
          }) || [],

      type: "select",
    },
    {
      name: "Дэд ангилал",
      filter: "subClassificationId",
      select:
        categories
          ?.filter((f) => f.type === "SUB_CLASSIFICATION")
          ?.map((item: IReference) => {
            return {
              label: item?.name || "",
              value: item?.id || "",
            };
          }) || [],

      type: "select",
    },
    {
      name: "Категори",
      filter: "categoryId",
      select:
        categories
          ?.filter((f) => f.type === "SUB_CLASSIFICATION")
          ?.map((item: IReference) => {
            return {
              label: item?.name || "",
              value: item?.id || "",
            };
          }) || [],
      type: "select",
    },
    {
      name: "Дэд категори",
      filter: "subCategoryId",
      select:
        categories
          ?.filter((f) => f.type === "SUB_CATEGORY")
          ?.map((item: IReference) => {
            return {
              label: item?.name || "",
              value: item?.id || "",
            };
          }) || [],
      type: "select",
    },
  ];

  const columns = useHeader({
    variantPriceHistoryStatus,
    Tag,
    currencies,
    onClick: (key, record) => {
      switch (key) {
        case "history":
          navigate(`/policy/price/history/${record.variantId}`);
          break;
        default:
          break;
      }
    },
    tiers,
  });

  return (
    <Flex direction="column" gap="md">
      {ERP_STD_PRICE.hasAccess ? (
        <>
          <Grid align="end">
            {filtersValues?.map((item: IFilterTypes, index: number) => {
              if (item.type === "segmented")
                return (
                  <Grid.Col span={12} key={index}>
                    <div>
                      <Text color="gray" size={"xs"}>
                        {item.name}
                      </Text>
                      <SegmentedControl
                        h={"36px"}
                        w={"100%"}
                        maw={"500px"}
                        value={filters?.[item.filter] || null}
                        onChange={(e) => {
                          setFilters({
                            ...filters,
                            [item.filter]: e || null,
                          });
                        }}
                        data={item.select.map((item2: any, index2: number) => {
                          return {
                            label: (
                              <Flex gap={8} align={"center"} key={index2} justify={"center"}>
                                {item2.color && <Paper h={"7px"} w={"7px"} bg={item2.color}></Paper>}
                                <Text fz={12}>{item2.label}</Text>
                              </Flex>
                            ),
                            value: item2.value,
                          };
                        })}
                      />
                    </div>
                  </Grid.Col>
                );
              else
                return (
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }} key={index}>
                    <Select
                      clearable
                      value={filters?.[item.filter]}
                      onChange={(e) => {
                        setFilters({ ...filters, [item.filter]: e });
                      }}
                      leftSectionWidth={150}
                      searchable
                      placeholder="Сонгоно уу"
                      style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
                      data={item.select}
                      leftSection={
                        <Group w={"calc(100% - 20px)"} align="center">
                          <Text fz={"sm"} c="gray.7">
                            {item.name}
                          </Text>
                          <Text>
                            <IconChevronRight size={13} />
                          </Text>
                        </Group>
                      }
                    />
                  </Grid.Col>
                );
            })}
            <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
              <ActionIcon
                onClick={() => {
                  setFilters(filterInit);
                }}
                color=""
                variant="light"
                size={36}>
                <IconFilterOff />
              </ActionIcon>
            </Grid.Col>
          </Grid>
          <Table ref={tableRef} name="group.list" columns={columns} filters={filters} loadData={(data) => PriceGroupGoodsApi.list(data!)} />
        </>
      ) : (
        ERP_STD_PRICE.accessDenied()
      )}
    </Flex>
  );
}

const useHeader = ({
  onClick,
  tiers,
  currencies,
  variantPriceHistoryStatus,
  Tag,
}: {
  onClick: (key: string, record: any) => void;
  tiers: any;
  currencies: IReference[];
  variantPriceHistoryStatus: IReference[];
  Tag: any;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_record, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Үйлдэл",
    render: (record) => (
      <Tooltip label="Түүх харах">
        <ActionIcon onClick={() => onClick("history", record)} variant="light" color="">
          <IconHistory />
        </ActionIcon>
      </Tooltip>
    ),
  },
  {
    title: "Үнийн код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Үнийн өөрчлөлт нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Үнэ статус",
    sorter: true,
    dataIndex: "historyStatus",
    render: (record) =>
      Tag(
        variantPriceHistoryStatus.find((item: IReference) => item.code === record.historyStatus)?.color,
        variantPriceHistoryStatus.find((item: IReference) => item.code === record.historyStatus)?.name || record.historyStatus || "-",
      ),
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record.image ?? ""} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Бараа, бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "variantName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.variantName ?? "-"}
      </Text>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.skuCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unitName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitName ?? "-"}
      </Text>
    ),
  },
  {
    title: "Үндсэн нэгж эсэх",
    sorter: true,
    dataIndex: "isBaseUnit",
    render: (record) => (
      <Badge variant="outline" color={record.isBaseUnit ? "green" : "gray"}>
        {record.isBaseUnit ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Үндсэн нэгжээр тоо",
    sorter: true,
    dataIndex: "unitConvertValue",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitConvertValue ?? 0}
      </Text>
    ),
  },
  {
    title: "Үнэ тооцох тоо",
    sorter: true,
    dataIndex: "unit",
    render: (record) => "1",
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => (
      <Text size="sm" w="max-content">
        {currencies.find((item: IReference) => item.code === record.currency)?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Эхэлсэн огноо",
    sorter: true,
    dataIndex: "unit",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.startedDate ? dateTimeFormat(record.startedDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Стандарт үнэ",
    sorter: true,
    dataIndex: "standardPrice",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record.standardPrice ? currencyFormat(record?.standardPrice || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 1)?.name || "Tier 1",
    sorter: true,
    dataIndex: "tier1Price",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record.tier1Price ? currencyFormat(record?.tier1Price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 2)?.name || "Tier 2",
    sorter: true,
    dataIndex: "tier2Price",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record.tier2Price ? currencyFormat(record?.tier2Price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 3)?.name || "Tier 3",
    sorter: true,
    dataIndex: "tier3Price",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record.tier3Price ? currencyFormat(record?.tier3Price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 4)?.name || "Tier 4",
    sorter: true,
    dataIndex: "tier4Price",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record.tier4Price ? currencyFormat(record?.tier4Price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 5)?.name || "Tier 5",
    sorter: true,
    dataIndex: "tier5Price",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record.tier5Price ? currencyFormat(record?.tier5Price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 6)?.name || "Tier 6",
    sorter: true,
    dataIndex: "tier6Price",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record.tier6Price ? currencyFormat(record?.tier6Price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 7)?.name || "Tier 7",
    sorter: true,
    dataIndex: "tier7Price",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record.tier7Price ? currencyFormat(record?.tier7Price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 8)?.name || "Tier 8",
    sorter: true,
    dataIndex: "tier8Price",
    render: (record) => (
      <Group align="right">
        <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
          {record.tier8Price ? currencyFormat(record?.tier8Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 9)?.name || "Tier 9",
    sorter: true,
    dataIndex: "tier9Price",
    render: (record) => (
      <Group align="right">
        <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
          {record.tier9Price ? currencyFormat(record?.tier9Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 10)?.name || "Tier 10",
    sorter: true,
    dataIndex: "tier10Price",
    render: (record) => (
      <Group align="right">
        <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
          {record.tier10Price ? currencyFormat(record?.tier10Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
];
