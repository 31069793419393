import { Badge, Input, Paper, Text, Tooltip } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useDebouncedState } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { ReferenceApi } from "../../apis";
import { PageFilter, PageLayout } from "../../components/layout";
import { ColumnType, Table } from "../../components/table";
import { IReference } from "../../interfaces/IReference";

export const ReferenceList = () => {
  const breadcrumbs = useBreadcrumb();
  const { classes } = useStyles();
  const [query, setQuery] = useDebouncedState("", 500);

  const columns = useHeader({
    classes,
  });

  return (
    <PageLayout
      title="Барааны лавлах мэдээлэл"
      subTitle="Барааны лавлах мэдээллийн жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <PageFilter
          left={[]}
          right={[
            <Tooltip key={0} label="Лавлахын нэр, кодоор хайх" color="indigo" position="top-end" withArrow>
              <Input
                onChange={(e: any) => setQuery(e.currentTarget.value)}
                placeholder="Хайлт хийх"
                rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
              />
            </Tooltip>,
          ]}
        />,
      ]}>
      <Paper withBorder radius="md" p="md">
        <Table name="reference.list" columns={columns} filters={{ query: query, type: "ITEM_REFERENCE" }} loadData={(data) => ReferenceApi.list(data!)} />
      </Paper>
    </PageLayout>
  );
};

type ColumnProps = {
  classes: { [key: string]: string };
};

const useHeader = ({ classes }: ColumnProps): ColumnType<IReference>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Лавлах код",
    sorter: true,
    dataIndex: "ref",
    width: 140,
    render: (record) => <Text size="sm">{record.refCode}</Text>,
  },
  {
    title: "Лавлах нэр",
    sorter: true,
    dataIndex: "type",
    width: 240,
    render: (record) => {
      return (
        <Link to={`/reference/${record.listType}`}>
          <Badge
            radius="xs"
            color="indigo.7"
            className={classes.reference}
            styles={{
              label: {
                overflow: "visible",
              },
            }}>
            {record.name}
          </Badge>
        </Link>
      );
    },
  },
  {
    title: "Лавлах төрөл",
    sorter: true,
    dataIndex: "list",
    width: 240,
    render: (record) => {
      return (
        <Badge
          radius="xs"
          color="blue.5"
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {record.listType}
        </Badge>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.description}
      </Text>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Лавлах мэдээлэл",
  },
];

const useStyles = createStyles((theme) => ({
  reference: {
    "&:hover": {
      background: theme.colors.dark[9],
    },
  },
}));
