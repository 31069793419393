import { Alert, Button, Divider, Grid, Group, Input, LoadingOverlay, Modal } from "@mantine/core";
import { IconBox, IconPlus } from "@tabler/icons-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { GeneralApi, GoodsApi } from "../../apis";
import { IField } from "../../interfaces/IField";
import { IGeneral } from "../../interfaces/IGeneral";
import { ISection } from "../../interfaces/ISection";
import { Field } from "../../models/Field";
import { initGeneral } from "../../store/general";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { MultiSelectField } from "../form/multi-select-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";
import { usePermission } from "../permission";
import { SectionFieldForm } from "../reference/section-field-form";
import { createStyles } from "@mantine/emotion";

type AdditionalInfomationFormProps = {
  editable: any[];
  reload: () => void;
  tabChange: () => void;
  onTabChange: any;
};

const AdditionalInfomationForm = React.memo(({ editable, tabChange, reload, onTabChange }: AdditionalInfomationFormProps) => {
  const navigate = useNavigate();
  const ERP_GDS = usePermission("ERP_GDS");
  const { classes } = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [action, setAction] = React.useState<string[]>([]);
  const dispatch = useDispatch();
  const { sections } = useSelector((state: { general: IGeneral }) => state.general);
  const formRef = React.useRef<IFormRef<{ sectionIds?: string[]; itemFieldValues: IField[] }>>(null);

  const [data, setFormData] = React.useState({
    sectionFields: [],
    hasVariant: false,
    departmentUnitId: undefined,
    departmentSubUnitId: undefined,
    deliveryTypeId: undefined,
    returnAllow: false,
    returnType: undefined,
    packageTypeId: undefined,
    isCompleted: false,
    ...(editable && editable[0] === "update" ? { ...editable[1], ...(splitField(editable[1]?.fields) || {}) } : {}),
  });

  const onSubmit = async (values: any, isNext: boolean) => {
    setLoading(true);

    try {
      if (ERP_GDS.isEdit) {
        const _values = {
          itemFieldValues: [...(values?.itemFieldValues || []), ...(values?.sectionFields || [])]?.map((item: any) => {
            return convertToField(item);
          }),
          sectionIds: values.sectionIds,
        };

        await GoodsApi.additionalInfo(data.id, _values);
        message.success("Амжилттай хадгаллаа!");
        reload();
      }

      if (isNext) tabChange();
      else navigate("/product");
      setLoading(false);
    } catch (error) {
      message.error((error as HttpHandler)?.message!);
      setLoading(false);
    }
  };

  const changeSchema = (fields: IField[], sectionFields: IField[]) => {
    return yup.object({
      itemFieldValues: yup.array().of(
        yup.object({
          ...fields.reduce((acc, iter) => {
            return {
              ...acc,
              [`${iter.id}`]: yupValidateType(iter.type),
            };
          }, {} as { [key: string]: any }),
        }),
      ),

      sectionFields: yup.array().of(
        yup.object({
          ...sectionFields?.reduce((acc, iter) => {
            return {
              ...acc,
              [`${iter.id}`]: yupValidateType(iter.type),
            };
          }, {} as { [key: string]: any }),
        }),
      ),
    });
  };

  const changeInitData = () => {
    const sectionFields = data.sectionFields
      ?.filter((f: IField) => f.sectionId)
      .map((field: IField) => {
        return {
          ...field,
          ...converToFormData(
            field,
            (data.itemFieldValues || []).reduce((acc: any, iter: any) => {
              return {
                ...acc,
                [`${iter.fieldId}`]: { ...iter },
              };
            }, []),
          ),
        };
      });

    return {
      itemFieldValues: [
        ...(data.fields || []).map((field: any) => {
          return {
            ...field,
            ...converToFormData(
              field,
              (data.itemFieldValues || []).reduce((acc: any, iter: any) => {
                return {
                  ...acc,
                  [`${iter.fieldId}`]: { ...iter },
                };
              }, []),
            ),
          };
        }),
      ],
      sectionIds: [...(data.sectionIds || [])],
      sectionFields: sectionFields,
    };
  };

  const onCancel = async () => {
    setAction([]);
  };

  const init = async () => {
    const res = await GeneralApi.init();
    dispatch(initGeneral(res));
  };

  const onChangeSelect = (sectionIds: string[] | null, values: any, setFieldValue: (name: string, value: any) => void) => {
    const sectionFieldValues =
      ((values?.sectionFields || []) as { [key: string]: any }[])?.reduce((accumualtor, iterator) => {
        return {
          ...accumualtor,
          ...iterator,
        };
      }, {} as { [key: string]: any }) || {};

    const fields = (sections || [])
      .filter((s: ISection) => sectionIds!?.indexOf(s.id) > -1)
      ?.reduce((accumulator, iterator) => {
        return [...accumulator, ...iterator.fields];
      }, [] as IField[]);

    const sectionFields = fields?.map((field: IField) => {
      return {
        ...field,
        [field.id!]: sectionFieldValues[field.id!] || undefined,
      };
    });

    setFormData({
      ...data,
      sectionFields: sectionFields,
    });

    setFieldValue("sectionFields", sectionFields);
    init();
  };

  if (!data)
    return (
      <div>
        <LoadingOverlay visible />
      </div>
    );
  return (
    <div>
      <LoadingOverlay visible={loading} />
      <Form ref={formRef} onSubmit={(val) => {}} validationSchema={changeSchema(data?.fields, data.sectionFields || [])} initialValues={changeInitData()}>
        {({ values, setFieldValue, errors }) => {
          console.log("form valid errors: ", errors);
          return (
            <div>
              <FormLayout
                my={20}
                title="Категорийн нэмэлт мэдээлэл"
                extra={[
                  <Button
                    key={0}
                    onClick={() => {
                      onTabChange("1");
                    }}
                    variant="default">
                    Өмнөх
                  </Button>,
                  <Button
                    hidden={!ERP_GDS.isEdit}
                    key={1}
                    variant="outline"
                    onClick={async () => {
                      let res = await formRef.current?.submit();
                      if (res) onSubmit(res, false);
                    }}>
                    Хадгалах
                  </Button>,
                  <Button
                    key={2}
                    onClick={async () => {
                      let res = await formRef.current?.submit();
                      if (res) onSubmit(res, true);
                    }}>
                    Үргэлжлүүлэх
                  </Button>,
                ]}>
                <Divider mb="lg" />
                <Grid>
                  {data.fields.length > 0 ? (
                    data.fields.map((field: IField, index: number) => {
                      return (
                        <Grid.Col key={index} span={4}>
                          <GenerateField field={field} name={`itemFieldValues[${index}]`} />
                        </Grid.Col>
                      );
                    })
                  ) : (
                    <Grid.Col>
                      <Alert icon={<IconBox />}>Бараа бүтээгдэхүүн, ажил үйлчилгээний дэд категорид хамаарах нэмэлт мэдээлэл тохируулаагүй байна.</Alert>
                    </Grid.Col>
                  )}
                </Grid>
              </FormLayout>
              <FormLayout title="Бүтээгдэхүүний нэмэлт мэдээлэл" subTitle="">
                <Input.Wrapper label="Нэмэлт мэдээллийг сонгоно уу" required>
                  <Group>
                    <MultiSelectField
                      name="sectionIds"
                      placeholder="Сонголт хийнэ үү."
                      options={(sections ?? []).map((s) => ({ label: s.name, value: s.id }))}
                      onChange={(value) => onChangeSelect(value, values, setFieldValue)}
                    />
                    <Button variant="light" style={{ width: 40, padding: 0 }} onClick={() => setAction(["SECTION"])}>
                      <IconPlus />
                    </Button>
                  </Group>
                </Input.Wrapper>
                <div className={classes.space} />

                <Divider mb="lg" />

                <Grid>
                  {(() => {
                    const fields = sections
                      .filter((s: ISection) => values?.sectionIds?.indexOf(s.id) > -1)
                      ?.reduce((accumulator, iterator) => {
                        return [...accumulator, ...iterator.fields];
                      }, [] as IField[]);
                    if (fields.length > 0) {
                      return fields?.map((field: IField, index: number) => {
                        return (
                          <Grid.Col key={index} span={4}>
                            <GenerateField field={field} name={`sectionFields[${index}]`} />
                          </Grid.Col>
                        );
                      });
                    } else
                      return (
                        <Grid.Col>
                          <Alert icon={<IconBox />}>Бүтээгдэхүүний нэмэлт мэдээлэл оруулахаар тохируулаагүй байна.</Alert>
                        </Grid.Col>
                      );
                  })()}
                </Grid>
              </FormLayout>
            </div>
          );
        }}
      </Form>
      <Modal opened={action[0] === "SECTION"} onClose={() => setAction([])} withCloseButton={false} size="90%" centered>
        <SectionFieldForm
          reload={() => {
            reload();
          }}
          action={action}
          onCancel={onCancel}
        />
      </Modal>
    </div>
  );
});

const yupValidateType = (type: string) => {
  switch (type) {
    case "CHECKBOX":
      return yup.boolean().nullable();
    case "NUMBER":
      return yup.number().required("Заавал бөглөнө!").typeError("Тоо оруулна уу!");
    case "SELECT":
      return yup.string().required("Заавал бөглөнө!").nullable();
    default:
      return yup.string().required("Заавал бөглөнө!");
  }
};

const converToFormData = (field: IField, data: any) => {
  const fieldValue = data[`${field.id}`];

  switch (fieldValue?.fieldType) {
    case "TEXT":
      return { [`${field.id}`]: fieldValue.text };
    case "NUMBER":
      return { [`${field.id}`]: fieldValue.number, numberUnit: fieldValue.numberUnit || "KILOGRAM" };
    case "SELECT":
      return { [`${field.id}`]: fieldValue?.fieldValue?.id };
    default: {
      if (field.type === "CHECKBOX") return { [`${field.id}`]: fieldValue?.checked || false };
      return undefined;
    }
  }
};

const splitField = (fields: Field[]) => {
  return {
    fields: fields.filter((field) => field.sectionId === undefined || null)?.map((c: any) => ({ ...c, [`${c.id}`]: undefined })),
    sectionFields: fields.filter((field) => field.sectionId !== undefined || field.sectionId !== "undefined"),
  };
};

const GenerateField = ({ field, name }: { field: IField; name: string }) => {
  const { classes } = useStyles();
  const { numberUnits } = useSelector((state: { general: IGeneral }) => state.general);

  const renderField = () => {
    switch (field.type) {
      case "TEXT":
        return <TextField name={`${name}.${field.id}`} placeholder={`${field.name}`} label={field.name} />;
      case "NUMBER":
        return (
          <NumberField
            name={`${name}.${field.id}`}
            placeholder={`${field.name}`}
            classNames={{
              input: classes.input,
            }}
            rightSection={
              <SelectField
                name={`${name}.numberUnit`}
                clearable={false}
                classNames={{
                  wrapper: classes.select,
                }}
                options={
                  [
                    ...numberUnits?.map((item) => {
                      return {
                        label: item.value,
                        value: item.code,
                      };
                    }),
                  ] as {
                    value: string;
                    label: string;
                  }[]
                }
                placeholder={"КГ"}
              />
            }
            label={field.name}
          />
        );
      case "SELECT":
        return (
          <SelectField
            name={`${name}.${field.id}`}
            placeholder={`${field.name}`}
            label={field.name}
            options={[
              ...(field?.values?.map((item: { name: any; id: any }) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              }) as any),
            ]}
          />
        );
      case "CHECKBOX":
        return <CheckboxField name={`${name}.${field?.id}`} label={field?.name} />;
      default:
        console.log("feild error: ", {
          name: `${name}.${field?.id}`,
          label: field?.name || "no name",
        });

        return null;
    }
  };

  return <div>{renderField()}</div>;
};

const convertToField = (item: any) => {
  switch (item.type) {
    case "NUMBER":
      return {
        fieldId: item.id,
        fieldType: item.type,
        number: item[`${item.id}`],
        numberUnit: item.numberUnit,
        sectionId: item?.sectionId,
      };
    case "TEXT":
      return {
        fieldId: item.id,
        fieldType: item.type,
        text: item[`${item.id}`],
        sectionId: item?.sectionId,
      };
    case "CHECKBOX":
      return {
        fieldId: item.id,
        fieldType: item.type,
        checked: item[`${item.id}`] || false,
        sectionId: item?.sectionId,
      };
    default:
      return {
        fieldId: item?.id,
        fieldType: item?.type,
        fieldValueId: item?.[`${item.id}`],
        sectionId: item?.sectionId,
      };
  }
};

AdditionalInfomationForm.displayName = "AdditionalInfomationForm";

const useStyles = createStyles((theme) => ({
  space: {
    marginTop: 20,
  },
  select: {
    width: 100,
    marginRight: 62,
  },
  input: {
    paddingRight: 104,
  },
  sectionContainer: {
    display: "flex",
    paddingTop: 10,
    paddingBottom: 10,
    gap: 8,
  },
  pill: {
    borderWidth: 1,
    borderRadius: 7,
    backgroundColor: "#F0F3F5",
    borderColor: "#DFE6E9",
    padding: 2,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    gap: 5,
    fontWeight: "bold",
    color: "#44566C",
  },
}));

export { AdditionalInfomationForm };
