import { Button, Divider, Group, LoadingOverlay, Modal, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { CategoryApi } from "../../apis";
import { ICategory } from "../../interfaces/ICategory";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { ColumnType, ITableRef, Table } from "../table";
import { ReferenceSubCategoryForm } from "./reference-sub-category-form";

export function SubCategoryList() {
  const navigate = useNavigate();
  const [filters] = React.useState({ query: "", type: "SUB_CATEGORY" });
  const [action, setAction] = React.useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ERP_REF_ITM_CLS = usePermission("ERP_REF_ITM_CLS");

  const ref = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState(false);

  const columns = useHeader({
    actionAccess: ERP_REF_ITM_CLS,
    onClick: async (key, item) => {
      switch (key) {
        case "edit": {
          setIsLoading(true);

          try {
            let res = await CategoryApi.get(item.id);

            setAction([
              "update",
              {
                id: item.id,
                name: item.name,
                description: item.description,
                itemTypeId: item.itemTypeId,
                classificationId: item?.classification?.id,
                subClassificationId: item.subClassification?.id,
                categoryId: item.category?.id,
                categoryFields: res.fields,
              },
            ]);
          } catch (error: any) {
            error.message && message.error(error.message);
          }

          setIsLoading(false);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  let res = await CategoryApi.remove(item.id);
                  if (res.success) {
                    setTimeout(() => {
                      ref.current?.reload();
                    }, 200);
                  }
                  message.success("Амжилттай категорыг устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const reload = async () => {
    await ref.current?.reload();
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Дэд категори"
        subTitle="Бараа бүтээгдэхүүн, ажил үйлчилгээний дэд категорийн жагсаалт"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference")}>
            Буцах
          </Button>,
          <Button
            hidden={!ERP_REF_ITM_CLS.isCreate}
            key={2}
            onClick={() => {
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Divider my="lg" />
        {ERP_REF_ITM_CLS.isView ? (
          <Table ref={ref} name="sub.category.list" columns={columns} filters={filters} loadData={(data) => CategoryApi.list(data!)} />
        ) : (
          ERP_REF_ITM_CLS.accessDenied()
        )}
      </PageLayout>

      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} centered size={"64rem"}>
        <ReferenceSubCategoryForm action={action} onCancel={() => setAction([])} reload={reload} />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: ICategory) => void;
  actionAccess: {
    isDelete: boolean;
    isEdit: boolean;
    isView: boolean;
  };
};

const useHeader = ({ onClick, actionAccess }: HeaderProps): ColumnType<ICategory>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Код",
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр төрөл",
    dataIndex: "itemType",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.itemType?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Ангилал",
    dataIndex: "classification",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.classification?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дэд ангилал",
    dataIndex: "subClassification",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.subClassification?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Категори",
    dataIndex: "category",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.category?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дэд категори",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Group gap={10}>
          <Tooltip label="Засах">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} hidden={!actionAccess.isEdit}>
              <IconEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Устгах">
            <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)} hidden={!actionAccess.isDelete}>
              <IconTrash />
            </Button>
          </Tooltip>
        </Group>
      );
    },
  },
];
