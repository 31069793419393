import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const upload = async (data: FormData | any) => {
  return httpRequest.upload("/erp/general/upload", data);
};

export const init = async () => {
  return httpRequest.get("/erp/general/init");
};
