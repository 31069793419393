import { ActionIcon, Flex, Grid, Modal, Paper, Space, Tooltip } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPackage } from "../../interfaces/IPackage";
import { IReference } from "../../interfaces/IReference";
import { Reference } from "../../models/Reference";
import { Unit } from "../../models/Unit";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { FormLayout } from "../layout";
import { DeliveryForm } from "../reference/delivery-form";
import { PackageForm } from "../reference/package-form";
import { UnitForm } from "../reference/unit-form";
import { ColumnType, Table } from "../table";

const schema = yup.object().shape({
  hasVariant: yup.boolean(),
  baseUnitId: yup.string().required("Заавал бөглөнө!").nullable(),
  departmentUnitId: yup.string().required("Заавал бөглөнө!").nullable(),
  departmentSubUnitId: yup.string().required("Заавал бөглөнө!").nullable(),
  hasAdditionalUnit: yup.boolean(),
  additionalUnits: yup.array().when("hasAdditionalUnit", (hasAdditionalUnit, schema) => {
    if (hasAdditionalUnit) {
      return schema.of(
        yup.object().shape({
          unitId: yup.string().required("Заавал бөглөнө!").nullable(),
          convertType: yup.string().required("Заавал бөглөнө!").nullable(),
          convertValue: yup.number().required("Заавал бөглөнө!").nullable(),
          floatValue: yup.number().nullable().required("Заавал бөглөнө!").optional(),
        }),
      );
    }
    return schema;
  }),
});

type IUnit = {
  unitId: string;
  convertType: string;
  convertValue: number;
  floatValue: number;
  parentId: string;
  id: string;
};

export type IOrderSettingsForm = {
  hasVariant: boolean;
  baseUnitId?: string;
  weight: number;
  weightLabel: string;
  length: number;
  height: number;
  width: number;
  spaceLabel: string;
  returnAllow: boolean;
  returnType?: string;
  deliveryTypeId: string;
  packageTypeId: string;
  minBalance: number;
  maxBalance: number;
  minOrderNum: number;
  reOrder: boolean;
  reOrderNum?: number;
  reOrderMinNum: number;
  hasAdditionalUnit: boolean;
  departmentUnitId: string;
  departmentSubUnitId: string;
  isCompleted: boolean;
  additionalUnits?: IUnit[];
  variants?: any;
  optionIds?: any[];
  optionValues?: any[];
};

type Props = {
  editable?: any | [string, IOrderSettingsForm];
  productRefCode: any;
  orderSettingsFormRef: any;
};

const ServiceOrderSettingsForm = React.memo(({ editable, productRefCode, orderSettingsFormRef }: Props) => {
  const { departmentUnits, units, packageTypes, unitSpaceLabels, unitWeightLabels, unitConvertTypes } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const [action, setAction] = useState<any[]>([]);

  const [data] = React.useState<IOrderSettingsForm>({
    hasVariant: false,
    baseUnitId: undefined,
    departmentUnitId: undefined,
    departmentSubUnitId: undefined,
    hasAdditionalUnit: true,
    additionalUnits: [
      {
        unitId: undefined,
        convertType: undefined,
        convertValue: 0,
        floatValue: 0,
        parentId: undefined,
        id: undefined,
      },
    ],
    ...(editable && editable[0] === "update"
      ? { ...editable[1], baseUnitId: editable[1]?.itemUnits?.find((f: any) => f.isBase)?.unitId, ...editable[1]?.itemUnits?.find((f: any) => f.isBase) }
      : {}),
    ...(editable && editable[0] === "update" ? { ...(editable[1]?.variants[0] && editable[1]?.variants[0]) } : {}),
  });

  const onRemoveRow = (row: any, index: number, values: IUnit[], setFieldValue: (name: string, value: any) => void) => {
    values?.splice(index, 1);
    if (values?.length === 0) {
      setFieldValue("additionalUnits", undefined);
      return;
    }
    setFieldValue("additionalUnits", [...values]);
    console.log(values);
    // setFieldValue("units", [...values]);
  };

  const columns = (values: any, setFieldValue: (name: string, value: any) => void) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useHeader({
      unitSpaceLabels,
      unitWeightLabels,
      units,
      unitConvertTypes,
      packageTypes,
      onRemoveRow,
      values,
      setFieldValue,
    });
  };

  const addRow = (additionalUnits: IUnit[] = [], setFieldValue: (name: string, value: any) => void) => {
    setFieldValue("additionalUnits", [...additionalUnits, initialUnit]);
  };

  return (
    <div>
      <Form ref={orderSettingsFormRef} validationSchema={schema} onSubmit={(e) => {}} initialValues={data}>
        {({ values, setFieldValue, errors }) => {
          return (
            <div>
              <Paper my={"lg"}>
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <Flex align={"end"} gap={8}>
                      <SelectField
                        rightSection={
                          <Tooltip label="Нэгж нэмэх">
                            <ActionIcon onClick={() => setAction(["unit_new"])} variant="light" color="violet">
                              <IconPlus size={18} />
                            </ActionIcon>
                          </Tooltip>
                        }
                        name="baseUnitId"
                        label="Үндсэн хэмжих нэгж"
                        placeholder="Үндсэн хэмжих нэгж"
                        options={units?.map((item) => ({ label: item.name, value: item.id }))}
                      />
                    </Flex>
                  </Grid.Col>
                </Grid>
                <Space h={"sm"} />
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <CheckboxField
                      onChangeCustom={() => {
                        setFieldValue("additionalUnits", undefined);
                      }}
                      name="hasAdditionalUnit"
                      label="Нэмэлт хэмжих нэгж бий эсэх"
                    />
                  </Grid.Col>
                </Grid>
              </Paper>

              {values?.hasAdditionalUnit && (
                <Grid>
                  <Grid.Col>
                    <ActionIcon variant="outline" onClick={() => addRow(values.additionalUnits, setFieldValue)}>
                      <IconPlus />
                    </ActionIcon>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Table
                      minWidth={600}
                      name="additionalUnits"
                      columns={columns(values, setFieldValue)}
                      dataSource={values.additionalUnits}
                      pagination={false}
                    />
                  </Grid.Col>
                </Grid>
              )}

              <FormLayout my={20} title="Түгээлт, хүргэлт" subTitle="">
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <SelectField
                      onChange={() => {
                        setFieldValue("departmentSubUnitId", undefined);
                      }}
                      name="departmentUnitId"
                      placeholder="Харьяалах нэгж"
                      label="Харьяалах нэгж"
                      options={departmentUnits
                        .filter((item) => !item.parentId)
                        .map((item: any) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                    <SelectField
                      disabled={!values.departmentUnitId}
                      name="departmentSubUnitId"
                      placeholder="Дэд нэгж"
                      label="Дэд нэгж"
                      options={departmentUnits
                        .filter((item) => item.parentId === values.departmentUnitId)
                        .map((item: any) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })}
                    />
                  </Grid.Col>
                </Grid>
              </FormLayout>
            </div>
          );
        }}
      </Form>
      <Modal opened={action[0] === "unit_new"} onClose={() => setAction([])} withCloseButton={false} centered>
        <UnitForm
          title="Нэгж"
          subTitle={action[0] === "unit_new" ? "Нэгж нэмэх" : "Нэгж засах"}
          onCancel={() => setAction([])}
          action={action}
          payload={action[1] && action[1]}
          reloadTable={() => {}}
        />
      </Modal>
      <Modal opened={action[0] === "deliivery_add"} onClose={() => setAction([])} withCloseButton={false} centered>
        <DeliveryForm
          title="Хүргэлтийн нөхцөл"
          subTitle={action[0] === "deliivery_add" ? "Хүргэлтийн нөхцөл нэмэх" : "Хүргэлтийн нөхцөлийг засах"}
          onCancel={() => setAction([])}
          action={action}
          payload={undefined}
          reloadTable={() => {}}
        />
      </Modal>
      <Modal opened={action[0] === "package_add"} onClose={() => setAction([])} withCloseButton={false} centered>
        <PackageForm
          title="Сав, баглаа, боодол"
          subTitle={action[0] === "package_add" ? "Сав, баглаа, боодол нэмэх" : "Сав, баглаа, боодол засах"}
          onCancel={() => setAction([])}
          action={action}
          payload={undefined}
          reloadTable={() => {}}
        />
      </Modal>
    </div>
  );
});

type HeaderProps = {
  units: any;
  unitSpaceLabels: IReference[];
  unitWeightLabels: IReference[];
  unitConvertTypes: IReference[];
  packageTypes: IPackage[];
  onRemoveRow: (row: any, index: number, values: any, setFieldValue: (name: string, value: any) => void) => void;
  values: any;
  setFieldValue: (name: string, value: any) => void;
};

const useHeader = ({
  unitSpaceLabels,
  unitWeightLabels,
  units,
  unitConvertTypes,
  packageTypes,
  onRemoveRow,
  values,
  setFieldValue,
}: HeaderProps): ColumnType<Unit[]>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хэмжих нэгжийн нэр",
    render: (_r, index) => {
      return (
        <>
          <SelectField
            noError
            name={`additionalUnits[${index}].unitId`}
            label=""
            placeholder="Сонгох/Нэмэх"
            options={units?.filter((f: any) => f?.id !== values?.baseUnitId)?.map((item: any) => ({ label: item.name, value: item.id }))}
          />
        </>
      );
    },
  },
  {
    title: "Хөрвөх арга",
    render: (_r, index) => {
      return (
        <SelectField
          noError
          name={`additionalUnits[${index}].convertType`}
          label=""
          placeholder="Сонгох"
          options={
            unitConvertTypes!.map((item: Reference) => {
              return {
                label: item.name,
                value: item.code,
              };
            }) as { value: string; label: string }[]
          }
        />
      );
    },
  },
  {
    title: "Хөрвүүлэх тоо",
    render: (_r, index) => {
      return <NumberField noError name={`additionalUnits[${index}].convertValue`} label="" placeholder="Тоо" />;
    },
  },
  {
    title: "Бутархай орон",
    render: (_r, index) => {
      return <NumberField noError name={`additionalUnits[${index}].floatValue`} label="" placeholder="Тоо" />;
    },
  },
  {
    title: "",
    render: (_r, index) => {
      return (
        <ActionIcon variant="outline" onClick={() => onRemoveRow(_r, index, values?.additionalUnits, setFieldValue)}>
          <IconX />
        </ActionIcon>
      );
    },
    width: "30px",
  },
];

const initialUnit = {
  unitId: undefined,
  convertType: undefined,
  convertValue: undefined,
  isBuying: false,
  isSelling: false,
  floatValue: undefined,
  spaceLabel: undefined,
  height: undefined,
  width: undefined,
  length: undefined,
  weight: undefined,
  weightLabel: undefined,
};

ServiceOrderSettingsForm.displayName = "OrderSettings";

export { ServiceOrderSettingsForm };
