import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string; logo: string }) => {
  return httpRequest.post("/erp/brand", data);
};

export const select = async (data: { query: string; limit?: number }) => {
  return httpRequest.get("/erp/brand/select", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/brand", { offset, filter });
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/brand/${id}`);
};

export const update = async (id: string, data: { name: string; logo: string }) => {
  return httpRequest.put(`/erp/brand/${id}`, data);
};
