import { MultiSelect } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string[] | null) => void;
  options: { value: string; label: string }[];
  required?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  onCreate?: any;
};

export function MultiSelectField({
  name,
  label,
  placeholder,
  onChange: onChangeValue,
  options,
  disabled = false,
  required,
  clearable,
  searchable,
  onCreate,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <MultiSelect
      label={label}
      placeholder={placeholder}
      // Ensure value is an array, if not, provide an empty array
      value={Array.isArray(value) ? value : []}
      disabled={disabled}
      onChange={(values) => {
        onChange(values);

        if (onChangeValue) {
          onChangeValue(values);
        }
      }}
      rightSection={<IconChevronDown size={16} color="gray" />}
      error={error}
      nothingFoundMessage="Сонголт байхгүй байна"
      // Ensure options is always an array
      data={Array.isArray(options) ? options : []}
      style={{ flex: 1 }}
      required={required}
      clearable={clearable}
      searchable={searchable}
      onOptionSubmit={onCreate}
    />
  );
}
