import { Button, Checkbox, Divider, Group, LoadingOverlay, Modal } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { SectionApi } from "../../apis";
import { Dialog } from "../../utils/confirm-modal";
import { dateTimeFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { ColumnType, ITableRef, Table } from "../table";
import { SectionForm } from "./section-form";

type ISectionData = {
  id: string;
  name: string;
  isPublic: boolean;
  isDehub: boolean;
  createdAt: string;
};

export function SectionList() {
  const navigate = useNavigate();
  const [filters] = React.useState({ query: "" });
  const [action, setAction] = React.useState<string[]>([]);
  const [payload, setPayload] = React.useState<ISectionData | null>();
  const ref = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState(false);
  const ERP_REF_SCTN = usePermission("ERP_REF_SCTN");

  const columns = useHeader({
    actionAccess: ERP_REF_SCTN,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setPayload(item);
          setAction(["update"]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await SectionApi.remove(item.id);
                  ref.current?.reload();
                  message.success("Амжилттай устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
    setPayload(null);
  };

  const reloadTable = () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Барааны нэмэлт мэдээлэл"
        subTitle="Барааны нэмэлт мэдээллийн жагсаалт, шинээр үүсгэх, засварлах"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference")}>
            Буцах
          </Button>,
          <Button
            hidden={!ERP_REF_SCTN.isCreate}
            key={2}
            onClick={() => {
              setPayload(null);
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Divider my="lg" />
        {ERP_REF_SCTN.isView ? (
          <Table ref={ref} name="section.list" columns={columns} filters={filters} loadData={(data) => SectionApi.list(data!)} />
        ) : (
          ERP_REF_SCTN.accessDenied()
        )}
      </PageLayout>
      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} size="70rem" centered>
        <SectionForm onCancel={onCancel} action={action} payload={payload || undefined} reloadTable={reloadTable} />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: ISectionData) => void;
  actionAccess: {
    isDelete: boolean;
    isEdit: boolean;
    isView: boolean;
  };
};

const useHeader = ({ onClick, actionAccess }: HeaderProps): ColumnType<ISectionData>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record, index) => {
      return record.name || "-";
    },
  },
  {
    title: "Нээлттэй",
    width: 80,
    dataIndex: "isPublic",
    render: (record, index) => {
      return <Checkbox checked={record.isPublic} onChange={() => {}} />;
    },
  },
  {
    title: "DeHub",
    width: 80,
    dataIndex: "isDehub",
    render: (record, index) => {
      return <Checkbox checked={record.isDehub} onChange={() => {}} />;
    },
  },
  {
    title: "Бүртгэсэн",
    dataIndex: "createdAt",
    render: (record) => {
      return dateTimeFormat(record.createdAt);
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    width: 120,
    render: (record, index) => {
      return (
        <Group>
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} hidden={!actionAccess.isEdit}>
            <IconEdit />
          </Button>
          <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)} hidden={!actionAccess.isDelete}>
            <IconTrash />
          </Button>
        </Group>
      );
    },
  },
];
