import { ActionIcon, Center, Checkbox, CloseButton, NumberInput, Table, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar, IconCurrencyTugrik, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { currencyFormat } from "../../utils";

const start = Date.now();

const elements = [
  { title: "Үйлдэл" },
  { title: "Тоо ширхэг" },
  { title: "Минимум тоо" },
  { title: "Max лимиттэй" },
  { title: "Максимум тоо" },
  { title: "Валют" },
  { title: "Борлуулах нэгж үнэ (НӨАТ-гүй)" },
  { title: "Эхлэх хугацаа" },
  { title: "Дуусах хугацаа" },
  { title: "Одоогийн үнэ" },
  { title: "Өөрчлөлт" },
];

export function PriceQuantityList({ form, action }: { form: any; action: any[] }) {
  return (
    <Table horizontalSpacing="sm" verticalSpacing="sm">
      <Table.Thead>
        <Table.Tr>
          {elements.map((item: any, index: number) => {
            return (
              <Table.Th key={index}>
                <Text style={{ whiteSpace: "nowrap" }}>{item.title}</Text>
              </Table.Th>
            );
          })}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {form.values.quantityPrices.map((element: any, index: number) => {
          return (
            <Table.Tr key={index}>
              <Table.Td>
                <ActionIcon
                  onClick={() => {
                    form.setFieldValue(`quantityPrices`, [...form.values.quantityPrices.filter((item2: any, index2: number) => index2 !== index)]);
                  }}
                  variant="light"
                  color="red">
                  <IconTrash />
                </ActionIcon>
              </Table.Td>
              <Table.Td>1 ширхэг</Table.Td>
              <Table.Td>
                <NumberInput size="xs" min={0} placeholder="0" {...form.getInputProps(`quantityPrices.${index}.minQuantity`)} />
              </Table.Td>
              <Table.Td>
                <Checkbox
                  onClick={() => {
                    form.setFieldValue(`quantityPrices.${index}.maxQuantity`, undefined);
                  }}
                  label="Тийм"
                  {...form.getInputProps(`quantityPrices.${index}.hasMaxLimit`, { type: "checkbox" })}
                />
              </Table.Td>
              <Table.Td>
                <NumberInput
                  disabled={!form.values?.quantityPrices[index]?.hasMaxLimit}
                  size="xs"
                  min={0}
                  placeholder="0"
                  {...form.getInputProps(`quantityPrices.${index}.maxQuantity`)}
                />
              </Table.Td>
              <Table.Td>Төгрөг</Table.Td>
              <Table.Td>
                <NumberInput
                  size="xs"
                  min={0}
                  leftSection={<IconCurrencyTugrik size={18} stroke="1.5" color="gray" opacity={0.8} />}
                  placeholder="0.00"
                  rightSection={
                    <CloseButton
                      mr={"6px"}
                      onClick={() => form.setFieldValue(`quantityPrices.${index}.salesPrice`, undefined)}
                      variant="transparent"
                      size={"sm"}
                      color="gray.6"
                    />
                  }
                  {...form.getInputProps(`quantityPrices.${index}.salesPrice`)}
                />
              </Table.Td>
              <Table.Td>
                <DatePickerInput
                  leftSection={<IconCalendar size={18} />}
                  size="xs"
                  placeholder="Эхлэх хугацаа"
                  minDate={new Date(start)}
                  clearable
                  defaultValue={form.values?.quantityPrices[index]?.startDate as string}
                  {...form.getInputProps(`quantityPrices.${index}.startDate`)}
                  value={form.values?.quantityPrices[index]?.startDate ? new Date(dayjs(form.values?.quantityPrices[index]?.startDate).format()) : null}
                  onChange={(e) => {
                    form.setFieldValue(`quantityPrices.${index}.startDate`, e);
                    form.setFieldValue(`quantityPrices.${index}.endDate`, null);
                  }}
                  style={{ width: 200 }}
                  valueFormat="YYYY-MM-DD"
                />
              </Table.Td>
              <Table.Td>
                <DatePickerInput
                  leftSection={<IconCalendar size={18} />}
                  size="xs"
                  minDate={
                    form.values?.quantityPrices[index]?.startDate
                      ? new Date(dayjs(form.values?.quantityPrices[index]?.startDate).add(1, "day").format())
                      : new Date(start)
                  }
                  placeholder="Дуусах хугацаа"
                  disabled={!form.values.quantityPrices[index].startDate}
                  {...form.getInputProps(`quantityPrices.${index}.endDate`)}
                  value={form.values?.quantityPrices[index]?.endDate ? new Date(dayjs(form.values?.quantityPrices[index]?.endDate).format()) : null}
                  onChange={(e) => form.setFieldValue(`quantityPrices.${index}.endDate`, e)}
                  style={{ width: 200 }}
                  valueFormat="YYYY-MM-DD"
                />
              </Table.Td>
              <Table.Td>
                <Text ta="right" style={{ whiteSpace: "nowrap" }}>
                  {currencyFormat(form.values?.quantityPrices[index]?.standardPrice || 0)}
                </Text>
              </Table.Td>
              <Table.Td>
                <Text ta="right" style={{ whiteSpace: "nowrap" }}>
                  {currencyFormat((form.values?.quantityPrices[index]?.standardPrice || 0) - (form.values?.quantityPrices[index]?.salesPrice || 0))}
                </Text>
              </Table.Td>
            </Table.Tr>
          );
        })}
        <Table.Tr>
          <Table.Td colSpan={elements.length}>
            {form.values.quantityPrices.length === 0 && (
              <Center p={"lg"}>
                <Text fz={"sm"} c="gray.9">
                  Одоогоор юу ч алга
                </Text>
              </Center>
            )}
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
}
