import { IFilter } from "./../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const createSub = async (data: any) => {
  return httpRequest.post("/erp/category", data);
};

export const updateSub = async (id: string, data: any) => {
  return httpRequest.put(`/erp/category/${id}`, data);
};

export const select = async (data?: { type?: string; itemTypeId?: string; query: string }) => {
  return httpRequest.get("/erp/category/select", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/category", { offset, filter });
};

export const update = async (id: string, data: { name: string; description: string; type?: string; itemTypeId?: string; parentId?: string }) => {
  return httpRequest.put(`/erp/category/${id}`, data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/category/${id}`);
};
