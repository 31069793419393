import { Button, CloseButton, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { CategoryApi } from "../../apis";
import { updateCategories } from "../../store/general";
import { HttpHandler } from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  title: string;
  subTitle: string;
  action: string[]; // ['CLASSIFICATION', itemTypeId, parentId]
};

type IFormData = {
  name: string;
  description: string;
};

export function CategoryForm({ title, subTitle, onCancel, action }: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    name: "",
    description: "",
  });

  const onSubmit = async (data: IFormData) => {
    try {
      setLoading(true);

      await CategoryApi.create({
        name: data.name,
        description: data.description,
        type: action[0],
        itemTypeId: action[1],
        parentId: action[2],
      });

      setLoading(false);

      message.success("Таны хүсэлт амжилттай!");

      onCancel && onCancel();

      let res = await CategoryApi.select();

      dispatch(updateCategories(res));
    } catch (err) {
      setLoading(false);

      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />

            <Flex gap={15} direction="column">
              <TextField name="name" label="Нэр" placeholder="Нэр" />
              <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
            </Flex>

            <Group justify="right" mt={20}>
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
}
