import { ActionIcon, Badge, Box, Checkbox, Flex, Grid, Group, MultiSelect, NumberInput, Paper, Select, Table, Text, Tooltip } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { randomId } from "@mantine/hooks";
import { IconDeviceFloppy, IconEdit, IconSquareRoundedX, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { VariantApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/message";

interface IProps {
  dataUpdate: any;
  reload: () => void;
}

type IAdditional = {
  parentId: string | number | undefined;
  id: string | undefined;
  unitId: string | undefined;
  convertType: string | undefined | null;
  convertValue: string | undefined;
  floatValue: number | undefined;
  spaceLabel: string | undefined;
  height: number | undefined;
  width: number | undefined;
  length: number | undefined;
  weight: number | undefined;
  weightLabel: string | undefined;
  optionValues: any[];
  isEdit: boolean;
  variantId: string | null;
};

const schema = yup.object().shape({
  unitIds: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!"),
  additional: yup.array().of(
    yup.object().shape({
      isEdit: yup.boolean().optional(),
      isForLoad: yup.boolean().optional(),
      parentId: yup
        .string()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      id: yup
        .string()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      unitId: yup
        .string()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      convertType: yup
        .string()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      convertValue: yup
        .number()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      floatValue: yup
        .number()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      spaceLabel: yup
        .string()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      height: yup
        .number()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      width: yup
        .number()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      length: yup
        .number()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      weight: yup
        .number()
        .typeError("Заавал бөглөнө!")
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
      weightLabel: yup
        .string()
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable() : schema.nullable().notRequired();
        })
        .nullable()
        .optional(),
    }),
  ),
});

const VariantAddUnit: React.FC<IProps> = React.memo(({ dataUpdate, reload }) => {
  const { units, unitConvertTypes, unitSpaceLabels, unitWeightLabels } = useSelector((state: { general: IGeneral }) => state.general);

  const unitConvertTypesOption =
    unitConvertTypes?.map((item: any) => {
      return {
        label: item.name,
        value: item.code,
      };
    }) || [];
  const unitSpaceLabelsOption =
    unitSpaceLabels?.map((item: any) => {
      return {
        label: item.name,
        value: item.code,
      };
    }) || [];
  const unitWeightLabelsOption =
    unitWeightLabels?.map((item: any) => {
      return {
        label: item.name,
        value: item.code,
      };
    }) || [];

  const orderAdditionalUnitFormRef = useRef<any>();

  const form = useForm<{
    unitIds: string[];
    additional: IAdditional[];
  }>({
    validate: yupResolver(schema),
    initialValues: {
      unitIds: dataUpdate?.additionalUnits?.map((item: any) => item?.unitId) || [],
      additional: (dataUpdate && dataUpdate?.additionalUnits) || [],
    },
  });

  const onClickAction = async (key: any, element: any, index: number) => {
    const data = {
      parentId: element?.parentId,
      id: element.id.split(": ")[0] === "new" ? "" : element?.id,
      unitId: element?.unitId,
      convertType: element?.convertType,
      convertValue: element?.convertValue || 0,
      floatValue: element.floatValue,
      spaceLabel: element.spaceLabel,
      height: element.height,
      width: element.width,
      length: element.length,
      weight: element.weight,
      weightLabel: element?.weightLabel,
      isForLoad: element?.isForLoad || false,
    };

    switch (key) {
      case "save":
        let isValidate = form.validate();

        if (!isValidate.hasErrors && data) {
          try {
            message.success("Үйлдэл амжилттай.");
            form.setFieldValue(`additional.${index}.isEdit`, false);

            reload();
          } catch (error: any) {
            message.error(error?.message || "Үйлдэл амжилтгүй!");
          }
        } else message.error("Мөрийг гүйцэт бөглөнө үү!");

        break;
      case "edit":
        form.setFieldValue(
          `additional`,
          form.values.additional.map((item: any) => {
            return {
              ...item,
              isEdit: item.id === element.id,
            };
          }),
        );
        break;
      case "cancel":
        form.setFieldValue(`additional.${index}.isEdit`, false);
        break;
      case "remove":
        if (element.id.split(": ")[0] !== "new") {
          try {
            await VariantApi.remove(data.id);
            reload();
            message.success("Үйлдэл амжилттай.");
          } catch (error: any) {
            message.error(error?.message || "Үйлдэл амжилтгүй!");
          }

          // reload();
        }
        form.setFieldValue(`additional.${index}.isEdit`, false);
        break;
    }
  };

  useEffect(() => {
    const e = Array.from(new Map(form.values.unitIds.map((item) => [item, item]))).map(([item]) => item);

    const COMBINE = e?.map((item1: any) => {
      return (
        (dataUpdate &&
          dataUpdate?.variants?.map((item2: any) => {
            return {
              uniqId: [...item2.optionValues.map((item3: any) => item3.id), item1],
              isActive: false,
              id: "new: " + randomId(),
              unitId: item1,
              optionValues: item2?.optionValues || [],
              parentId: item2.id,
              variantId: item2.id,
              convertType: undefined,
              convertValue: undefined,
              floatValue: undefined,
              spaceLabel: undefined,
              height: undefined,
              width: undefined,
              length: undefined,
              weight: undefined,
              weightLabel: undefined,
              isForLoad: false,
              isEdit: false,
            };
          })) ||
        []
      );
    });

    const updateArrays =
      (dataUpdate &&
        dataUpdate?.additionalUnits.map((item: any) => {
          return {
            ...item,
            isActive: true,
            uniqId: [...item.optionValues.map((item2: any) => item2.id), item?.unitId],
          };
        })) ||
      [];

    let test = [].concat(...(COMBINE || [])).map((item: any) => {
      let find = updateArrays?.find((item2: any) =>
        item2.uniqId?.every((evr: any) => {
          return item?.uniqId?.some((som: any) => som === evr);
        }),
      );

      if (find && find?.isActive)
        return {
          ...find,
        };
      else return { ...item };
    });

    form.setFieldValue(`additional`, test);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdate.additionalUnits]);

  return (
    <Paper my={"lg"}>
      <form ref={orderAdditionalUnitFormRef} onSubmit={form.onSubmit((values) => console.log(values))}>
        <Grid>
          <Grid.Col>
            <Text fw={600} fz={"lg"}>
              Хэмжих нэгж болон хэмжээс
            </Text>
          </Grid.Col>
          {dataUpdate.hasAdditionalUnit && (
            <Grid.Col>
              <Group align="end">
                <MultiSelect
                  style={{ maxWidth: 800, minWidth: 300 }}
                  clearable
                  label="Нэмэлт хэмжих нэгжийн нэp"
                  required
                  data={[
                    ...(units
                      .map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      })
                      .filter((item: any) => item.value !== dataUpdate.baseUnitId) || []),
                  ]}
                  placeholder="Хувилбар сонгох"
                  error={form.errors.unitIds}
                  value={Array.from(new Map(form.values.unitIds.map((item) => [item, item]))).map(([id, item]) => item)}
                  onChange={(e: any) => {
                    const COMBINE = e?.map((item1: any) => {
                      return (
                        (dataUpdate &&
                          dataUpdate?.variants?.map((item2: any, index: number) => {
                            return {
                              uniqId: [...item2.optionValues.map((item3: any) => item3.id), item1],
                              isActive: false,
                              id: "new: " + randomId(),
                              unitId: item1,
                              optionValues: item2?.optionValues || [],
                              parentId: item2.id,
                              variantId: item2.id,
                              convertType: null,
                              convertValue: null,
                              floatValue: null,
                              spaceLabel: null,
                              height: null,
                              width: null,
                              length: null,
                              weight: null,
                              weightLabel: null,
                              isForLoad: false,
                              isEdit: false,
                            };
                          })) ||
                        []
                      );
                    });

                    const updateArrays =
                      (dataUpdate &&
                        dataUpdate?.additionalUnits.map((item: any) => {
                          return {
                            ...item,
                            isActive: true,
                            uniqId: [...item.optionValues.map((item2: any) => item2.id), item?.unitId],
                          };
                        })) ||
                      [];

                    let test = [].concat(...(COMBINE || [])).map((item: any, index: number) => {
                      let find = updateArrays?.find((item2: any) =>
                        item2.uniqId?.every((evr: any) => {
                          return item?.uniqId?.some((som: any) => som === evr);
                        }),
                      );

                      return {
                        ...item,
                        ...find,
                      };
                    });

                    form.setFieldValue(`unitIds`, e);
                    form.setFieldValue(`additional`, test);
                  }}
                />
              </Group>

              <Box py={"lg"} style={{ overflow: "auto" }}>
                <Table miw={2200}>
                  <thead>
                    <tr>
                      {th.map((item: { name: string }, index: number) => {
                        return <th key={index}>{item.name}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {form?.values?.additional?.map((element: IAdditional, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Flex align="center" gap="sm">
                              {form.values.additional[index].isEdit ? (
                                <>
                                  <Tooltip label="Хадгалах">
                                    <ActionIcon color="" onClick={() => onClickAction("save", element, index)} variant="light">
                                      <IconDeviceFloppy size="1.2rem" />
                                    </ActionIcon>
                                  </Tooltip>
                                  <Tooltip label="Болих">
                                    <ActionIcon onClick={() => onClickAction("cancel", element, index)} variant="light">
                                      <IconSquareRoundedX size="1.2rem" />
                                    </ActionIcon>
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip label="Засах">
                                    <ActionIcon color="blue" onClick={() => onClickAction("edit", element, index)} variant="light">
                                      <IconEdit size="1.2rem" />
                                    </ActionIcon>
                                  </Tooltip>
                                  {element?.id?.split(": ")[0] !== "new" && (
                                    <Tooltip label="Устгах">
                                      <ActionIcon
                                        color="red"
                                        onClick={() => onClickAction("remove", element, index)}
                                        disabled={form.values.additional.some((som: any) => som.isEdit)}
                                        variant="light">
                                        <IconTrash size="1.2rem" />
                                      </ActionIcon>
                                    </Tooltip>
                                  )}
                                </>
                              )}
                            </Flex>
                          </td>
                          <td>{element?.id?.split(": ")[0] === "new" ? <Badge color="gray">Идэвхгүй</Badge> : <Badge color="green">Идэвхтэй</Badge>}</td>
                          <td>
                            <Select
                              readOnly
                              clearable
                              data={units?.map((item) => {
                                return {
                                  label: item.name,
                                  value: item.id,
                                };
                              })}
                              placeholder="Нэмэлт хэмжих нэгж"
                              size="xs"
                              w={280}
                              {...form.getInputProps(`additional.${index}.unitId`)}
                            />
                          </td>
                          <td>
                            <Flex miw={"140px"} align="center" gap={8}>
                              {form.values.additional[index].optionValues?.map((item: any, index: number) => {
                                return <Badge key={index}>{item.name}</Badge>;
                              })}
                            </Flex>
                          </td>
                          <td>
                            <Select
                              clearable
                              data={unitConvertTypesOption}
                              placeholder="Хөрвөх арга"
                              size="xs"
                              w={230}
                              {...form.getInputProps(`additional.${index}.convertType`)}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                          <td>
                            <NumberInput
                              placeholder="Хөврүүлэх тоо"
                              size="xs"
                              w={230}
                              {...form.getInputProps(`additional.${index}.convertValue`)}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                          <td>
                            <NumberInput
                              {...form.getInputProps(`additional.${index}.floatValue`)}
                              placeholder="Бутархай орон"
                              size="xs"
                              w={230}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                          <td>
                            <Checkbox
                              label="Тйим"
                              // onChange={() => {}}
                              {...form.getInputProps(`additional.${index}.isForLoad`, { type: "checkbox" })}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                          <td>
                            <Select
                              clearable
                              {...form.getInputProps(`additional.${index}.spaceLabel`)}
                              data={unitSpaceLabelsOption || []}
                              placeholder="Эзлэхүүн нэгж"
                              size="xs"
                              w={230}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                          <td>
                            <NumberInput
                              {...form.getInputProps(`additional.${index}.height`)}
                              placeholder="Өндөр"
                              size="xs"
                              w={230}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                          <td>
                            <NumberInput
                              {...form.getInputProps(`additional.${index}.width`)}
                              placeholder="Өргөн"
                              size="xs"
                              w={230}
                              disabled={!form.values.additional[index]?.isEdit || false}
                            />
                          </td>
                          <td>
                            <NumberInput
                              {...form.getInputProps(`additional.${index}.length`)}
                              placeholder="Урт"
                              size="xs"
                              w={230}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                          <td>
                            <NumberInput
                              {...form.getInputProps(`additional.${index}.weight`)}
                              placeholder="Жин"
                              size="xs"
                              w={230}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                          <td>
                            <Select
                              clearable
                              data={unitWeightLabelsOption}
                              {...form.getInputProps(`additional.${index}.weightLabel`)}
                              placeholder="Жин нэгж"
                              size="xs"
                              w={230}
                              disabled={!form.values.additional[index].isEdit || false}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Box>
            </Grid.Col>
          )}
        </Grid>
      </form>
    </Paper>
  );
});

const th = [
  {
    name: "#",
  },
  {
    name: "Төлөв",
  },
  {
    name: "Нэмэлт хэмжих нэгж",
  },
  {
    name: "Барааны хувилбар",
  },
  {
    name: "Хөрвөх арга",
  },
  {
    name: "Хөрвүүлэх тоо",
  },
  {
    name: "Бутархай орон",
  },
  {
    name: "Ачилт",
  },
  {
    name: "Эзлэхүүн нэгж",
  },
  {
    name: "Өндөр",
  },
  {
    name: "Өргөн",
  },
  {
    name: "Урт",
  },
  {
    name: "Жин",
  },
  {
    name: "Жин нэгж",
  },
  {
    name: "#",
  },
];

export { VariantAddUnit };
