import { Badge, Button, Flex, Grid, Group, Text, Title } from "@mantine/core";
import { IconCheck, IconEdit, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import React, { useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IField } from "../../interfaces/IField";
import { IGeneral } from "../../interfaces/IGeneral";
import { IFieldType } from "../../models/General";
import { Dialog } from "../../utils/confirm-modal";
import { Form, IFormRef } from "../form";
import { MultiCreateableField } from "../form/multi-createable-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { ColumnType, Table } from "../table";

const schema = yup.object({
  fields: yup.array().of(
    yup.object({
      name: yup.string().required("Заавал бөглөнө!").nullable(),
      type: yup.string().required("Заавал бөглөнө!").nullable(),
      values: yup.array().when("type", (typeValue, schema) => {
        if (typeof typeValue === "string" && typeValue === "SELECT") {
          return schema.of(yup.string().required("Энэ талбарыг бөглөнө үү!")).min(1, "Заавал бөглөнө!");
        }
        return schema.notRequired();
      }),
      defaultValueId: yup.array().when("type", (typeValue, schema) => {
        if (typeof typeValue === "string" && typeValue === "SELECT") {
          return schema.of(yup.string().required("Энэ талбарыг бөглөнө үү!")).min(1, "Заавал бөглөнө!");
        }
        return schema.notRequired();
      }),
      numberUnit: yup.string().optional().nullable(),
      sort: yup.number().required("Заавал бөглөнө!").nullable(),
    }),
  ),
});

type Props = {
  onCancel?: () => void;
  init?: any;
  disabled?: boolean;
};

export type IDynamicRef = {
  submit: () => Promise<{
    fields: {
      name: string | undefined;
      type: IFieldType;
      numberUnit: string | undefined;
      defaultValueId: string | undefined;
      sort: number;
      values: string[];
      text?: string;
      number?: number;
      checked?: boolean;
    }[];
  }>;
};

export const DynamicFieldForm = React.forwardRef(({ onCancel, init = [], disabled = false }: Props, ref: React.Ref<IDynamicRef>) => {
  const formRef = React.useRef<IFormRef<{ fields: IField[] }>>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const { fieldTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [data, setFormData] = React.useState<{ fields: IField[] }>({
    fields: init,
  });

  const columns = useHeader({
    isHideAction: disabled,
    action: action,
    fieldTypes,
    fields: data.fields,
    onCreate: (option, index) => {
      const fields = (data?.fields || [])?.reduce((accumulator, iterator, i) => {
        if (i === index) {
          return [
            ...accumulator,
            {
              ...iterator,
              values: [
                ...(iterator.values || []),
                {
                  name: option.label,
                  id: option.value,
                  isDefault: false,
                },
              ],
            },
          ];
        }

        return [...accumulator, iterator];
      }, [] as IField[]);

      setFormData({
        fields: fields,
      });
    },
    onClick: async (key, record, index) => {
      switch (key) {
        case "cancel": {
          const errors = await formRef.current?.validate();

          if (errors && Object.keys(errors).length > 0) {
            formRef.current?.setFormData((state) => ({
              fields: state?.fields?.filter((_i, i) => i !== index),
            }));

            setAction([]);
          } else setAction([]);

          break;
        }
        case "edit": {
          setAction(["edit", `${index}`]);
          break;
        }
        case "save": {
          const errors = await formRef.current?.validate();

          if (errors && Object.keys(errors).length === 0) {
            setAction([]);
          }

          break;
        }
        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", (key) => {
            switch (key) {
              case "confirm": {
                formRef.current?.setFormData((state) => ({
                  fields: state?.fields?.filter((_i, i) => i !== index),
                }));
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  useImperativeHandle(ref, () => ({
    submit() {
      return formRef.current?.submit();
    },
  }));

  return (
    <Form ref={formRef} validationSchema={schema} initialValues={data}>
      {({ values }) => {
        return (
          <>
            <Flex direction="row" justify="space-between" align="center" mb="md">
              <Title size="md" c="dark.3">
                Динамик талбарууд
              </Title>
              {!disabled && (
                <Button
                  variant="light"
                  size="xs"
                  onClick={() => {
                    if (!action[0]) {
                      formRef.current?.setFormData((state) => {
                        setAction(["edit", `${state?.fields?.length || 0}`]);

                        const field: IField = {
                          name: undefined,
                          type: "TEXT",
                          numberUnit: undefined,
                          defaultValueId: undefined,
                          sort: state?.fields?.length + 1,
                          values: [],
                        };

                        setFormData((state) => ({
                          ...state,
                          fields: [...state.fields, field],
                        }));

                        return {
                          fields: [...(state?.fields || []), field],
                        };
                      });
                    }
                  }}
                  disabled={action[0] === "edit"}>
                  <IconPlus size={20} /> Шинэ талбар
                </Button>
              )}
            </Flex>
            <Grid>
              <Grid.Col span={12}>
                <Table name="sub.category.list.fields" columns={columns} dataSource={values.fields} pagination={false} />
              </Grid.Col>
            </Grid>
          </>
        );
      }}
    </Form>
  );
});

type HeaderProps = {
  onClick: (key: string, record: IField, index: number) => void;
  fieldTypes: IFieldType[];
  action: string[];
  fields: IField[];
  onCreate: (option: { label: string; value: string }, index: number) => void;
  isHideAction: boolean;
};

const useHeader = ({ action, fieldTypes, fields, onCreate, onClick, isHideAction }: HeaderProps): ColumnType<IField>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Динамик талбарын нэр",
    dataIndex: "name",
    width: 240,
    render: (record, index) => {
      if (action[1] === `${index}`) return <TextField name={`fields[${index}].name`} placeholder="Динамик талбарын нэр" noError />;

      return (
        <Text size="sm" w="max-content">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Өгөгдлийн төрөл",
    dataIndex: "type",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <SelectField
            name={`fields[${index}].type`}
            placeholder="Өгөгдлийн төрөл"
            noError
            options={fieldTypes.map((f) => ({
              label: f,
              value: f,
            }))}
          />
        );

      return (
        <Text size="sm" w="max-content">
          {record.type}
        </Text>
      );
    },
  },
  {
    title: "Сонголтод утга",
    dataIndex: "values",
    render: (record, index) => {
      if (record.type !== "SELECT") return "-";

      if (action[1] === `${index}`)
        return (
          <MultiCreateableField
            name={`fields[${index}].values`}
            onCreate={(o) => onCreate(o, index)}
            placeholder="Сонголтод утга"
            options={(fields[index]?.values || []).map((v) => ({ label: v.name, value: v.id }))}
            noError
          />
        );

      return (
        <>
          {record?.values?.map((value: any, index: number) => (
            <Badge key={index} variant="dot" mr="xs">
              {value}
            </Badge>
          ))}
        </>
      );
    },
  },
  {
    title: "[Default] утга",
    dataIndex: "defaultValue",
    width: 120,
    render: (record: any, index) => {
      if (record.type === "SELECT" && action[1] === `${index}`)
        return (
          <SelectField
            noError
            name={`fields[${index}].defaultValueId`}
            placeholder="[Default] утга"
            options={(record?.values || []).map((v: any) => ({ label: v, value: v }))}
          />
        );

      return (
        <Text size="sm" w="max-content">
          {record?.values?.find((v: any) => v === record.defaultValueId) || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгж",
    dataIndex: "status",
    width: 120,
    render: (record, index) => {
      if (record.type === "NUMBER" && action[1] === `${index}`) return <TextField name={`fields[${index}].numberUnit`} placeholder="Нэгж" />;

      return (
        <Text size="sm" w="max-content">
          {record.numberUnit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эрэмбэ",
    dataIndex: "sort",
    width: 120,
    render: (record, index) => {
      if (action[1] === `${index}`) return <NumberField name={`fields[${index}].sort`} placeholder="Эрэмбэ" noError />;

      return (
        <Text size="sm" w="max-content">
          {record.sort}
        </Text>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    isHide: isHideAction,
    render: (record, index) => {
      if (action[0] === "edit" && action[1] === `${index}`)
        return (
          <Group gap={10}>
            <Button variant="filled" radius={100} w={35} h={35} p={0} onClick={() => onClick("save", record, index)}>
              <IconCheck />
            </Button>

            <Button variant="default" radius={100} w={35} h={35} p={0} onClick={() => onClick("cancel", record, index)}>
              <IconX />
            </Button>
          </Group>
        );

      return (
        <Group gap={10}>
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record, index)}>
            <IconEdit />
          </Button>
          <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record, index)}>
            <IconTrash />
          </Button>
        </Group>
      );
    },
    width: "120px",
  },
];
