import { Button, Flex, LoadingOverlay } from "@mantine/core";
import { IconCheck, IconDeviceFloppy } from "@tabler/icons-react";
import React, { useRef } from "react";
import { SpecialPriceApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { PageLayout } from "../layout";
import { SpecialDynamicFieldForm } from "./special-dynamic-form";

export function SpecialDetail({ id }: { id: string }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});
  const [variantList, setVariantList] = React.useState<any>(null);
  const dynamicFormRef = useRef<any>(null);

  const onSubmit = async (type: boolean) => {
    setLoading(true);

    try {
      let valid = await dynamicFormRef.current?.submit();

      if (valid?.fields?.length > 0) {
        await SpecialPriceApi.selectVariant(id, { unitVariants: valid?.fields?.map((c: any) => ({ price: c?.price, unitVariantId: c?.id })), confirm: type });
        message.success("");
      } else {
        message.error("Жагсаалт хоосон байж болохгүй!");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error((error as HttpHandler)?.message! || "Алдаа гарлаа");
    }
  };

  React.useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await SpecialPriceApi.get(id);
          if (res) {
            const list = await SpecialPriceApi.variantList({
              filter: { changeType: res?.changeType, perType: res?.perType, perValue: res?.perValue },
              offset: {
                page: 1,
                limit: 10,
              },
            });
            setVariantList(list?.rows);
          }
          setData(res);

          setLoading(false);
        } catch (err) {
          message.error((err as HttpHandler)?.message! || "Алдаа гарлаа");
          setLoading(false);
        }
      })();
    }
  }, [id]);

  return (
    <PageLayout
      title={"Жагсаалтын үнийн тохиргоо хийх"}
      subTitle="Жагсаалтан дахь барааны үнийн тохиргоо хийх"
      extra={[
        <Flex key={1} gap={"md"}>
          <Button onClick={() => onSubmit(false)} leftSection={<IconDeviceFloppy size={20} />} variant="outline">
            Хадгалах
          </Button>
          <Button onClick={() => onSubmit(true)} leftSection={<IconCheck size={20} />}>
            Батлах
          </Button>
        </Flex>,
      ]}>
      <LoadingOverlay visible={loading} />
      {variantList ? <SpecialDynamicFieldForm ref={dynamicFormRef} initData={data} initValues={variantList} /> : null}
    </PageLayout>
  );
}
