import { LoadingOverlay, Paper } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { PriceGroupApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { SpecialPriceForm } from "../../components/special-price/main-form";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export const EditSpecialPrice = () => {
  const { theme } = useStyle();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const ERP_PG = usePermission("ERP_PG");

  const { data, isLoading } = useSwr<any[]>(
    ERP_PG.isView && `/erp/price_group/${params.id}`,
    async () => ERP_PG.isView && (await PriceGroupApi.get(`${params.id!}`)),
    {
      fallback: {},
    },
  );

  const onMainSubmit = async (data: any) => {
    setLoading(true);
    try {
      let transmition = {
        type: "SPECIAL",
        name: data.name,
        perType: data?.isAuto ? data.perType : null,
        perValue: data?.isAuto ? (data.perType === "AMOUNT" ? data?.perValueAmount : data?.perValuePercentage) : null,
        changeType: data?.isAuto ? data.changeType : null,
        description: data.description,
        startDate: data.startDate,
        endDate: data.endDate,
        variants:
          data?.variants?.map((item: any) => {
            return {
              variantId: item?.variantId,
              tier0Price: item?.tier0Price,
            };
          }) || [],
        confirm: data?.confirm || false,
      };
      await PriceGroupApi.updateSpecial(params?.id as string, transmition);

      message.success("Үйлдэл амжилттай.");
      navigate("/policy");
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <PageLayout
      title="Тусгай үнийн жагсаалт засах"
      subTitle={"Харилцагчид тусгайлан өгөх үнийн жагсаалт засах"}
      breadcrumb={breadcrumbs}
      bgColor={theme.colors.gray[0]}>
      {ERP_PG.isEdit ? (
        <Paper radius="sm" px="md" withBorder pos={"relative"} mih={"80vh"}>
          {isLoading ? <LoadingOverlay h={"100%"} visible={true} /> : <SpecialPriceForm loading={loading} onSubmit={onMainSubmit} editable={["edit", data]} />}
        </Paper>
      ) : (
        ERP_PG.accessDenied()
      )}
    </PageLayout>
  );
};

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/policy",
    label: "Үнийн бодлого",
  },
  {
    label: "Тусгай үнэ засах",
  },
];
