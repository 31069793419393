import { ActionIcon, Button, Center, Divider, Flex, Group, Input, LoadingOverlay, Paper, Select, Space, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconExclamationMark } from "@tabler/icons-react";
import React, { useState } from "react";
import useSwr from "swr";
import * as yup from "yup";
import { AuthApi, GoodsApi, InactiveTypeApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";

const schema = yup.object({
  pin: yup.number().typeError("Зөвхөн тоон утга оруулна").max(999999, "Хэт урт sбайна!").min(0, "Хэт богино байна!").required("Заавал бөглөнө!").nullable(),
  inactiveTypeId: yup
    .string()
    .when("isInactive", {
      is: true,
      then: (schema) => schema.typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
    })
    .optional()
    .nullable(),
});

type Props = {
  onCancel: () => void;
  reload: any;
  action: any;
};

type IFormData = {
  pin: string;
  inactiveTypeId?: string;
  isInactive: boolean;
};

type ITypePin = {
  success: boolean;
};

type ITypeInactiveType = {
  id: string | undefined;
  createdAt: string | undefined;
  updatedAt: string | undefined;
  deletedAt: string | undefined;
  businessId: string | undefined;
  text: string | undefined;
  isPublic: boolean;
  isDehub: boolean;
};

export function ProductActiveForm({ onCancel, reload, action }: Props) {
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    pin: "",
    inactiveTypeId: "",
    isInactive: action[0] && action[0] === "product_inactive",
  });
  const [query, setQuery] = useState<{ query: string; limit?: number }>({ query: "" });

  const [queryDelay] = useDebouncedValue(query, 800);

  const { data: inactiveTypes } = useSwr<any>(`/erp/inactive_type/select?${JSON.stringify(queryDelay)}`, async () => {
    const result = await InactiveTypeApi.select(queryDelay);
    return result;
  });

  const onChangeStatus = async (data: any) => {
    const now = Date.now();
    try {
      if (action[0] === "product_active") {
        await GoodsApi.statusChange(action[1] && action[1]?.variantId, {
          status: "ACTIVE",
        });
      } else {
        await GoodsApi.statusChange(action[1] && action[1]?.variantId, {
          status: "INACTIVE",
          inactiveTypeId: data.inactiveTypeId,
        });
      }

      if (action[0] === "product_active")
        message.success(`Та "${action[1]?.nameMon || "-"}" барааг идэвхтэй болголоо. ${dateTimeFormat(`${new Date(now)}`)} `, "Барааг амжилттай идэвхжүүллээ");
      else
        message.success(
          `Та "${action[1]?.nameMon || "-"}" барааг идэвхгүй болголоо. ${dateTimeFormat(`${new Date(now)}`)} `,
          "Барааг амжилттай идэвхгүй болголоо",
        );

      reload();
      onCancel && onCancel();
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй!");
    }
  };

  const onSubmit = async (data: IFormData) => {
    setLoading(true);

    try {
      let res: ITypePin = await AuthApi.checkPin({ pin: data.pin });

      if (res.success) {
        onChangeStatus({ inactiveTypeId: data?.inactiveTypeId });
      }
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй!");
    }

    setLoading(false);
  };

  if (!action[1]) {
    return <LoadingOverlay visible />;
  } else
    return (
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ errors, setFieldValue }) => {
          return (
            <Paper p={"md"}>
              <LoadingOverlay visible={loading} />
              <>
                <Center>
                  <ActionIcon variant="light" size={80} color="yellow" radius={80}>
                    <IconExclamationMark size={50} />
                  </ActionIcon>
                </Center>
                <Space h={"lg"} />
                <Text ta="center" fw={600} fz={"xl"}>
                  {action[0] === "product_inactive" ? "Барааг идэвхгүй болгох" : "Барааг идэвхжүүлэх"}
                </Text>
                <Space h={"lg"} />
                <Text ta="center">Та барааг бүртгэж захиалга авах боломжтой болгох гэж байна. Итгэлтэй бол ПИН кодоо оруулна уу.</Text>
                <Space h={"lg"} />
                {action[0] === "product_inactive" && (
                  <Input.Wrapper error={errors?.inactiveTypeId} label="Шалтгаан сонгох" required>
                    <Select
                      size="md"
                      error={!!errors?.inactiveTypeId}
                      placeholder="Сонгоно уу"
                      searchable
                      onChange={(e: string | null) => {
                        setFieldValue("inactiveTypeId", e);
                      }}
                      onSearchChange={(e: any) => {
                        setQuery({ query: e ?? "" });
                        return e;
                      }}
                      data={(inactiveTypes ?? []).map((item: ITypeInactiveType) => {
                        return {
                          value: item.id,
                          label: item?.text ?? "",
                        };
                      })}
                    />
                  </Input.Wrapper>
                )}
                <Space h={"sm"} />
                <Flex align={"center"} justify="center">
                  <OtpField size="md" name="pin" label="" required />
                </Flex>
                <Divider my={"lg"} />
                <Group justify="space-between">
                  <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                    Болих
                  </Button>
                  <Button loading={loading} key={2} type="submit" color={action[0] === "product_inactive" ? "red" : "teal"}>
                    {action[0] === "product_inactive" ? "Идэвхгүй болгох " : "Идэвхжүүлэх"}
                  </Button>
                </Group>
              </>
            </Paper>
          );
        }}
      </Form>
    );
}
