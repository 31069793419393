import { Select } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSwr from "swr";
import { useField } from ".";
import { IGeneral } from "../../interfaces/IGeneral";
import { updateManagers } from "../../store/general";

export function SelectFetchField({
  name,
  fetcher,
  onChange: onChangeValue,
  getObj,
  rest,
  readOnly,
  disabled,
}: {
  fetcher: any;
  name: string;
  rest?: any;
  getObj?: boolean;
  onChange?: (value: string | null) => void;
  readOnly?: boolean;
  disabled?: boolean;
}) {
  const { value, error, onChange } = useField(name);
  const dispatch = useDispatch();
  const { managers } = useSelector((state: { general: IGeneral }) => state.general);

  const [delay, setDelay] = useDebouncedState("", 1000);

  const {
    data = [],
    mutate,
    isLoading,
  } = useSwr<{ label: string; value: string; disabled?: boolean }[]>(`${JSON.stringify({ name, value: value || "" })}`, async () => await fetcher(), {
    fallbackData: [],
  });

  useEffect(() => {
    fetcher && fetcher({ query: delay });
  }, [delay, fetcher]);

  return (
    <Select
      readOnly={readOnly || isLoading}
      disabled={disabled || isLoading}
      nothingFound={"Хайлт олдсонгүй."}
      style={{ flex: 1 }}
      placeholder="Сонгох"
      size="xs"
      searchable
      clearable
      onSearchChange={async (e) => {
        setDelay(e);
      }}
      data={data}
      error={error}
      value={value}
      onChange={async (value) => {
        onChange(value);

        onChangeValue && onChangeValue(value);
        !isLoading && (await mutate());

        if (getObj) {
          const find: any = data?.find((i: any) => i?.value === value);
          if (find && !managers.some((som: any) => som.value === find.value)) {
            dispatch(updateManagers([...managers, find]));
          }
        }
      }}
      onClick={() => mutate()}
      {...rest}
    />
  );
}
