import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string }) => {
  return httpRequest.post("/erp/supplier", data);
};

export const select = async (data: { query: string; limit?: number }) => {
  return httpRequest.get("/erp/supplier/select", data);
};
