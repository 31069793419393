import { Paper } from "@mantine/core";
import { useParams } from "react-router";
import { PageLayout } from "../../components/layout";
import { PolicyDetail } from "../../components/policy/detail";
import { SpecialDetail } from "../../components/policy/special-detail";

export const DetailPolicy = () => {
  const { type, id } = useParams();
  const breadcrumbs = useBreadcrumb(type as string);

  return (
    <PageLayout
      title={type === "special" ? "Тусгай үнийн дэлгэрэнгүй" : "Бүтээгдэхүүний үнийн дэлгэрэнгүй"}
      subTitle="Үнийн дэлгэрэнгүй , засвар"
      breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        {type === "special" ? <SpecialDetail id={id as string} /> : <PolicyDetail id={id as string} />}
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = (type: string) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/policy",
    label: "Стандарт үнийн бодлого",
  },
  {
    label: type === "special" ? "Тусгай үнийн дэлгэрэнгүй" : "Бүтээгдэхүүний үнийн дэлгэрэнгүй",
  },
];
