import { Button, CloseButton, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { InactiveTypeApi } from "../../apis";
import { IBrand } from "../../interfaces/IBrand";
import { message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  text: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна.").nullable(),
});

type Props = {
  onCancel?: () => void;
  action: string[];
  payload?: IBrand;
  reloadTable?: any;
};

type IFormData = {
  text: string;
};

const InactiveTypeForm = React.memo(({ onCancel, action, payload, reloadTable }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    text: "",
    ...(payload ? payload : {}),
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      if (payload) {
        await InactiveTypeApi.update(payload?.id, { text: data.text });
      } else await InactiveTypeApi.create({ text: data.text });
      message.success("Үйлдэл амжилттай");
      reloadTable && reloadTable();
      onCancel && onCancel();
    } catch (error: any) {
      message.error(error.message || "Үйлдэл амжилтгүй.");
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout
            title="Бараа идэвхгүй болгох мэдээлэл"
            subTitle="Бараа идэвхгүй болгох мэдээлэл оруулна уу."
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Flex gap={15} direction="column">
              <TextField name="text" label="Тайлбар" placeholder="Тайлбар" />
            </Flex>

            <Group justify="right" mt={20}>
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
});

export default InactiveTypeForm;
