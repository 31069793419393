import { Avatar, Badge, Divider, Flex, Group, LoadingOverlay, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useSWR from "swr";
import { PriceGroupGoodsApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export const PriceChangesHistoryList = () => {
  const params = useParams();
  const { tiers, currencies, variantPriceHistoryStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const { data, isLoading } = useSWR(`/erp/price_group/goods/variant/${params.id}`, async () => {
    if (!!params.id) {
      try {
        let res = await PriceGroupGoodsApi.variantGet(`${params.id}`);
        return res;
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
        return err;
      }
    } else return null;
  });

  const breadcrumbs = useBreadcrumb();

  const columns = useHeader({
    variantPriceHistoryStatus,
    onClick: (key, record) => {
      switch (key) {
        default:
          break;
      }
    },
    tiers,
    currencies,
    Tag,
  });

  if (isLoading) return <LoadingOverlay visible />;
  else
    return (
      <PageLayout title={"Барааны үнийн өөрчлөлт"} subTitle="Стандарт үнийн өөрчлөлтийн түүх" breadcrumb={breadcrumbs}>
        <Flex gap={"sm"}>
          <Avatar src={data?.image} h={"200px"} w={"200px"} radius="xs">
            <IconPhoto />
          </Avatar>
          <Flex direction={"column"} gap={"sm"}>
            <Flex gap={"xs"}>
              <Text fw={600}>SKU: </Text>
              <Text>{data?.skuCode || "-"} </Text>
            </Flex>
            <Flex gap={"xs"}>
              <Text fw={600}>Barcode: </Text>
              <Text>{data?.barCode || "-"} </Text>
            </Flex>
            <Flex gap={"xs"}>
              <Text fw={600}>Dehub code: </Text>
              <Text>{data?.refCode || "-"} </Text>
            </Flex>
            <Flex gap={"xs"}>
              <Text fw={600}>Нэр: </Text>
              <Text>
                {data?.nameMon}{" "}
                {data?.optionValues?.map((item: string, index: number) => {
                  return <span key={index}>{item}, </span>;
                }) || "-"}{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Divider my={"lg"} />
        <Table
          name={`price.history.variants`}
          columns={columns}
          filters={{ variantId: params.id }}
          loadData={(data) => PriceGroupGoodsApi.variantHistory(data!)}
        />
        <Divider mt="md" mb="md" />
      </PageLayout>
    );
};

const useHeader = ({
  onClick,
  tiers,
  currencies,
  Tag,
  variantPriceHistoryStatus,
}: {
  onClick: (key: string, record: any) => void;
  tiers: any;
  currencies: IReference[];
  Tag: any;
  variantPriceHistoryStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_record, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Үнийн код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Үнийн өөрчлөлт нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Үнэ статус",
    sorter: true,
    dataIndex: "description",
    render: (record) =>
      Tag(
        variantPriceHistoryStatus.find((item: IReference) => item.code === record.historyStatus)?.color,
        variantPriceHistoryStatus.find((item: IReference) => item.code === record.historyStatus)?.name || record?.historyStatus || "-",
      ),
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Avatar src={record?.variant?.image} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Бараа, бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "nameMon",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.variant?.nameMon || "-"}
      </Text>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.variant?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unitName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitName || "-"}
      </Text>
    ),
  },
  {
    title: "Үндсэн нэгж эсэх",
    sorter: true,
    dataIndex: "isBaseUnit",
    render: (record) => (
      <Badge variant="outline" color={record.isBaseUnit ? "green.5" : "gray.5"}>
        {record.isBaseUnit ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Үндсэн нэгжээр тоо",
    sorter: true,
    dataIndex: "unitConvertValue",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitConvertValue || 0}
      </Text>
    ),
  },
  {
    title: "Үнэ тооцох тоо",
    sorter: true,
    dataIndex: "unitConvertValue",
    render: (record) => "-",
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => (
      <Text size="sm" w="max-content">
        {currencies.find((item: IReference) => item.code === record.currency)?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Эхэлсэн огноо",
    sorter: true,
    dataIndex: "startedDate",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.startedDate ? dateTimeFormat(record.startedDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Стандарт үнэ",
    sorter: true,
    dataIndex: "standardPrice",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.standardPrice ? currencyFormat(record?.standardPrice || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 1)?.name || "Tier 1",
    sorter: true,
    dataIndex: "tier1Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier1Price ? currencyFormat(record?.tier1Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 2)?.name || "Tier 2",
    sorter: true,
    dataIndex: "tier2Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier2Price ? currencyFormat(record?.tier2Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 3)?.name || "Tier 3",
    sorter: true,
    dataIndex: "tier3Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier3Price ? currencyFormat(record?.tier3Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 4)?.name || "Tier 4",
    sorter: true,
    dataIndex: "tier4Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier4Price ? currencyFormat(record?.tier4Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 5)?.name || "Tier 5",
    sorter: true,
    dataIndex: "tier5Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier5Price ? currencyFormat(record?.tier5Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 6)?.name || "Tier 6",
    sorter: true,
    dataIndex: "tier6Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier6Price ? currencyFormat(record?.tier6Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 7)?.name || "Tier 7",
    sorter: true,
    dataIndex: "tier7Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier7Price ? currencyFormat(record?.tier7Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 8)?.name || "Tier 8",
    sorter: true,
    dataIndex: "tier8Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier8Price ? currencyFormat(record?.tier8Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 9)?.name || "Tier 9",
    sorter: true,
    dataIndex: "tier9Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier9Price ? currencyFormat(record?.tier9Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 10)?.name || "Tier 10",
    sorter: true,
    dataIndex: "tier10Price",
    render: (record) => (
      <Group justify="right">
        <Text size="sm" w={"max-content"}>
          {record.tier10Price ? currencyFormat(record?.tier10Price || 0) : "-"}
        </Text>
      </Group>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Стандарт үнийн бодлого",
  },
  {
    label: "Үнийн өөрчлөлт",
  },
];
