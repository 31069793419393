import { AppShell, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import {
  IconChartPie,
  IconHeadset,
  IconPackage,
  IconReceipt,
  IconReceiptRefund,
  IconReceiptTax,
  IconRepeat,
  IconReport,
  IconTruck,
  IconUser,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { IAuth } from "../../interfaces/IAuth";
import { useScreenDetector } from "../screen-detector";
import { CommingSoon } from "./comming-soon";

const items = [
  {
    title: "Дашбоард",
    icon: IconChartPie,
    link: "/",
    disabled: false,
    menu: "INVENTORY_DASHBOARD",
    fltr: "BUYER",
  },
  {
    title: "Бараа удирдлага",
    icon: IconPackage,
    link: "/product",
    disabled: false,
    menu: "INVENTORY_GOODS",
    fltr: "BUYER",
  },
  {
    title: "Ажил үйлчилгээ",
    icon: IconHeadset,
    link: "/service",
    disabled: true,
    menu: "TEST",
    fltr: "BUYER",
  },
  {
    title: "Нийлүүлэгчийн бараа",
    icon: IconReceiptRefund,
    link: "/supp",
    disabled: false,
    menu: "INVENTORY_STORE",
    fltr: "SUPPLIER",
  },
  {
    title: "Шинэ бараа, үйлчилгээ",
    icon: IconRepeat,
    link: "/",
    disabled: true,
    menu: "TEST",
    fltr: "BUYER",
  },
  {
    title: "Үнийн бодлого",
    icon: IconReceiptTax,
    link: "/policy",
    disabled: false,
    menu: "INVENTORY_PRICE",
    fltr: "BUYER",
  },
  {
    title: "Үнэ - гэрээт харилцагч",
    icon: IconUser,
    link: "/customer/price",
    disabled: false,
    menu: "INVENTORY_NETWORK_PRICE",
    fltr: "BUYER",
  },
  {
    title: "Урамшуулал тохиргоо",
    icon: IconReceipt,
    link: "/",
    disabled: true,
    menu: "TEST",
    fltr: "BUYER",
  },
  {
    title: "Барааны нөөц",
    icon: IconTruck,
    link: "/resource",
    disabled: false,
    menu: "INVENTORY_STOCK",
    fltr: "BUYER",
  },
  {
    title: "Барааны Лавлах",
    icon: IconReport,
    link: "/reference",
    disabled: false,
    menu: "INVENTORY_REFERENCE",
    fltr: "BUYER",
  },
];

export function Navigation({ opened, setOpened }: { opened: boolean; setOpened: (e: boolean) => void }) {
  const { isMobile } = useScreenDetector();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const test = user?.permissions?.filter((fil: any) => fil?.module === "INVENTORY");

  return (
    <AppShell.Navbar style={{ paddingTop: "2rem" }}>
      {items
        ?.filter((item: any) => user?.currentBusiness?.type !== item.fltr)
        ?.map((item, index) => (
          <NavItem
            item={{ ...item, disabled: !test?.some((som: any) => som?.menu === item?.menu) }}
            key={index}
            setOpened={setOpened}
            opened={isMobile && opened}
          />
        )) || []}
    </AppShell.Navbar>
  );
}

type INavItem = {
  title: string;
  icon: typeof IconReceipt;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, setOpened, opened = false }: { item: INavItem; setOpened: (e: boolean) => void; opened: boolean }) {
  const { classes, cx } = useStyles({ color: "violet" });

  return (
    <CommingSoon position="right" visible={item.disabled}>
      <NavLink
        className={cx(classes.item, item.disabled && "disabled", opened && classes.row)}
        to={!item.disabled ? item.link : "#"}
        onClick={() => {
          setOpened(false);
        }}>
        <item.icon className={classes.icon} size={40} />
        <Text size="xs" mt={opened ? 0 : 7} lh="16px" fw="400">
          {item.title}
        </Text>
      </NavLink>
    </CommingSoon>
  );
}

const useStyles = createStyles((theme, { color }: { color: string }) => {
  const icon = "icon";

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "20px",
      [`&.disabled .${icon}`]: {
        backgroundColor: `${theme.colors[color][0]}!important`,
        color: `${theme.colors[color][6]}!important`,
      },
      [`&:hover .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
      [`&.active .${icon}`]: {
        backgroundColor: theme.colors[color][6],
        color: theme.white,
      },
    },
    icon: {
      ref: icon,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors[color][6],
      backgroundColor: theme.colors[color][0],
      borderRadius: 4,
      padding: 8,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      gap: 15,
      justifyContent: "start",
      borderRadius: theme.radius.sm,

      [`&:hover`]: {
        background: theme.colors[color][0],
        transform: "revert",
        animation: "ease-in",
      },
    },
  };
});
