import { useState } from "react";
import useSwr from "swr";
import { PriceGroupApi } from "../../apis";
import { SelectField } from "../../components/form/select-field";
import { useDebouncedValue } from "@mantine/hooks";

export const SelectFieldQuery = ({ values }: any) => {
  const [query, setQuery] = useState<string>("");
  const [debounced] = useDebouncedValue(query, 500, { leading: true });

  const { data: selectList } = useSwr<any>(
    `/erp/price_group/select?${JSON.stringify({
      type: values?.priceGroupType,
      query: debounced,
    })}`,
    values?.priceGroupType ? async () => await PriceGroupApi.select({ type: values?.priceGroupType, query: debounced }) : null,
    { fallback: [] },
  );

  return (
    <SelectField
      clearable
      disabled={!values?.priceGroupType}
      searchable
      searchValue={query}
      onSearchChange={(e: any) => setQuery(e)}
      name="priceGroupId"
      label="Үнийн бүлэг"
      placeholder="Үнийн бүлэг сонгох"
      options={selectList?.map((item: any) => {
        return {
          label: item?.name,
          value: item.id,
        };
      })}
      required
    />
  );
};
