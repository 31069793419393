import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter | any) => {
  return httpRequest.get("/erp/price_group/goods", { offset, filter });
};

export const variantGet = async (id: string) => {
  return httpRequest.get(`/erp/price_group/goods/variant/${id}`);
};

export const variantHistory = async ({ offset, filter }: IFilter | any) => {
  return httpRequest.get("/erp/price_group/goods/variant/history", { offset, filter });
};
