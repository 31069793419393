import { MantineTheme, MantineThemeOverride } from "@mantine/core";
import { Global } from "@mantine/emotion";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      })}
    />
  );
};

export const themeConfig: MantineThemeOverride = {
  colors: {
    primary: ["#f6eeff", "#e7daf7", "#cab1ea", "#ad86dd", "#9562d2", "#854bcb", "#7d3ec9", "#6b31b2", "#5f2aa0", "#52228d"],
  },
  primaryColor: "primary",
  components: {
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.gray[7],
          fontSize: 15,
        },
      }),
    },
    Table: {
      styles: () => ({
        highlightOnHover: {
          "&:hover": {
            backgroundColor: "red",
          },
        },
      }),
    },
  },
};
