import { IFilter } from "../interfaces/IFilter";
import { IGroupPrice } from "../interfaces/IGroupPrice";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/price_group", { offset, filter });
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/price_group/${id}`);
};

export const variantSelect = async (data: any) => {
  return httpRequest.get("/erp/price_group/variant/select", data);
};

export const variantList = async (data: any) => {
  return httpRequest.get("/erp/price_group/variant", data);
};

export const create = async (data: IGroupPrice | any) => {
  return httpRequest.post("/erp/price_group", data);
};

export const update = async (id: string, data: IGroupPrice | any) => {
  return httpRequest.post(`/erp/price_group/${id}`, data);
};

export const selectVariant = async (id: string, data: any) => {
  return httpRequest.put(`/erp/price_group/${id}/select_variant`, data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/price_group/${id}`);
};
