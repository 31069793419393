import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const supplier = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/store/supplier", { offset, filter });
};

export const supp_get = async (id: string) => {
  return httpRequest.get(`/erp/store/supplier/${id}`);
};

export const goods = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/store/goods", { offset, filter });
};

export const fetch = async (id: string, data: { businessId: string; description: string }) => {
  return httpRequest.post(`/erp/store/goods/${id}/fetch`, data);
};
