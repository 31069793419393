import { ActionIcon, Box, Button, Divider, Flex, Group, Text, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconPlus, IconX } from "@tabler/icons-react";
import React from "react";
import { FormLayout } from "../layout";

export function VariantSelect({
  onClose,
  data,
  selected,
  addItemToList,
  selectVariants,
  setFilter,
  filter,
}: {
  onClose: () => void;
  data: any;
  selected: any;
  addItemToList: any;
  selectVariants: any;
  setFilter: any;
  filter: any;
}) {
  const [list, setList] = React.useState<any>([]);
  const [query, setQuery] = useDebouncedState("", 500);

  const addToList = (code: string) => {
    setList([...list, code]);
  };

  const removeFromList = (code: string) => {
    setList([...list?.filter((c: string) => c !== code)]);
  };

  // console.log("list", list);

  const onSave = () => {
    addItemToList(list);
    onClose();
  };

  React.useEffect(() => {
    selectVariants(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, filter]);

  return (
    <FormLayout
      title="Үнийн жагсаалт нэмэх"
      subTitle=""
      extra={[
        <ActionIcon onClick={() => onClose()} key={1}>
          <IconX />
        </ActionIcon>,
      ]}>
      <Flex direction="column" gap="sm">
        <TextInput
          onChange={(e) => {
            setQuery(e?.currentTarget?.value);
          }}
          w="40%"
          placeholder="Хайх"
        />
        <Flex pb={5} sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[4]}` })} justify="space-between">
          <Text maw={300} fz={15} w="100%" fw={500}>
            Нэр
          </Text>
          <Text maw={160} fz={15} w="100%" fw={500}>
            Код
          </Text>
          <Text maw={200} fz={15} w="100%" fw={500}>
            Стандарт үнэ
          </Text>
          <Text maw={100} fz={15} w="100%" fw={500}>
            Сонгох
          </Text>
        </Flex>
        <Box sx={{ maxHeight: "360px", overflow: "hidden", overflowY: "auto" }}>
          {data?.filter((c: any) => !selected?.includes(c?.id)).length > 0 ? (
            data
              ?.filter((c: any) => !selected?.includes(c?.id))
              ?.map((c: any, index: number) => (
                <Box px={0} py={3} key={index}>
                  <Flex align="center" justify="space-between">
                    <Text maw={300} fz={14} lineClamp={1} w="100%">
                      {c.nameMon}
                    </Text>
                    <Text maw={160} fz={14} lineClamp={1} w="100%">
                      {c.refCode}
                    </Text>
                    <Text maw={200} fz={14} lineClamp={1} w="100%">
                      {c.price}
                    </Text>
                    <Button
                      onClick={() => {
                        list?.find((code: any) => code === c?.id) ? removeFromList(c?.id as string) : addToList(c?.id as string);
                      }}
                      w={100}
                      variant={list?.find((code: any) => code === c.id) ? "filled" : "outline"}
                      size="xs"
                      leftSection={list?.find((code: any) => code === c.id) ? <IconX size={16} /> : <IconPlus size={16} />}>
                      {list?.find((code: any) => code === c.id) ? "Хасах" : "Нэмэх"}
                    </Button>
                  </Flex>
                </Box>
              ))
          ) : (
            <Box>
              <Text ta="center" c="gray" fw={500} fz={16}>
                Жагсаалт хоосон байна
              </Text>
            </Box>
          )}
        </Box>

        {data?.filter((c: any) => !selected?.includes(c?.id)).length > 10 ? (
          <Flex justify="center">
            <Button
              size="xs"
              mt={5}
              variant="outline"
              onClick={() => {
                setFilter();
              }}>
              Цааш үзэх
            </Button>
          </Flex>
        ) : null}
        <Divider mt={5} />
        <Group align="right">
          <Button onClick={() => (data?.filter((c: any) => !selected?.includes(c?.id)).length > 0 ? onSave() : onClose())}>Хадгалах</Button>
        </Group>
      </Flex>
    </FormLayout>
  );
}
