import { Button, Group, Paper } from "@mantine/core";
import { IconCheckupList, IconDeviceFloppy, IconX } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { StockAdjustmentApi } from "../../apis";
import { IFormRef } from "../../components/form";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { RecourceForm } from "../../components/resource/form";
import { message } from "../../utils/message";

export const ResourceNew = () => {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const formRef = useRef<IFormRef<any>>(null);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const ERP_STOCK_ADJ = usePermission("ERP_STOCK_ADJ");

  const onSubmit = async (values: any) => {
    setIsloading(true);
    try {
      await StockAdjustmentApi.adjust({
        warehouseId: values.warehouseId,
        description: values.description,
        reference: values.reference,
        variants: values?.variants?.map((i: any) => {
          return {
            variantId: i.variantId,
            movementType: i.movementType,
            quantity: i.quantity,
            adjustmentNoteId: i.adjustmentNoteId,
          };
        }),
        confirm: values.confirm,
      });

      message.success("Үйлдэл амжилттай.");
      navigate("/resource", { state: { key: "3" } });
    } catch (error: any) {
      message.error(error?.message || "Үйлдэл амжилтгүй.");
    }
    setIsloading(false);
  };

  return (
    <PageLayout
      title="Үлдэгдлийг шинээр тохируулах"
      subTitle="Барааны нөөцийн үлдэгдэлд тохируулга хийх"
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={0} hidden={!ERP_STOCK_ADJ.isCreate}>
          <Button onClick={() => navigate("/resource", { state: { key: "3" } })} leftSection={<IconX size={"1.2rem"} />} variant="outline">
            Болих
          </Button>
          <Button
            hidden={!ERP_STOCK_ADJ.isCreate}
            onClick={async () => {
              let validData = await formRef.current?.submit();
              if (validData) {
                onSubmit({ ...validData, confirm: false });
              } else {
                let errors = await formRef.current?.validate();
                console.log(errors);
              }
            }}
            leftSection={<IconDeviceFloppy size={"1.2rem"} />}
            variant="light"
            loading={isLoading}>
            Хадгалах
          </Button>
          <Button
            hidden={!ERP_STOCK_ADJ.isCreate}
            onClick={async () => {
              let validData = await formRef.current?.submit();
              if (validData) {
                onSubmit({ ...validData, confirm: true });
              } else {
                let errors = await formRef.current?.validate();
                console.log(errors);
              }
            }}
            leftSection={<IconCheckupList size={"1.2rem"} />}
            loading={isLoading}>
            Батлах
          </Button>
        </Group>,
      ]}>
      <Paper>{ERP_STOCK_ADJ.isCreate ? <RecourceForm formRef={formRef} /> : ERP_STOCK_ADJ.accessDenied()}</Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/resource",
    label: "Барааны нөөц",
  },
  {
    label: "Барааны тохируулга",
  },
];
