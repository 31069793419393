import { Button, Divider, Group, LoadingOverlay, Modal, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { CategoryApi } from "../../apis";
import { ICategory } from "../../interfaces/ICategory";
import { IClassification } from "../../interfaces/IClassification";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { ColumnType, ITableRef, Table } from "../table";
import { ReferenceCategoryForm } from "./reference-classification-form";

export function ClassificationList() {
  const [filters] = React.useState({ query: "", type: "CLASSIFICATION" });
  const [action, setAction] = React.useState<string[]>([]);
  const [payload, setPayload] = React.useState<IClassification | null>();
  const ref = React.useRef<ITableRef>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const ERP_REF_ITM_CLS = usePermission("ERP_REF_ITM_CLS");

  const columns = useHeader({
    actionAccess: ERP_REF_ITM_CLS,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setPayload(item);
          setAction(["edit", "CLASSIFICATION", item.itemType.id, item.id]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  let res = await CategoryApi.remove(item.id);
                  if (res.success) {
                    setTimeout(() => {
                      ref.current?.reload();
                    }, 200);
                  }
                  message.success("Амжилттай ангиллыг устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
    setPayload(null);
  };

  const reloadTable = () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Ангилал"
        subTitle="Бараа бүтээгдэхүүн, ажил үйлчилгээний ангиллын жагсаалт"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference")}>
            Буцах
          </Button>,
          <Button
            hidden={!ERP_REF_ITM_CLS.isCreate}
            key={2}
            onClick={() => {
              setPayload(null);
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Divider my="lg" />
        <Table ref={ref} name="classificaiton.list" columns={columns} filters={filters} loadData={(data) => CategoryApi.list(data!)} />
      </PageLayout>

      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} centered>
        <ReferenceCategoryForm
          title="Ангилал"
          subTitle={!payload ? "Бараа бүтээгдэхүүн, ажил үйлчилгээний ангилал нэмэх" : "Бараа бүтээгдэхүүн, ажил үйлчилгээний ангилал засах"}
          onCancel={onCancel}
          action={action}
          payload={payload || undefined}
          reloadTable={reloadTable}
        />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: ICategory) => void;
  actionAccess: {
    isDelete: boolean;
    isEdit: boolean;
    isView: boolean;
  };
};

const useHeader = ({ onClick, actionAccess }: HeaderProps): ColumnType<ICategory>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Нэр төрөл",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.itemType?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Код",
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Group gap={10}>
          <Tooltip label="Засах">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} hidden={!actionAccess.isEdit}>
              <IconEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Устгах">
            <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)} hidden={!actionAccess.isDelete}>
              <IconTrash />
            </Button>
          </Tooltip>
        </Group>
      );
    },
  },
];
