import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const add = async (data: {
  goodsId: string | null;
  id?: string | null;
  image: string | null;
  optionValues: {
    optionId: string | null;
    name: string | null;
  }[];
  skuCode: string | null;
  barCode: string | null;
  erpCode: string | null;
  nameApp: string | null;
  nameWeb: string | null;
  nameBill: string | null;
}) => {
  return httpRequest.post("/erp/goods/variant", data);
};

export const additional = async (data: any) => {
  return httpRequest.post("/erp/goods/variant/additional", data);
};

export const remove = async (id: any) => {
  return httpRequest.del(`/erp/goods/variant/${id}`);
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/goods/variant/${id}`);
};
