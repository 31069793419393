import { ActionIcon, Alert, Avatar, Box, Collapse, Text } from "@mantine/core";
import { IconPhoto, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { AdjustmentNoteApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";

export const ResourceProductList = ({ setFieldValue, values, errors }: { setFieldValue: (e: string, e2: any) => void; values: any; errors: any }) => {
  const { stockMovementTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const { data: adjustmentNotes = [] } = useSwr<any[]>(
    `/erp/adjustment_note/select?query=${JSON.stringify("")}`,
    async () =>
      await AdjustmentNoteApi.select({
        query: "",
        limit: 1000,
      }),
    {
      fallback: [],
    },
  );

  const columns = useHeader({
    values,
    stockMovementTypes,
    adjustmentNotes,
    onClick: (key: string, item: any) => {
      if (key === "remove") {
        setFieldValue(
          "variants",
          values.variants.filter((f: any) => f.id !== item.id),
        );
      }
    },
  });

  return (
    <div>
      <Table minWidth={"max-conent"} name="variants" columns={columns} filters={{}} dataSource={values?.variants || []} />
      <Collapse in={errors?.variants === "emty"} mt={"xs"}>
        <Alert color="red">Үлдэгдэл тохируулах бараа нэмнэ үү.</Alert>
      </Collapse>
    </div>
  );
};

const useHeader = ({
  values,
  onClick,
  adjustmentNotes,
  stockMovementTypes,
}: {
  values: any;
  onClick: (e1: string, e2: any) => void;
  adjustmentNotes: any;
  stockMovementTypes: IReference[];
}): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <ActionIcon variant="light" color={"red"} onClick={() => onClick("remove", record)}>
          <IconTrash />
        </ActionIcon>
      );
    },
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "item.image",
    render: (record) => (
      <Avatar src={record.image ?? ""} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Код#",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.refCode || "-"}
      </Text>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Бар код",
    sorter: true,
    dataIndex: "category",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.barCode || "-"}
      </Text>
    ),
  },
  {
    title: "Барааны нэр",
    sorter: true,
    dataIndex: "category",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.nameMon || "-"}
      </Text>
    ),
  },
  {
    title: "Үндсэн нэгж",
    sorter: true,
    dataIndex: "price",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitName || "-"}
      </Text>
    ),
  },
  {
    title: "Нийт үлдэгдэл",
    sorter: true,
    dataIndex: "availableQuantity",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.availableQuantity}
      </Text>
    ),
  },
  {
    title: "Өөрлчлөлтийн төрөл",
    sorter: true,
    dataIndex: "price",
    render: (_record, index) => {
      return (
        <SelectField
          clearable
          searchable
          options={
            stockMovementTypes.map((i: IReference) => {
              return {
                label: i?.name || "",
                value: i?.code || "",
              };
            }) || []
          }
          w="220px"
          name={`variants[${index}].movementType`}
          placeholder="Сонгох"
          size="xs"
        />
      );
    },
  },
  {
    title: "Тохируулах дүн",
    sorter: true,
    dataIndex: "price",
    render: (_record, index) => (
      <Box w={"220px"}>
        <NumberField name={`variants[${index}].quantity`} placeholder="Тоо оруулах" size="xs" />
      </Box>
    ),
  },
  {
    title: "Шинэ үлдэгдэл",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => {
      if (record.movementType === "INCREASE")
        return (
          <Text size="sm" w="max-content">
            {(record.availableQuantity || 0) + (record?.quantity || 0)}
          </Text>
        );
      else if (record.movementType === "DECREASE")
        return (
          <Text size="sm" w="max-content">
            {(record.availableQuantity || 0) - (record?.quantity || 0)}
          </Text>
        );
      else return <Text size="sm">-</Text>;
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "adjustmentNoteId",
    render: (_record, index) => (
      <SelectField
        clearable
        searchable
        options={adjustmentNotes.map((i: any) => {
          return {
            label: i.name,
            value: i.id,
          };
        })}
        w="220px"
        name={`variants[${index}].adjustmentNoteId`}
        placeholder="Сонгох"
        size="xs"
      />
    ),
  },
];
