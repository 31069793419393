import { ComboboxItem, Group, MantineColor, Select, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";

export const SelectFilter = ({
  label,
  value,
  onChange,
  data = [],
  disabled = false,
  iconWidth,
  placeholder,
  onSearchChange,
}: {
  label: string;
  value: string;
  onChange: (value: string | null, option?: ComboboxItem) => void;
  data: {
    code: string;
    color?: MantineColor;
    name: string;
  }[];
  disabled?: boolean;
  iconWidth?: number;
  placeholder?: string;
  onSearchChange?: (e: string) => void;
}) => {
  return (
    <Select
      onSearchChange={onSearchChange}
      disabled={disabled}
      clearable
      value={value}
      onChange={onChange}
      leftSectionWidth={iconWidth || 160}
      searchable
      placeholder={placeholder || "Сонгоно уу"}
      style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
      data={data.map((item: any) => {
        return {
          value: item.code || "",
          disabled: item?.disabled || false,
          label: item?.name || "",
        };
      })}
      leftSection={
        <Group w={"calc(100% - 20px)"} align="center">
          <Text fz={"sm"} c="gray.7">
            {label}
          </Text>
          <Text>
            <IconChevronRight size={13} />
          </Text>
        </Group>
      }
    />
  );
};
