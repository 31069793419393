import { Paper } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import useSwr from "swr";
import { ServiceApi } from "../../apis";
import ServiceOrderVariantsForm from "./service-order-variants-form";

function ServiceOrderSettingsTab({
  onTabChane,
  orderUnitFormRef,
  orderDeliveryFormRef,
  orderAdditionalUnitFormRef,
  setHasAdditionalUnit,
  hasAdditionalUnit,
}: {
  onTabChane: (e: any) => void;
  orderUnitFormRef: any;
  orderDeliveryFormRef: any;
  orderAdditionalUnitFormRef: any;
  setHasAdditionalUnit: (e: boolean) => void;
  hasAdditionalUnit: boolean;
}) {
  const { id: serviceId } = useParams();

  const { data } = useSwr<any>(`/api/goods/${serviceId}`, async () => await ServiceApi.get(`${serviceId}`), {});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [action, setAction] = useState<any[]>([]);

  const reloader = useCallback(async () => {
    setIsLoading(true);
    try {
      let data = await ServiceApi.get(`${serviceId}`);
      setAction([
        "update",
        {
          ...data,
          hasVariant: data?.hasVariant,
          departmentUnitId: data?.departmentUnitId,
          departmentSubUnitId: data?.departmentSubUnitId,
          deliveryTypeId: data?.deliveryTypeId,
          returnAllow: data?.returnAllow,
          returnType: data?.returnType,
          packageTypeId: data?.packageTypeId,
          isCompleted: data?.isCompleted,

          serviceId: serviceId,
          baseUnitId: data?.itemUnits.find((f: any) => f.isBase)?.unitId,
          weight: data?.itemUnits.find((f: any) => f.isBase)?.weight,
          weightLabel: data?.itemUnits.find((f: any) => f.isBase)?.weightLabel,
          length: data?.itemUnits.find((f: any) => f.isBase)?.length,
          height: data?.itemUnits.find((f: any) => f.isBase)?.height,
          width: data?.itemUnits.find((f: any) => f.isBase)?.width,
          spaceLabel: data?.itemUnits.find((f: any) => f.isBase)?.spaceLabel,

          minBalance: data?.minBalance,
          maxBalance: data?.maxBalance,
          minOrderNum: data?.minOrderNum,
          reOrder: data?.reOrder,
          reOrderNum: data?.reOrderNum,
          reOrderMinNum: data?.reOrderMinNum,
          hasAdditionalUnit: data?.hasAdditionalUnit,
          additionalUnits: data?.additionalUnits,
          variants: data?.variants,
        },
      ]);
    } catch (error) {
      // handle error
    } finally {
      setIsLoading(false);
    }
  }, [serviceId]);

  useEffect(() => {
    if (data) reloader();
  }, [data, reloader]);

  return (
    <Paper>
      <ServiceOrderVariantsForm
        editable={action}
        reload={async () => {
          await reloader();
        }}
        isLoading={isLoading}
      />
    </Paper>
  );
}

export default ServiceOrderSettingsTab;
