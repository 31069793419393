import { LoadingOverlay, Paper, Tabs } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { GoodsApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import ProductOrderSettingsTab from "../../components/product-order-settings-tab";
import { AdditionalInfomationForm } from "../../components/product/additional-information-form";
import { MainForm } from "../../components/product/main-form";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

const EditProduct = React.memo(() => {
  const params = useParams();
  const { id, index } = params;
  const { theme } = useStyle();
  const breadcrumbs = useBreadcrumb();
  const [tabIndex, setTabIndex] = React.useState(index || "1");
  const navigate = useNavigate();
  const { data, mutate, isLoading } = useSwr<any>(`/api/goods/${id}`, async () => await GoodsApi.get(`${id}`), {});
  const ERP_GDS = usePermission("ERP_GDS");
  const location = useLocation();

  const onMainSubmit = async (data: any, setLoading: React.Dispatch<React.SetStateAction<boolean>>, isNext: boolean) => {
    setLoading(true);

    try {
      if (ERP_GDS.isEdit) {
        await GoodsApi.update(data.id, {
          skuCode: data.skuCode,
          barCode: data.barCode,
          erpCode: data.erpCode,
          nameMon: data.nameMon,
          nameEng: data.nameEng,
          nameBill: data.nameBill,
          nameWeb: data.nameWeb,
          nameApp: data.nameApp,
          brandId: data.brandId,
          supplierId: data.supplierId,
          manufacturerId: data.manufacturerId,
          originCountry: data.originCountry,
          importerCountry: data.importerCountry,
          distributorId: data.distributorId,
          itemTypeId: data.itemTypeId,
          classificationId: data.classificationId,
          subClassificationId: data.subClassificationId,
          categoryId: data.categoryId,
          subCategoryId: data.subCategoryId,
          tagId: data.tagId,
          description: data.description,
          coverImages: data?.coverImages || [
            {
              isMain: true,
              url: "/",
            },
          ],
          detailImages: data?.detailImages || ["/"],
        });
        setLoading(false);
        message.success("Амжилттай бараа засагдлаа!");
        await mutate();
      }
      if (isNext) onTabChange("2");
      else navigate("/product");
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
  };

  const onTabChange = (_index: any) => {
    navigate(`/product/edit/${data.id}/${_index}`);
    setTabIndex(_index);
  };

  useEffect(() => {
    if (tabIndex === "4") mutate();
  }, [tabIndex, mutate]);

  useEffect(() => {
    setTabIndex(`${index}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!data && isLoading)
    return (
      <div>
        <LoadingOverlay visible />
      </div>
    );

  return (
    <PageLayout title="Шинэ бүртгэл засах" breadcrumb={breadcrumbs} bgColor={theme.colors.gray[0]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={tabIndex}>
          <Tabs.List>
            <Tabs.Tab value="1">Үндсэн мэдээлэл</Tabs.Tab>
            <Tabs.Tab value="2">Динамик мэдээлэл</Tabs.Tab>
            <Tabs.Tab value="3">Захиалга тохиргоо</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">{tabIndex === "1" && <MainForm onSubmit={onMainSubmit} editable={["update", data || {}]} />}</Tabs.Panel>
          <Tabs.Panel value="2">
            {tabIndex === "2" && (
              <AdditionalInfomationForm
                onTabChange={onTabChange}
                reload={() => mutate()}
                tabChange={() => onTabChange("3")}
                editable={["update", data || {}]}
              />
            )}
          </Tabs.Panel>
          <Tabs.Panel value="3">{tabIndex === "3" && <ProductOrderSettingsTab />}</Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
});

EditProduct.displayName = "EditProduct";

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/product",
    label: "Бүтээгдэхүүн",
  },
  {
    label: "Бараа нэмэх",
  },
];

export { EditProduct };
