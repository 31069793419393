import { Avatar, Button, Checkbox, Divider, Group, Input, Modal, Select, Text, UnstyledButton } from "@mantine/core";
import { IconPhoto, IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StockAdjustmentApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { message } from "../../utils/message";
import { usePermission } from "../permission";

export const PackageAddForm = ({ setFieldValue, values }: { setFieldValue: (e: string, e2: any) => void; values: any }) => {
  const { itemTypes = [], itemStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>(values?.variants || []);
  const ERP_STOCK_ADJ = usePermission("ERP_STOCK_ADJ");

  useEffect(() => {
    if (action[0] === "package_add") {
      setSelected(values?.variants || []);
    }
  }, [action, values?.variants]);

  const columns = useHeader({
    itemTypes,
    itemStatus,
    values,
    selected,
    onClick: (key: string, item: any) => {
      if (key === "remove") {
        setSelected(selected.filter((f: any) => f.variantId !== item.variantId));
      }
      if (key === "add") {
        setSelected([
          ...selected,
          {
            id: item?.id,
            variantId: item?.id,
            image: item?.image,
            refCode: item?.refCode,
            skuCode: item?.skuCode,
            barCode: item?.barCode,
            nameMon: `${item.nameMon}, ${
              item?.optionValues?.map((item: any) => {
                return ` ${item.name}`;
              }) || ""
            } `,
            unitName: item?.itemUnit?.unit?.name,
            totalQuantity: item?.totalQuantity,
            movementType: "",
            quantity: item?.quantity,
            adjustmentNoteId: item?.adjustmentNoteId,
            availableQuantity: item?.availableQuantity || 0,
          },
        ]);
      }
      if (key === "select_all") {
        setSelected(
          item.map((i: any) => {
            return {
              id: i?.id,
              variantId: i?.id,
              image: i?.image,
              refCode: i?.refCode,
              skuCode: i?.skuCode,
              barCode: i?.barCode,
              nameMon: `${i.nameMon}, ${
                i?.optionValues?.map((i: any) => {
                  return ` ${i?.name}`;
                }) || ""
              } `,
              unitName: i?.itemUnit?.unit?.name,
              totalQuantity: i?.totalQuantity,
              movementType: "",
              quantity: i?.quantity || 0,
              adjustmentNoteId: i?.adjustmentNoteId,
              availableQuantity: i?.availableQuantity || 0,
            };
          }) || [],
        );
      }
      if (key === "unselect_all") {
        setSelected([]);
      }
    },
  });

  return (
    <div>
      <Group justify="space-between" my={"lg"}>
        <Text size={"lg"} fw={600}>
          Үлдэгдэл тохируулах бараа
        </Text>

        <Button
          onClick={() => {
            if (values.warehouseId) setAction(["package_add"]);
            else {
              message.error("Та эхлээд агуулах аа сонгоно уу.");
            }
          }}>
          Багцаар нэмэх
        </Button>
      </Group>

      <Modal
        centered
        opened={action[0] === "package_add"}
        onClose={() => setAction([])}
        title={
          <Text fw={600} fz={"lg"}>
            Багцаар нэмэх
          </Text>
        }
        size={"70rem"}>
        <Divider mb={"xs"} />
        {values?.warehouseId && (
          <>
            <Group p={"sm"} bg={"gray.0"}>
              <Input w={"220px"} leftSection={<IconSearch size={"1.2rem"} />} placeholder="Барааны нэрээр хайх" />
              <Select
                w={"220px"}
                leftSection={<IconSearch size={"1.2rem"} />}
                data={[]}
                placeholder="Ангилалаар хайх"
                searchable
                nothingFoundMessage="Ангилал байхгүй."
              />
            </Group>

            {ERP_STOCK_ADJ.isEdit ? (
              <Table
                minWidth={"max-content"}
                name={`stock.adjustment.list.${action[0]}`}
                columns={columns}
                filters={{}}
                pagination={false}
                loadData={async (data) => {
                  let res = await StockAdjustmentApi.variantSelect({ warehouseId: values.warehouseId });
                  return {
                    rows: res,
                    count: 1000,
                  };
                }}
              />
            ) : (
              ERP_STOCK_ADJ.accessDenied()
            )}
          </>
        )}
        <Divider my={"lg"} />
        <Group justify="right">
          <Button
            onClick={() => {
              setSelected([]);
              setAction([]);
            }}
            variant="outline">
            Болих
          </Button>
          <Button
            onClick={() => {
              setFieldValue("variants", selected);
              setSelected([]);
              setAction([]);
            }}>
            Нэмэх
          </Button>
        </Group>
      </Modal>
    </div>
  );
};

const useHeader = ({
  onClick,
  itemStatus,
  itemTypes,
  values,
  selected,
}: {
  onClick: (key: string, record: any | any) => void;
  itemStatus: IReference[];
  itemTypes: any[];
  values: any;
  selected: any[];
}): ColumnType<any | any>[] => [
  {
    title: "",
    actionTitle(data) {
      let isChecked: boolean = data.rows.length === selected?.length && selected?.length > 0;
      return (
        <Checkbox
          checked={isChecked}
          onChange={(e) => (!isChecked ? onClick("select_all", data?.rows) : onClick("unselect_all", data?.rows))}
          onClick={(e: any) => {}}
        />
      );
    },
    render: (record) => {
      return (
        <Checkbox
          checked={selected.some((som: any) => som.id === record.id)}
          onChange={() => {
            if (selected.some((som: any) => som.id === record.id)) {
              onClick("remove", record);
            } else onClick("add", record);
          }}
        />
      );
    },
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <UnstyledButton onClick={() => onClick("image", record)}>
        <Avatar src={`${record.image}`} radius="xs">
          <IconPhoto />
        </Avatar>
      </UnstyledButton>
    ),
  },
  {
    title: "DeHub Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.refCode || "-"}
      </Text>
    ),
  },
  {
    title: "SKU Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.skuCode}
      </Text>
    ),
  },
  {
    title: "Bar Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.barCode}
      </Text>
    ),
  },
  {
    title: "Барааны нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => (
      <Text size="sm" w="max-content">
        {`${record.nameMon}, ${
          record?.optionValues?.map((item: any) => {
            return ` ${item.name}`;
          }) || ""
        } `}
      </Text>
    ),
  },
  {
    title: "Барааны үлдэгдэл",
    sorter: true,
    dataIndex: "name",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.availableQuantity}
      </Text>
    ),
  },
];
