import { LoadingOverlay, Paper } from "@mantine/core";
import { useLocation, useParams } from "react-router";
import useSwr from "swr";
import { PriceGroupApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { SpecialPriceForm } from "../../components/special-price/main-form";

export const DetailsSpecialPrice = () => {
  const breadcrumbs = useBreadcrumb();
  const breadcrumbs2 = useBreadcrumb2();
  const params = useParams();
  const location = useLocation();
  const ERP_PG = usePermission("ERP_PG");

  const { data, isLoading } = useSwr<any[]>(
    ERP_PG.isView && `/erp/price_group/${params.id}`,
    async () => ERP_PG.isView && (await PriceGroupApi.get(`${params.id!}`)),
    {
      fallback: {},
    },
  );

  const onMainSubmit = async (data: any) => {};

  return (
    <PageLayout
      title={location.state?.type === "STANDARD" ? "Стандарт үнийн жагсаалт" : "Тусгай үнийн жагсаалт"}
      subTitle={
        location.state?.type === "STANDARD" ? "Өөрчлөлт хийгдсэн бараанууд, тэдгээрийн үнийн өөрчлөлт" : "Харилцагчид тусгайлан өгөх үнийн жагсаалт дэлгэрэнгүй"
      }
      breadcrumb={location.state?.type === "STANDARD" ? breadcrumbs2 : breadcrumbs}>
      {ERP_PG.isView ? (
        <Paper radius="sm" px="md" withBorder pos="relative">
          {isLoading ? <LoadingOverlay h={"100%"} visible={true} /> : <SpecialPriceForm loading={false} onSubmit={onMainSubmit} editable={["details", data]} />}
        </Paper>
      ) : (
        ERP_PG.accessDenied()
      )}
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/policy",
    label: "Стандарт үнийн бодлого",
  },
  {
    label: "Тусгай үнэ дэлгэрэнгүй",
  },
];

const useBreadcrumb2 = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/policy",
    label: "Стандарт үнийн бодлого",
  },
  {
    label: "Стандарт үнийн өөрчлөлт",
  },
];
