import { DatePickerInput, TimeInput } from "@mantine/dates";
import { EmotionStyles } from "@mantine/emotion";
import { IconClockHour7 } from "@tabler/icons-react";
import dayjs from "dayjs";
import React from "react";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  icon?: React.ReactNode;
  nullable?: boolean;
  styles?: EmotionStyles;
  noError?: boolean;
  onChange?: (value: string | undefined) => void;
  minDate?: Date;
  clearable?: boolean;
  showTime?: boolean;
};

export function DatePickerField({
  name,
  label,
  placeholder,
  disabled = false,
  icon,
  nullable,
  styles,
  noError = false,
  onChange: onChangeValue,
  minDate,
  clearable = false,
  showTime = false,
}: Props) {
  const { value, error, onChange } = useField(name);

  const dateValue = value ? new Date(value) : new Date();

  return (
    <DatePickerInput
      minDate={minDate}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      valueFormat="MM-DD-YYYY"
      error={error}
      clearable={clearable}
      value={dateValue}
      onChange={(e: Date | null) => {
        const formattedValue = e ? dayjs(e).format() : null;
        onChange(formattedValue);
        onChangeValue && onChangeValue(formattedValue ?? undefined);
      }}
      required={!nullable}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        cell: {
          padding: "0px",
        },
        ...styles,
      }}
      rightSectionWidth={90}
      rightSection={
        showTime ? (
          <TimeInput
            error={!!error}
            disabled={disabled || !dateValue}
            leftSection={<IconClockHour7 size={"1.2rem"} />}
            value={dateValue ? dayjs(dateValue).format("HH:mm") : ""}
            onChange={(e: any) => {
              const timeString = e.target.value;
              console.log("Time string from TimeInput: ", timeString);
              if (timeString.split(":").length < 2) return;

              const selectedTime = dayjs(dateValue || new Date())
                .set("hour", timeString.split(":")[0])
                .set("minute", timeString.split(":")[1]);

              if (!selectedTime.isValid()) {
                console.error("Invalid time format:", timeString);
                return;
              }

              onChange(selectedTime.format());
              onChangeValue && onChangeValue(selectedTime.format());
            }}
            styles={{
              input: {
                border: "transparent",
                background: "transparent",
              },
              disabled: {
                border: "transparent",
                background: "transparent",
              },
            }}
          />
        ) : (
          icon && icon
        )
      }
    />
  );
}
