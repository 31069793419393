import { MantineSize, NumberInput } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  noError?: boolean;
  hideControls?: boolean;
  classNames?: Partial<Record<string, string>> | undefined;
  defaultValue?: any;
  max?: number | undefined;
  errors?: any;
  styles?: any;
  rightSection?: any;
  rightSectionWidth?: any;
  required?: boolean;
  size?: MantineSize;
  icon?: any;
  iconWidth?: any;
};

export function NumberField({
  name,
  label,
  noError = false,
  hideControls = true,
  placeholder,
  disabled = false,
  classNames,
  defaultValue,
  max,
  errors,
  styles,
  rightSectionWidth,
  rightSection,
  required,
  size,
  icon,
  iconWidth,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <>
      <NumberInput
        leftSection={icon}
        leftSectionWidth={iconWidth}
        size={size || "sm"}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        error={errors ? errors : error}
        value={value === "" ? undefined : value}
        hideControls={hideControls}
        onChange={(v) => onChange(v)}
        classNames={classNames}
        max={max}
        rightSectionWidth={rightSectionWidth}
        styles={{
          error: {
            display: noError ? (errors ? "block" : "none") : "block",
            marginBottom: errors ? -7 : 0,
          },
          wrapper: {
            marginBottom: error && noError && 0,
            marginTop: errors ? 10 : 0,
          },

          ...styles,
        }}
        rightSection={rightSection}
        required={required}
      />
    </>
  );
}
