import { ActionIcon, Alert, Button, Collapse, Flex, Grid, Group, Input, Modal, MultiSelect, Paper, Select, Text, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { randomId } from "@mantine/hooks";
import { IconPlus, IconRowInsertBottom, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { GeneralApi, OptionApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { initGeneral } from "../../store/general";
import { message } from "../../utils/message";
import { VariantsTable } from "./variants-table";

interface IProps {
  dataUpdate: any;
  reload: () => Promise<void>;
}

type IOptionForm = {
  action: undefined | string | null;
  name: string | number | readonly string[] | undefined;
  isGoods: boolean;
  isService: boolean;
  values: string[];
};

const schema2 = yup.object().shape({
  action: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").nullable(),
  isGoods: yup.boolean().optional().nullable(),
  isService: yup.boolean().optional().nullable(),
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  values: yup.array().min(2, "Багадаа 2 -ийг оруулна уу!").max(100, "Хэт их байна.").required("Заавал бөглөнө!").nullable(),
});

const VariantsInfo: React.FC<IProps> = React.memo(({ dataUpdate, reload }) => {
  const params = useParams();
  const { options: initOptionsRoot } = useSelector((state: { general: IGeneral }) => state.general);
  const [initOptions, setInitOptions] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>(
    dataUpdate?.options?.map((i: any) => {
      return {
        ...i,
        values: dataUpdate?.optionValues?.filter((f: any) => f.optionId === i.id) || [],
      };
    }) || [],
  );
  const [optionLoad, setOptionLoad] = useState<boolean>(false);
  const dispatch = useDispatch();

  const optionForm = useForm<IOptionForm>({
    validate: yupResolver(schema2),
    initialValues: {
      action: "",
      name: "",
      isGoods: true,
      isService: false,
      values: [],
    },
  });

  const onCloseModal = () => {
    setOptionLoad(true);

    optionForm.setValues({
      action: "",
      name: "",
      isGoods: true,
      isService: false,
      values: [],
    });
    setOptionLoad(false);
  };

  const optionCreateSubmit = async (values: any) => {
    setOptionLoad(true);

    try {
      await OptionApi.create({
        isGoods: true,
        isService: false,
        name: values.name,
        values: values?.values || [],
      });

      const res2 = await GeneralApi.init();
      dispatch(initGeneral(res2));
      message.success("Үйлдэл амжилттай.");
    } catch (error: any) {
      message.error(error?.message || "Үйлдэл амжилтгүй.");
    }

    onCloseModal();
  };

  useEffect(() => {
    setInitOptions(initOptionsRoot);
  }, [initOptionsRoot]);

  return (
    <Paper>
      <Grid>
        <Grid.Col>
          <Group align="right" bg={"gray.0"} p={"xs"}>
            <ActionIcon
              onClick={() => setOptions([...(options && options), { ...rowInsert, id: randomId() }])}
              variant="light"
              color=""
              disabled={options?.length >= initOptions?.filter((f: any) => f.isGoods)?.length}>
              <IconRowInsertBottom />
            </ActionIcon>
            <Button onClick={() => optionForm.setFieldValue("action", "option-new")} variant="outline" leftSection={<IconPlus size={"1.2rem"} />} size="xs">
              Хувилбар
            </Button>
          </Group>
        </Grid.Col>

        <Grid.Col>
          {options?.map((OI: any, index: number) => {
            return (
              <Grid key={index}>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Select
                    value={OI?.id || ""}
                    onChange={(changeId: string | null) => {
                      let itemUpdate = initOptions.filter((f: any) => f.isGoods)?.find((f: any) => f.id === changeId);
                      setOptions(
                        options?.map((item2: any, index2: number) => {
                          if (index === index2) return { ...itemUpdate, values: [] };
                          else return item2;
                        }),
                      );
                    }}
                    label="Хувилбарын нэр"
                    data={initOptions
                      .filter((f: any) => f.isGoods)
                      .map((initOI: any) => {
                        return {
                          value: initOI.id,
                          label: initOI.name,
                          disabled: options.some((som: any) => som.id === initOI.id),
                        };
                      })}
                    placeholder="Сонгох"
                    required
                    clearable
                    nothingFoundMessage="Сонголт байхгүй"
                    searchable
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
                  <Flex w={"100%"} gap={"xs"}>
                    <MultiSelect
                      w={"100%"}
                      value={OI?.values?.map((i: any) => i?.id) || []}
                      onChange={(es: string[]) => {
                        const IDS = es.filter((f: string) => f !== "null");

                        let itemUpdate = initOptions.filter((f: any) => f.isGoods)?.find((f: any) => f.id === OI.id);

                        setOptions(
                          options?.map((item2: any, index2: number) => {
                            if (item2.id === itemUpdate.id)
                              return {
                                ...item2,
                                values:
                                  IDS.map((i: string) => {
                                    return itemUpdate.values.find((f: any) => f.id === i);
                                  }) || [],
                              };
                            else return item2;
                          }),
                        );
                      }}
                      label="Хувилбар дахь сонголтууд"
                      data={
                        initOptions
                          .find((f) => f.id === OI.id)
                          ?.values?.map((i: any) => {
                            return {
                              label: i.name,
                              value: i.id,
                            };
                          }) || []
                      }
                      placeholder="Сонгох"
                      nothingFoundMessage="Сонголт байхгүй"
                      disabled={!OI.id}
                      required
                      clearable
                      searchable
                      onOptionSubmit={(query) => {
                        const start = Date.now();
                        const randId = randomId();

                        const createValue = {
                          businessId: null,
                          id: randId,
                          isGoods: true,
                          createdAt: start,
                          updatedAt: null,
                          deletedAt: null,
                          optionId: OI.id,
                          isDehub: false,
                          isPublic: true,
                          isService: false,
                          name: query,
                        };

                        setInitOptions(
                          initOptions.map((item2: any, index2: number) => {
                            if (OI?.id === item2?.id)
                              return {
                                ...item2,
                                values: [...item2.values, createValue],
                              };
                            return item2;
                          }),
                        );

                        return "null";
                      }}
                    />
                    <ActionIcon
                      onClick={() => setOptions([...(options?.filter((f: any) => f.id !== OI.id) || [])])}
                      variant="light"
                      color="red"
                      radius={8}
                      size={"sm"}>
                      <IconX size={"1rem"} />
                    </ActionIcon>
                  </Flex>
                </Grid.Col>
              </Grid>
            );
          })}

          <Collapse in={options?.length === 0} my={"sm"}>
            <Alert title="Заавал бөглөнө!" color="red">
              <Flex gap={8} align="center">
                <Text>Хувилбарын сонголт оо нэмнэ үү.</Text> <IconRowInsertBottom size={"1.2rem"} color="gray" />
              </Flex>
            </Alert>
          </Collapse>
        </Grid.Col>
        <Grid.Col>
          <Collapse in={options?.length > 0}>
            <VariantsTable
              reload={reload}
              variantUpdate={
                dataUpdate?.variants?.map((item: any) => {
                  return {
                    id: item?.id || "",
                    status: "ACTIVE",
                    refCode: item?.refCode || "",
                    goodsId: params.id,
                    skuCode: item?.skuCode || "",
                    barCode: item?.barCode || "",
                    erpCode: item?.erpCode || "",
                    nameApp: item?.nameApp || "",
                    nameWeb: item?.nameWeb || "",
                    nameBill: item?.nameBill || "",
                    image: item?.image || "",
                    isEdit: item?.isEdit || "",
                    isLoading: false,
                    optionValues:
                      item?.optionValues?.map((item: any) => {
                        return {
                          optionId: item?.optionId || "",
                          id: item.id,
                          name: item?.name || "",
                        };
                      }) || [],
                  };
                }) || []
              }
              options={
                options?.map((item) => {
                  return {
                    id: item.id,
                    name: item?.name || "",
                    values: item.values?.map((item: any) => {
                      return {
                        optionId: item?.optionId || "",
                        id: item?.id || "",
                        name: item?.name || "",
                      };
                    }),
                  };
                }) || []
              }
            />
          </Collapse>
        </Grid.Col>
      </Grid>

      <Modal
        centered
        title={
          <Text fw={600} size={"lg"}>
            Хувилбар нэмэх
          </Text>
        }
        opened={optionForm.values.action === "option-new"}
        onClose={onCloseModal}
        size={"lg"}>
        <form onSubmit={optionForm.onSubmit(optionCreateSubmit)}>
          <Grid>
            <Grid.Col>
              <Input.Wrapper label="Хувилбарын нэр" required>
                <TextInput {...optionForm.getInputProps("name")} placeholder="Хувилбарын нэр" />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col>
              <Input.Wrapper label="Хувилбарын сонголт" required>
                <MultiSelect
                  data={optionForm.values.values}
                  placeholder="Хувилбарын сонголт оруулна уу."
                  searchable
                  error={optionForm.errors.values}
                  label={"Сонголт нэмэх"}
                  onOptionSubmit={(query) => {
                    optionForm.setFieldValue(`values`, [...optionForm.values.values, query]);
                    return query;
                  }}
                  onChange={(e) => {
                    optionForm.setFieldValue(`values`, e);
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col>
              <Group align="right">
                <Button onClick={onCloseModal} variant="default">
                  Болих
                </Button>
                <Button loading={optionLoad} type="submit">
                  Хадгалах
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Modal>
    </Paper>
  );
});

const rowInsert = {
  id: null,
  name: null,
  isGoods: true,
  isService: false,
  isPublic: true,
  isDehub: true,
  values: [],
};

VariantsInfo.displayName = "VariantsInfo";

export { VariantsInfo };
