import { Avatar, Group, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { ColumnType, Table } from "../../components/table";
import { currencyFormat } from "../../utils";

export const MostSoldTable = ({ data }: { data: any[] }) => {
  const columns = useHeader();

  return (
    <div>
      <Table name="most.sold.list" columns={columns} filters={{}} dataSource={data} />
    </div>
  );
};

const useHeader = (): ColumnType<any | any>[] => [
  {
    title: "Барааны нэр",
    render: (record) => {
      return (
        <Group>
          <Avatar src={record?.image}>
            <IconPhoto />
          </Avatar>
          <div>
            <Text fw={600}>{record?.nameMon} </Text>
            <Text fw={600}>
              {record?.variantOptions?.map((item: any, index: number) => {
                return (
                  <Text component="span" key={index}>
                    , {item}
                  </Text>
                );
              })}{" "}
            </Text>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Борлуулсан дүн",
    render: (record) => {
      return (
        <Group align="right">
          <Text w={"max-content"}>{currencyFormat(record.soldAmount)}</Text>
        </Group>
      );
    },
  },
  {
    title: "Борлуулсан тоо",
    render: (record) => {
      return record?.soldCount || 0;
    },
  },
  {
    title: "Захиалсан бизнес",
    render: (record) => {
      return record?.orderedBusinessCount;
    },
  },
];
