import { ActionIcon, Avatar, Badge, Card, Flex, Grid, Group, LoadingOverlay, MantineTheme, Pagination, Select, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconPackage, IconPhotoOff, IconPlus } from "@tabler/icons-react";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";
import { currencyFormat } from "../../utils";

export * from "./RowAction";

export type ColumnType<T> = {
  title: string;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  filters?: { [key: string]: string | number | any };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  onClick: any;
};

export type ITableRef = { reload: () => void };

interface State {
  page: number;
  limit: number | null;
}

export const ProductTable = React.forwardRef(
  ({ name, filters = {}, pagination = true, dataSource = [], loadData, onClick }: Props, ref: React.Ref<ITableRef>) => {
    const { classes } = useStyles();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const [offset, setOffset] = React.useState<State>({ page: 1, limit: 12 });
    const limit = offset.limit ?? 1;

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
      async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );

    const onScroll = () => {};

    useImperativeHandle(ref, () => ({
      reload() {
        return mutate();
      },
    }));

    function FeaturesCard({ item, index }: any) {
      const { classes } = useStyles();

      return (
        <Card withBorder radius="md" className={classes.card} key={index}>
          <Avatar h={"100%"} className={classes.imageSection} w={"100%"} src={item?.image} alt={item.nameApp}>
            <IconPhotoOff size={"32px"} />
          </Avatar>
          <Group justify="space-between" mt="md" pb={"xs"}>
            <div>
              <Text w={"200px"} fw={500}>
                {item.nameApp ?? "-"}
              </Text>
            </div>
            {item.hasUnit ? (
              <Badge color={"indigo"} variant="outline">
                би зардаг
              </Badge>
            ) : (
              <ActionIcon onClick={() => onClick(index, item)} color="indigo" variant="light">
                <IconPlus />
              </ActionIcon>
            )}
          </Group>

          <Card.Section className={classes.section}>
            <Group justify="space-between">
              <div>
                <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>
                  {currencyFormat(item?.customPrice || 0)}
                </Text>
                <Text fz="sm" c="dimmed" fw={400} sx={{ lineHeight: 1 }} mt={3}>
                  Хямдрал
                </Text>
              </div>

              <div>
                <Text td="line-through" c="gray" fz="xl" fw={600} sx={{ lineHeight: 1 }}>
                  {currencyFormat(item?.standardPrice)}
                </Text>
                <Text fz="sm" c="dimmed" fw={400} sx={{ lineHeight: 1 }} mt={3}>
                  Үндсэн үнэ
                </Text>
              </div>
            </Group>
          </Card.Section>
        </Card>
      );
    }

    return (
      <div className={classes.container}>
        <div ref={scrollRef} onScroll={onScroll} className={classes.scroll}>
          <Grid>
            {data?.rows?.map((item: any, index: number) => {
              return (
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
                  <FeaturesCard item={item} index={index} />
                </Grid.Col>
              );
            })}

            {data?.rows?.length === 0 && (
              <div className={classes.notFound}>
                <Flex direction="column" align="center">
                  <IconPackage size={"50px"} color="gray" stroke="1" />
                  <Text c="gray" size="md">
                    Өгөгдөл олдсонгүй.
                  </Text>
                </Flex>
              </div>
            )}
          </Grid>
          <LoadingOverlay visible={isLoading} />
        </div>

        {pagination && (
          <div className={classes.pagination}>
            <Pagination onChange={(page) => setOffset((state) => ({ ...state, page: page }))} total={data.count / limit || +(data.count % limit > 0 ? 1 : 0)} />
            <Space w={"xs"} />
            <Select
              onChange={(e: string | null) => {
                const limit = e !== null ? parseInt(e) : null;
                setOffset((state) => ({ ...state, limit }));
              }}
              value={`${offset.limit}`}
              w={"100px"}
              size="xs"
              defaultValue={"10"}
              data={["10", "20", "30", "50", "100", "200"]}
            />
          </div>
        )}
      </div>
    );
  },
);

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {},
  notFound: {
    width: "100%",
    height: "400px",
    padding: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.colors.gray[0],
  },
  scroll: {
    overflow: "hidden",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
    alignItems: "center",
    gap: 10,
  },
  icon: {
    color: theme.colors.yellow[5],
  },

  name: {},

  card: {
    height: "100%",
  },

  imageSection: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    maxHeight: "220px",
    minHeight: "220px",
    overflow: "hidden",
    width: "220px",
    objectFit: "cover",
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },

  icon2: {
    marginRight: "80px",
    color: theme.colors.gray[5],
  },
}));
