import { IFilter } from "../interfaces/IFilter";
import { IService } from "../interfaces/IService";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/service", { offset, filter });
};

export const create = async (data: IService | any) => {
  return httpRequest.post("/erp/service", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/service/${id}`);
};

export const updateVariant = async (id: string, data: any) => {
  return httpRequest.put(`/erp/service/${id}/variant`, data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/erp/service/${id}`, data);
};

export const additionalInfo = async (id: string, data: any) => {
  return httpRequest.put(`/erp/service/${id}/additional_info`, data);
};
