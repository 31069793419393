import { createSlice } from "@reduxjs/toolkit";

export interface IPubState {
  supptabKey: string;
}

const initialState: IPubState = {
  supptabKey: "our-supp",
};

const pubStateSlice = createSlice({
  name: "pubState",
  initialState,
  reducers: {
    updateSuppTabKey: (state, { payload }: { payload: string }) => {
      state.supptabKey = payload;
      return state;
    },
  },
});

export const pubStateReducer = pubStateSlice.reducer;

export const { updateSuppTabKey } = pubStateSlice.actions;
