import { Button, CloseButton, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { CategoryApi } from "../../apis";
import { ICategory } from "../../interfaces/ICategory";
import { IGeneral } from "../../interfaces/IGeneral";
import { RefCategory } from "../../models/RefCategory";
import { updateCategories } from "../../store/general";
import { HttpHandler } from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  itemType: yup.string().required("Заавал бөглөнө!").nullable(),
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  classificationId: yup.string().required("Заавал бөглөнө!").nullable(),
  subClassificationId: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  title: string;
  subTitle: string;
  action: string[]; // ['CLASSIFICATION', itemTypeId, parentId]
  payload?: ICategory;
  reloadTable?: any;
};

export function ReferenceCategoryForm({ title, subTitle, onCancel, action, payload, reloadTable }: Props) {
  const dispatch = useDispatch();
  const { itemTypes, categories } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<RefCategory>({
    id: "",
    itemType: "",
    classificationId: "",
    subClassificationId: "",
    name: "",
    description: "",
    ...(payload
      ? {
          ...payload,
          itemType: payload.itemType?.id as string,
          classificationId: payload.classification?.id as string,
          subClassificationId: payload.subClassification?.id as string,
        }
      : {}),
  });

  console.log(payload);

  const onSubmit = async (data: RefCategory) => {
    try {
      setLoading(true);

      if (payload) {
        await CategoryApi.update(payload.id, {
          name: data.name,
          description: data.description,
        });
      } else {
        await CategoryApi.create({
          name: data.name,
          description: data.description,
          type: "CATEGORY",
          itemTypeId: data.itemType,
          parentId: data.subClassificationId,
        });
      }
      reloadTable();
      setLoading(false);

      message.success("Таны хүсэлт амжилттай!");

      onCancel && onCancel();

      let res = await CategoryApi.select();

      dispatch(updateCategories(res));
    } catch (err) {
      setLoading(false);

      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue, errors }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Flex gap={15} direction="column">
              {action[0] === "new" && (
                <SelectField
                  name="itemType"
                  label="Нэр төрөл"
                  onChange={() => {
                    setFieldValue("classificationId", null);
                    setFieldValue("subClassificationId", null);
                  }}
                  placeholder="Нэр төрөл"
                  options={itemTypes.map((c) => ({ label: c.name, value: c.id }))}
                />
              )}
              {action[0] === "new" && (
                <SelectField
                  label="Ангилал"
                  name="classificationId"
                  placeholder="Ангилал"
                  disabled={!values.itemType}
                  onChange={() => {
                    setFieldValue("subClassificationId", null);
                  }}
                  options={categories
                    .filter((c) => c.type === "CLASSIFICATION" && c.itemTypeId === values.itemType)
                    .map((s) => ({ label: s.name, value: s.id }))}
                />
              )}
              {action[0] === "new" && (
                <SelectField
                  name="subClassificationId"
                  label="Дэд ангилал"
                  placeholder="Дэд ангилал"
                  options={categories
                    .filter((c) => c.type === "SUB_CLASSIFICATION" && c.itemTypeId === values.itemType && values.classificationId === c.parentId)
                    .map((s) => ({ label: s.name, value: s.id }))}
                  disabled={!values.classificationId}
                />
              )}
              <TextField name="name" label="Категори нэр" placeholder="Категори нэр" />
              <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
            </Flex>

            <Group justify="right" mt={20}>
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
}
