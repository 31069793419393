import { Badge, MantineColor } from "@mantine/core";

function Tag(color: MantineColor, name: string) {
  if (color?.slice(0, 1) === "#")
    return (
      <Badge
        style={{ border: `${color} 1px solid`, color: color }}
        size="md"
        radius="xl"
        variant="outline"
        styles={{
          label: {
            overflow: "visible",
            textOverflow: "unset",
          },
        }}>
        {name}
      </Badge>
    );
  else
    return (
      <Badge
        color={color}
        size="md"
        radius="xl"
        variant="outline"
        styles={{
          label: {
            overflow: "visible",
            textOverflow: "unset",
          },
        }}>
        {name}
      </Badge>
    );
}

export { Tag };
