import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string; isGoods: boolean; isService: boolean; values: string[] }) => {
  return httpRequest.post("/erp/option", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/option/${id}`);
};

export const list = async (data: any) => {
  return httpRequest.get(`/erp/option`, data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/option/${id}`);
};

export const select = async (query: { isGoods: boolean; isService: boolean; query: string | null }) => {
  return httpRequest.del(`/erp/select`, query);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/erp/option/${id}`, data);
};
