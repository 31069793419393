import { Box, Button, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useNavigate } from "react-router";

const NotFound: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <Box className={classes.root}>
      <Text fz={150} fw={600} c="gray">
        404
      </Text>
      <Text fz={16} fw={500} c="gray" ta="center">
        Таны хайсан хуудас байхгүй байна
      </Text>
      <Button variant="gradient" gradient={{ from: "#fff", to: "#7950f2", deg: 15 }} mt="lg" onClick={() => navigate("/")}>
        Нүүр хуудас
      </Button>
    </Box>
  );
};

export { NotFound };

const useStyles = createStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    background: theme.colors.gray[0],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
