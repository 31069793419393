import { Button, Flex, Group, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { PriceGroupApi } from "../../apis";
import { message } from "../../utils/message";
import { IFormRef } from "../form";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import ChangeForm from "../policy/standart-price-form";

type IFormData = {};

export const ChangeStandardPrice = () => {
  const chagneFormRed = useRef<IFormRef<IFormData>>(null);
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const ERP_PG = usePermission("ERP_PG");

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const isValid = {
        type: values?.type,
        name: values?.name,
        description: values?.description,
        startDate: values?.startDate ? dayjs(values?.startDate).format() : null,
        endDate: values?.endDate ? dayjs(values?.endDate).format() : null,
        variants:
          values?.variants.map((item: any) => {
            return {
              variantId: item?.variantId,
              standardPrice: item?.standardPrice,
              tier1Price: item?.tier1Price?.isSet ? item?.tier1Price?.value : null,
              tier2Price: item?.tier2Price?.isSet ? item?.tier2Price?.value : null,
              tier3Price: item?.tier3Price?.isSet ? item?.tier3Price?.value : null,
              tier4Price: item?.tier4Price?.isSet ? item?.tier4Price?.value : null,
              tier5Price: item?.tier5Price?.isSet ? item?.tier5Price?.value : null,
              tier6Price: item?.tier6Price?.isSet ? item?.tier6Price?.value : null,
              tier7Price: item?.tier7Price?.isSet ? item?.tier7Price?.value : null,
              tier8Price: item?.tier8Price?.isSet ? item?.tier8Price?.value : null,
              tier9Price: item?.tier9Price?.isSet ? item?.tier9Price?.value : null,
              tier10Price: item?.tier10Price?.isSet ? item?.tier10Price?.value : null,
            };
          }) || [],
        confirm: values?.confirm || false,
      };

      await PriceGroupApi.createGroup(isValid);

      message.success("Үйлдэл амжилттай.");

      navigate("/policy");
    } catch (error: any) {
      message.error(error?.message || "Үйлдэл амжилтгүй!");
      setLoading(false);
    }
  };

  return (
    <PageLayout title="Стандарт үнэ өөрчлөх" subTitle="Сонгосон бараанд үнэ өөрчлөх" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <Group align="center" justify="space-between">
          <Text fz={"lg"} fw={600}>
            Өөрчлөлтийн ерөнхий мэдээлэл
          </Text>
          <Flex align={"center"} gap={8}>
            <Button onClick={() => navigate("/policy")} variant="default">
              Болих
            </Button>
            <Button
              hidden={!ERP_PG.isCreate}
              loading={loading}
              onClick={async () => {
                let isValid = await chagneFormRed.current?.submit();

                if (isValid) {
                  let data = await chagneFormRed.current?.submit();
                  onSubmit({ ...data, confirm: false });
                }
              }}
              variant="outline">
              Хадгалах
            </Button>
            <Button
              loading={loading}
              onClick={async () => {
                let isValid = await chagneFormRed.current?.submit();

                if (isValid) {
                  let data = await chagneFormRed.current?.submit();
                  onSubmit({ ...data, confirm: true });
                }
              }}>
              Батлах
            </Button>
          </Flex>
        </Group>

        <ChangeForm chagneFormRed={chagneFormRed} />
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/policy",
    label: "Үнийн бодлого",
  },
  {
    label: "Үнэ нэмэх",
  },
];
