import { ActionIcon, Box, Button, Divider, Flex, Grid, LoadingOverlay } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { GoodsApi } from "../../apis";
import { message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { DatePickerField } from "../form/datepicker-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { FormLayout } from "../layout";

type IFormData = {
  variantId: string | undefined;
  standardPrice: string | undefined;
  currency: string | undefined;
  standardMinPrice: string | undefined;
  priceMinQuantity: string | undefined;
  hasVat: boolean | undefined;
  vatPercent: string | undefined;
  hasTax: boolean | undefined;
  taxPercent: string | undefined;
  avgPurchasePrice: string | undefined | null;
  lastPurchasePrice: string | undefined | null;
  priceStartDate: string | undefined;
  priceEndType: string | undefined;
};

const schema = yup.object({
  standardPrice: yup.number().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  currency: yup.string().required("Заавал бөглөнө!").nullable(),
  standardMinPrice: yup.number()?.moreThan(0, "0-с их үнэ байна!").required("Заавал бөглөнө!"),
  priceMinQuantity: yup.number()?.moreThan(0, "0-с их үнэ байна!").required("Заавал бөглөнө!"),
  hasVat: yup.string().required("Заавал бөглөнө!"),
  vatPercent: yup
    .number()
    .max(10, "Ихдээ 10 хувь")
    .when("hasVat", (hasVat, field) => (typeof hasVat === "string" && hasVat === "true" ? field.required("Заавал бөглөнө!") : field)),
  hasTax: yup.string().required("Заавал бөглөнө!"),
  taxPercent: yup
    .number()
    .max(2, "Ихдээ 2 хувь")
    .when("hasTax", (hasTax, field) => (typeof hasTax === "string" && hasTax === "true" ? field.required("Заавал бөглөнө!") : field)),
  avgPurchasePrice: yup.string().nullable(),
  lastPurchasePrice: yup.string().nullable(),
  priceStartDate: yup.string().required("Заавал бөглөнө!"),
  priceEndType: yup.string().nullable(),
});

export function GoodSetPriceForm({ onClose, action, reloadTable }: { onClose: () => void; action: any; reloadTable: any }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { currencies, priceEndTypes } = useSelector((state: any) => state.general);

  const [data] = React.useState<IFormData>({
    variantId: action[1]?.variantId,
    standardPrice: undefined,
    currency: "TUGRUG",
    standardMinPrice: undefined,
    priceMinQuantity: undefined,
    hasVat: false,
    vatPercent: undefined,
    hasTax: false,
    taxPercent: undefined,
    avgPurchasePrice: null,
    lastPurchasePrice: null,
    priceStartDate: undefined,
    priceEndType: undefined,
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);

    if (values?.variantId) {
      try {
        await GoodsApi.setPrice(values);
        message.success("Үнийн мэдээлэл амжилттай хадгалагдлаа");
        setLoading(false);
        reloadTable();
        onClose();
      } catch (error: any) {
        setLoading(false);
        message.error(error?.message || "Алдаа гарлаа");
      }
    } else {
      message.error("Алдаа гарлаа");
      setLoading(false);
    }
  };

  return (
    <Form validationSchema={schema} initialValues={data} onSubmit={onSubmit}>
      {({ values, errors }) => {
        return (
          <FormLayout
            title="Стандарт үнийн мэдээлэл"
            extra={[
              <ActionIcon onClick={() => onClose()} key={1}>
                <IconX />
              </ActionIcon>,
            ]}>
            <Grid>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <LoadingOverlay visible={loading} />
              <Grid.Col span={4}>
                <NumberCurrencyField name="standardPrice" label="Стандарт үнийн мэдээлэл" placeholder="Стандарт үнийн мэдээлэл" />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField options={currencies?.map((c: any) => ({ label: c.name, value: c.code }))} name="currency" label="Валют" placeholder="Валют" />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField name="standardMinPrice" label="Минимум-стандарт үнэ (НӨАТ-гүй)" placeholder="Минимум-стандарт үнэ (НӨАТ-гүй)" />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField
                  name="priceMinQuantity"
                  label="Минимум тоо хэмжээ - үндсэн нэгж (НӨАТ-гүй)"
                  placeholder="Минимум тоо хэмжээ - үндсэн нэгж (НӨАТ-гүй)"
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Box h={errors?.priceMinQuantity ? "80%" : "100%"} sx={{ display: "flex", alignItems: "end" }}>
                  <CheckboxField name="hasVat" label="НӨАТ-тай эсэх" />
                </Box>
              </Grid.Col>
              <Grid.Col span={4}>
                <Box h={errors?.priceMinQuantity ? "80%" : "100%"} sx={{ display: "flex", alignItems: "end" }}>
                  <CheckboxField name="hasTax" label="НХАТ-тай эсэх" />
                </Box>
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField disabled name="avgPurchasePrice" label="Татан авалтын дундаж үнэ" placeholder="Татан авалтын дундаж үнэ" />
              </Grid.Col>
              <Grid.Col span={4}>{values.hasVat ? <NumberField name="vatPercent" label="НӨАТ тооцох хувь" placeholder="НӨАТ тооцох хувь" /> : null} </Grid.Col>

              <Grid.Col span={4}> {values.hasTax ? <NumberField name="taxPercent" label="НХАТ тооцох хувь" placeholder="НХАТ тооцох хувь" /> : null} </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField disabled name="lastPurchasePrice" label="Сүүлд татан авсан үнэ" placeholder="Сүүлд татан авсан үнэ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <DatePickerField name="priceStartDate" label="Эхлэх огноо" placeholder="Эхлэх огноо" />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  disabled
                  options={priceEndTypes?.map((c: any) => ({ label: c.name, value: c.code }))}
                  name="priceEndType"
                  label="Дуусах хугацааны төрөл"
                  placeholder="Дуусах хугацааны төрөл"
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Flex justify="end">
                  <Button key={2} type="submit">
                    Хадгалах
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </FormLayout>
        );
      }}
    </Form>
  );
}
