import { Paper, Tabs } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router";
import { ServiceApi } from "../../apis";

import { createStyles } from "@mantine/emotion";
import { PageLayout } from "../../components/layout";
import { ServiceMainForm } from "../../components/service/main-form";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export const NewService = () => {
  const { theme } = useStyle();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabIndex] = React.useState("1");
  const [loading, setLoading] = React.useState(false);

  const onMainSubmit = async (data: any) => {
    try {
      setLoading(true);
      const res = await ServiceApi.create({
        ...data,
        coverImages: data?.coverImages || [
          {
            isMain: true,
            url: "/",
          },
        ],
        detailImages: data?.detailImages || ["/"],
      });
      /// FIX
      navigate(`/service/edit/${res.id}/2`);
      setLoading(false);
      message.success("Амжилттай Үйлчилгээ нэмлээ!");
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <PageLayout title="Шинэ бүртгэл хийх" breadcrumb={breadcrumbs} bgColor={theme.colors.gray[0]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={tabIndex}>
          <Tabs.List>
            <Tabs.Tab value="1">Үндсэн мэдээлэл</Tabs.Tab>
            <Tabs.Tab value="2">Нэмэлт мэдээлэл</Tabs.Tab>
            <Tabs.Tab value="3">Захиалгийн тохиргоо</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">
            <ServiceMainForm loading={loading} onSubmit={onMainSubmit} editable={["create"]} />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
};

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/product",
    label: "Бүтээгдэхүүн",
  },
  {
    label: "Үйлчилгээ нэмэх",
  },
];
