import { Badge, Button, Divider, Group, LoadingOverlay, Modal, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { DistributorApi } from "../../apis";
import { IDistributor } from "../../interfaces/IDistributor";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { ColumnType, ITableRef, Table } from "../table";
import { DistributorForm } from "./distributor-form";

export function DistributorList() {
  const [filters] = React.useState({ query: "" });
  const [action, setAction] = React.useState<string[]>([]);
  const [payload, setPayload] = React.useState<IDistributor | null>();
  const ref = React.useRef<ITableRef>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const ERP_REF_DIST = usePermission("ERP_REF_DIST");

  const columns = useHeader({
    actionAccess: ERP_REF_DIST,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setPayload(item);
          setAction(["update", item.name, item.id]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  let res = await DistributorApi.remove(item.id);
                  if (res.success) {
                    setTimeout(() => {
                      ref.current?.reload();
                    }, 200);
                  }
                  message.success("Амжилттай дистрибютрийг устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
    setPayload(null);
  };

  const reloadTable = () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Дистрибьютер"
        subTitle="Дистрибьютер жагсаалт, шинээр үүсгэх, засварлах"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference")}>
            Буцах
          </Button>,
          <Button
            hidden={!ERP_REF_DIST.isCreate}
            key={2}
            onClick={() => {
              setPayload(null);
              setAction(["new"]);
            }}>
            Дистрибьютер нэмэх
          </Button>,
        ]}>
        <Divider my="lg" />
        {ERP_REF_DIST.isView ? (
          <Table ref={ref} name="distributor.list" columns={columns} filters={filters} loadData={(data) => DistributorApi.list(data!)} />
        ) : (
          ERP_REF_DIST.accessDenied()
        )}
      </PageLayout>
      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} centered>
        <DistributorForm
          title="Дистрибьютер"
          subTitle={!payload ? "Дистрибьютерын нэмэх" : "Дистрибьютерын засах"}
          onCancel={onCancel}
          action={action}
          payload={payload || undefined}
          reloadTable={reloadTable}
        />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IDistributor) => void;
  actionAccess: {
    isDelete: boolean;
    isEdit: boolean;
    isView: boolean;
  };
};

const useHeader = ({ onClick, actionAccess }: HeaderProps): ColumnType<IDistributor>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нээлттэй",
    dataIndex: "isPublic",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isPublic ? "green.5" : "gray.5"}>
          {record.isPublic ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "DeHub",
    dataIndex: "isDehub",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isDehub ? "green.5" : "gray.5"}>
          {record.isDehub ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Group gap={10}>
          <Tooltip label="Засах">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} hidden={!actionAccess.isEdit}>
              <IconEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Устгах">
            <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)} hidden={!actionAccess.isDelete}>
              <IconTrash />
            </Button>
          </Tooltip>
        </Group>
      );
    },
  },
];
