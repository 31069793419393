import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const me = async () => {
  const res = await httpRequest.get("/erp/auth/me");
  return res;
};

export const checkPin = async (data: any) => {
  return httpRequest.put("/2fa/auth/pin/check", data);
};
