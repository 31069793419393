import { Paper } from "@mantine/core";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router";
import { PriceGroupApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { SpecialPriceForm } from "../../components/special-price/main-form";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export const NewSpecialPrice = () => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [loading, setLoading] = React.useState(false);
  const ERP_PG = usePermission("ERP_PG");

  const onMainSubmit = async (data: any) => {
    try {
      setLoading(true);

      let transmition = {
        type: "SPECIAL",
        name: data.name,
        perType: data?.isAuto ? data.perType : null,
        perValue: data?.isAuto ? (data.perType === "AMOUNT" ? data?.perValueAmount : data?.perValuePercentage) : null,
        changeType: data?.isAuto ? data.changeType : null,
        description: data.description,
        startDate: data.startDate ? dayjs(data.startDate).format() : "",
        endDate: dayjs(data.endDate).format(),
        variants:
          data?.variants?.map((item: any) => {
            return {
              variantId: item?.variantId,
              tier0Price: item?.tier0Price,
            };
          }) || [],
        confirm: data?.confirm || false,
      };

      await PriceGroupApi.createSpecial(transmition);

      message.success("Үйлдэл амжилттай.");
      navigate("/policy");
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <PageLayout title="Тусгай үнийн жагсаалт нэмэх" subTitle={"Харилцагчид тусгайлан өгөх үнийн жагсаалт нэмэх"} breadcrumb={breadcrumbs}>
      <Paper radius="sm" px="md" withBorder>
        {ERP_PG.isCreate ? <SpecialPriceForm loading={loading} onSubmit={onMainSubmit} editable={["create"]} /> : ERP_PG.accessDenied()}
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/policy",
    label: "Үнийн бодлого",
  },
  {
    label: "Тусгай үнэ нэмэх",
  },
];
