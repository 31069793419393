import { Avatar, Badge, Select, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { IService } from "../../interfaces/IService";
import { PageFilter } from "../layout";
import { PopoverText } from "../popover-text/popover-text";
import { ColumnType, RowAction, Table } from "../table";
import { IconPhoto } from "@tabler/icons-react";

export const ServiceList = () => {
  const { itemStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [filters] = React.useState({
    serviceStatus: null,
    categoryId: null,
  });
  const navigate = useNavigate();

  const columns = useHeader({
    itemStatus,
    onClick: (key, record) => {
      switch (key) {
        case "edit":
          navigate(`/service/edit/${record.id}/1`);
      }
    },
  });

  return (
    <div>
      <PageFilter
        left={[
          <Select
            key={1}
            placeholder="Талбараар"
            clearable
            data={[
              { value: "react", label: "Бүгд" },
              { value: "ng", label: "Түр төлөв" },
              { value: "svelte", label: "Бүртгэсэн" },
            ]}
          />,
          <Select
            key={2}
            placeholder="Категори"
            clearable
            data={[
              { value: "react", label: "React" },
              { value: "ng", label: "Angular" },
              { value: "svelte", label: "Svelte" },
              { value: "vue", label: "Vue" },
            ]}
          />,
        ]}
      />
      <br />
      <Table name="service.list" columns={columns} filters={filters} dataSource={[]} />
    </div>
  );
};

const useHeader = ({ onClick, itemStatus }: { onClick: (key: string, record: IService) => void; itemStatus: any }): ColumnType<IService>[] => [
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <RowAction
          onClick={(key) => onClick(key, record)}
          extra={{
            edit: "Засварлах",
            price: "Борлуулалтын үнэ",
            detail: "Дэлгэрэнүй",
          }}
        />
      );
    },
    width: "120px",
  },
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record.image ?? "-"} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => <Text size="sm">{record.refCode ?? "-"}</Text>,
  },
  {
    title: "SKU Code",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => <Text size="sm">{record.skuCode ?? "-"}</Text>,
  },
  {
    title: "Ажил үйлчилгээ",
    sorter: true,
    dataIndex: "mon",
    render: (record) => <Text size="sm">{record.nameMon}</Text>,
  },
  {
    title: "Категори",
    sorter: true,
    dataIndex: "category",
    render: (record) => <Text size="sm">{record?.category?.name}</Text>,
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "departmentUnitId",
    render: (record) => ``,
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "itemStatus",
    render: (record) => {
      return (
        <Badge color={itemStatus.find((item: IReference) => item.code === record.itemStatus)?.color || "gray"}>
          {itemStatus.find((item: IReference) => item.code === record.itemStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Үлдэгдэл",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => `${record?.quantity || "-"}`,
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => <PopoverText children={record?.description || ""} />,
  },
];
