import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string }) => {
  return httpRequest.post("/erp/distributor", data);
};

export const select = async (data: { query: string; limit?: number }) => {
  return httpRequest.get("/erp/distributor/select", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/distributor", { offset, filter });
};

export const update = async (id: string, data: { name: string }) => {
  return httpRequest.put(`/erp/distributor/${id}`, data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/distributor/${id}`);
};
