import { Paper } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { PageLayout } from "../../components/layout";

export const NewPolicy = () => {
  const { theme } = useStyle();
  const breadcrumbs = useBreadcrumb();

  return (
    <PageLayout title="Шинэ бүртгэл хийх" breadcrumb={breadcrumbs} bgColor={theme.colors.gray[0]}>
      <Paper radius="sm" p="md" withBorder></Paper>
    </PageLayout>
  );
};

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/policy",
    label: "Үнийн бодлого",
  },
  {
    label: "Үнэ нэмэх",
  },
];
