import { Divider, Tabs } from "@mantine/core";
import React from "react";
import { PageLayout } from "../../components/layout";
import { GroupPriceList } from "../../components/policy/group-price-list";
import { SpecialList } from "../../components/policy/special-list";
import { StandartPriceHistoryList } from "../../components/policy/standart-price-history-list";

export const PolicyList = () => {
  const [tabKey, setTabKey] = React.useState<string>("1");

  const breadcrumbs = useBreadcrumb();

  return (
    <PageLayout title={"Стандарт үнийн бодлого"} subTitle="Бүх бараа бүтээгдэхүүний өнөөдөр идэвхтэй байгаа стандарт үнийн жагсаалт" breadcrumb={breadcrumbs}>
      <Tabs
        onChange={(e: string | null) => {
          if (e) setTabKey(e);
        }}
        variant="pills"
        defaultValue={tabKey}>
        <Tabs.List>
          <Tabs.Tab value="1">Стандарт үнэ</Tabs.Tab>
          <Tabs.Tab value="2">Стандарт үнийн түүх</Tabs.Tab>
          <Tabs.Tab value="3">Тусгай үнэ</Tabs.Tab>
        </Tabs.List>
        <Divider mt="md" mb="md" />

        <Tabs.Panel value="1">{tabKey === "1" && <GroupPriceList />}</Tabs.Panel>
        <Tabs.Panel value="2">{tabKey === "2" && <StandartPriceHistoryList />}</Tabs.Panel>
        <Tabs.Panel value="3">{tabKey === "3" && <SpecialList />}</Tabs.Panel>
      </Tabs>

      <Divider mt="md" mb="md" />
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Үнийн бодлого",
  },
];
