import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const warehouseAssign = async (data: any) => {
  return httpRequest.put("/erp/stock/warehouse_assign", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/stock", { offset, filter });
};

export const history = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/stock/history", { offset, filter });
};

export const variant_select = async (data: {
  warehouseId: null | string;
  warehouseUserId: null | string;
  categoryId: null | string;
  subCategoryId: null | string;
  name: null | string;
  limit: number;
}) => {
  return httpRequest.get("/erp/stock/variant_select", data);
};
