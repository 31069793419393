import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export type ITypeSetPrice = {
  priceGroupType: string | null;
  priceGroupId: string | null;
  priceTierNo: number | null;
  priceStartDate: string | null;
  priceEndDate: string | null;
  businessIds: string[];
  confirm?: boolean;
};

export const list = async (data: any) => {
  return httpRequest.get("/erp/network", data);
};

export const setPrice = async (data: ITypeSetPrice) => {
  return httpRequest.put(`/erp/network/set_price`, data);
};
