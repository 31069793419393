import { ActionIcon, Alert, Avatar, Button, Flex, Grid, LoadingOverlay, Modal, Text, Title } from "@mantine/core";
import { IconCalendar, IconCheck, IconEdit, IconPhoto, IconPlus, IconTag, IconTrash, IconX } from "@tabler/icons-react";
import React, { useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { Dialog } from "../../utils/confirm-modal";
import { Form, IFormRef } from "../form";
import { NumberField } from "../form/number-field";
import { ColumnType, Table } from "../table";

import { PriceGroupApi } from "../../apis";
import { dateFormat } from "../../utils/date";
import { message } from "../../utils/message";
import { FormLayout } from "../layout";
import { GoodSetPriceForm } from "./good-set-price-form";
import { GroupVariantSelect } from "./group-variant-select";

type Props = {
  initData?: any;
  tableRef: any;
};

export const DynamicFieldForm = React.forwardRef(({ initData, tableRef }: Props, ref: React.Ref<any>) => {
  const formRef = React.useRef<IFormRef<{ fields: any }>>(null);
  const [action, setAction] = React.useState<any>([]);
  const [variants, setVariants] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<any>({ limit: 10, page: 1 });
  const { perTypes, basePriceTypes, priceChangeTypes, currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const [data, setFormData] = React.useState<{ fields: any }>({
    fields: initData?.unitVariants?.map((c: any) => ({
      barCode: c?.barCode,
      erpCode: c?.erpCode,
      image: c?.image,
      unitVariantId: c?.unitVariantId,
      nameMon: c?.nameMon,
      refCode: c?.refCode,
      skuCode: c?.skuCode,
      unitName: c?.unitName,
      standardPrice: c?.standardPrice,
      isPriceSet: c?.isPriceSet,
      priceEndDate: c?.priceEndDate,
      priceStartDate: c?.priceStartDate,
      variantId: c?.variantId,
      tier1Price: c?.tiers?.find((f: any) => f?.tierNo === 1)?.price,
      tier2Price: c?.tiers?.find((f: any) => f?.tierNo === 2)?.price,
      tier3Price: c?.tiers?.find((f: any) => f?.tierNo === 3)?.price,
      tier4Price: c?.tiers?.find((f: any) => f?.tierNo === 4)?.price,
      tier5Price: c?.tiers?.find((f: any) => f?.tierNo === 5)?.price,
      tier6Price: c?.tiers?.find((f: any) => f?.tierNo === 6)?.price,
      tier7Price: c?.tiers?.find((f: any) => f?.tierNo === 7)?.price,
      tier8Price: c?.tiers?.find((f: any) => f?.tierNo === 8)?.price,
      tier9Price: c?.tiers?.find((f: any) => f?.tierNo === 9)?.price,
      tier10Price: c?.tiers?.find((f: any) => f?.tierNo === 10)?.price,
    })),
  });

  const schema = yup.object({
    fields: yup.array().of(
      yup.object({
        tier1Price: yup.number().typeError("Зөвхөн үнэ байна").moreThan(0, "0-с их байна").required("Заавал бөглөнө"),
        tier2Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
        tier3Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
        tier4Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
        tier5Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
        tier6Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
        tier7Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
        tier8Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
        tier9Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
        tier10Price: yup.number().typeError("Зөвхөн үнэ байна").nullable(),
      }),
    ),
  });

  const columns = useHeader({
    action: action,
    basePriceTypes,
    perTypes,
    currencies,
    priceChangeTypes,
    setFormData,
    initData,
    fields: data.fields,

    onCreate: (option, index) => {
      const fields = (data?.fields || [])?.reduce((accumulator: any, iterator: any, i: any) => {
        if (i === index) {
          return [
            ...accumulator,
            {
              ...iterator,
              values: [...(iterator.values || []), {}],
            },
          ];
        }

        return [...accumulator, iterator];
      }, [] as any);

      setFormData({
        fields: fields,
      });
    },

    onClick: async (key, record, index) => {
      switch (key) {
        case "cancel": {
          const errors = await formRef.current?.validate();

          if (errors && Object.keys(errors).length > 0) {
            formRef.current?.setFormData((state) => ({
              fields: state?.fields?.filter((_i: any, i: any) => i !== index),
            }));

            setAction([]);
          } else setAction([]);

          break;
        }
        case "edit": {
          setAction(["edit", `${index}`]);
          break;
        }
        case "save": {
          const errors = await formRef.current?.validate();

          if (errors && Object.keys(errors).length === 0) {
            setAction([]);
          }

          break;
        }
        case "fix": {
          setAction(["fix", record as any, index, record?.isPriceSet ? "changeDate" : "setPrice"]);

          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", (key) => {
            switch (key) {
              case "confirm": {
                formRef.current?.setFormData((state) => ({
                  fields: state?.fields?.filter((_i: any, i: any) => i !== index),
                }));
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  useImperativeHandle(ref, () => ({
    submit() {
      return formRef.current?.submit();
    },
  }));

  const selectVariants = async (query: string) => {
    try {
      const variants = await PriceGroupApi.variants(initData?.id as string, { filter: { query: query }, offset: filter });
      setVariants(variants);
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const deleteItem = (index: number) => {
    formRef.current?.setFormData((state) => ({
      fields: state?.fields?.filter((_i: any, i: number) => i !== index),
    }));
    setAction([]);
  };

  const updateVariant = async () => {
    setLoading(true);
    try {
      await PriceGroupApi.updateVariant({ unitVariantId: action[1]?.unitVariantId, date: action[1]?.priceStartDate });
      setAction([]);
      setLoading(false);
      tableRef.current.reload();
      message.success("Огноог амжилттай шинэчиллээ");
    } catch (error: any) {
      message.error(error?.message || "Хүсэлт амжилтгүй");
      setLoading(false);
    }
  };

  return (
    <Form ref={formRef} validationSchema={schema} initialValues={data}>
      {({ values }) => {
        const addToList = (ids: any) => {
          formRef.current?.setFormData((state) => ({
            fields: (values?.fields || [])?.concat(
              variants?.rows
                ?.filter((f: any) => ids?.includes(f?.id))
                ?.map((c: any) => ({
                  barCode: c?.barCode,
                  erpCode: c?.erpCode,
                  image: c?.image,
                  nameMon: c?.nameMon,
                  refCode: c?.refCode,
                  skuCode: c?.skuCode,
                  unitName: c?.unitName,
                  standardPrice: c?.standardPrice,
                  priceEndDate: c?.priceEndDate,
                  unitVariantId: c?.id,
                  isSet: c?.isSet,
                  isPriceSet: c?.isPriceSet,
                  needToFix: c?.needToFix,
                  priceStartDate: c?.priceStartDate,
                  variantId: c?.variantId,
                  tier1Price: c?.tiers?.find((f: any) => f?.tierNo === 1)?.price,
                  tier2Price: c?.tiers?.find((f: any) => f?.tierNo === 2)?.price,
                  tier3Price: c?.tiers?.find((f: any) => f?.tierNo === 3)?.price,
                  tier4Price: c?.tiers?.find((f: any) => f?.tierNo === 4)?.price,
                  tier5Price: c?.tiers?.find((f: any) => f?.tierNo === 5)?.price,
                  tier6Price: c?.tiers?.find((f: any) => f?.tierNo === 6)?.price,
                  tier7Price: c?.tiers?.find((f: any) => f?.tierNo === 7)?.price,
                  tier8Price: c?.tiers?.find((f: any) => f?.tierNo === 8)?.price,
                  tier9Price: c?.tiers?.find((f: any) => f?.tierNo === 9)?.price,
                  tier10Price: c?.tiers?.find((f: any) => f?.tierNo === 10)?.price,
                })),
            ),
          }));
        };

        return (
          <>
            <Flex direction="row" justify="space-between" align="center" mb="md">
              <Title size="md" c="dark.3">
                Тооноос хамаарах стандарт үнэ
              </Title>
              <Button
                variant="light"
                size="xs"
                onClick={() => {
                  setAction(["select"]);
                  selectVariants("");
                }}
                disabled={action[0] === "edit"}>
                <IconPlus size={20} /> Шинэ талбар
              </Button>
            </Flex>

            <Grid>
              <Grid.Col span={12}>
                <Table ref={tableRef} minWidth={2014} name="product.sellingprice.list.fields" columns={columns} dataSource={values.fields} pagination={false} />
              </Grid.Col>

              <Modal size="50%" centered withCloseButton={false} opened={action[0] === "select"} onClose={() => setAction([])}>
                <GroupVariantSelect
                  filter={filter}
                  addItemToList={addToList}
                  setFilter={() => {
                    setFilter((state: any) => ({ ...state }));
                  }}
                  selectVariants={selectVariants}
                  selected={values?.fields?.map((c: any) => c?.unitVariantId)}
                  data={variants?.rows}
                  onClose={() => setAction([])}
                />
              </Modal>

              <Modal size={action[4] ? "60%" : "40%"} centered withCloseButton={false} opened={action[0] === "fix"} onClose={() => setAction([])}>
                {!action[4] ? (
                  <FormLayout
                    title="Анхааруулга"
                    extra={[
                      <ActionIcon onClick={() => setAction([])} key={1}>
                        <IconX />
                      </ActionIcon>,
                    ]}>
                    <LoadingOverlay visible={loading} />
                    <Flex direction="column" gap="sm">
                      <Alert
                        styles={{ root: { backgroundColor: "#FCDDEC" }, message: { color: "#44566c", fontWeight: 500 } }}
                        radius="md"
                        color="red"
                        withCloseButton={false}>
                        <Text ta="center">
                          {action[3] === "setPrice"
                            ? "Барааны стандарт үнийг оруулаагүй байна. Та барааны стандарт үнийг оруулах эсвэл барааг жагсаалтаас хасна уу!"
                            : "Барааны үнэ идэвхжих огноо нь өмнөх барааны үнэ дуусах огноотой давхцаж байна. Та өмнөх барааны үнэ дуусах хугацааг барааны үнэ идэвхжих огноогоор солих  эсвэл барааг жагсаалтаас хасна уу!"}
                        </Text>
                      </Alert>
                    </Flex>
                    <Flex mt={30} justify="space-between">
                      <Button leftSection={<IconTrash size={20} />} onClick={() => deleteItem(action[2])} variant="outline" color="red">
                        Жагсаалтаас хасах
                      </Button>
                      <Button
                        leftSection={action[3] === "setPrice" ? <IconTag size={20} /> : <IconCalendar size={20} />}
                        onClick={() => (action[3] === "setPrice" ? setAction(["fix", action[1], action[2], action[3], true]) : updateVariant())}>
                        {action[3] === "setPrice" ? "   Үнэ тохируулах" : "Огноог солих"}
                      </Button>
                    </Flex>
                  </FormLayout>
                ) : (
                  <>
                    {action[3] === "setPrice" ? (
                      <GoodSetPriceForm reloadTable={() => tableRef.current.reload()} action={action} onClose={() => setAction([])} />
                    ) : null}
                  </>
                )}
              </Modal>
            </Grid>
          </>
        );
      }}
    </Form>
  );
});

type HeaderProps = {
  onClick: (key: string, record: any, index: number) => void;
  basePriceTypes: any;
  action: string[];
  fields: any;
  currencies: any;
  priceChangeTypes: any;
  perTypes: any;
  initData: any;
  setFormData: any;
  onCreate: (option: { label: string; value: string }, index: number) => void;
};

const useHeader = ({ action, initData, onClick }: HeaderProps): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Price Code",
    dataIndex: "refCode",
    width: 180,
    render: (record, index) => {
      return record.refCode || "-";
    },
  },
  {
    title: "Зураг",
    dataIndex: "image",
    width: 160,
    render: (record, index) => {
      return (
        <Avatar src={record?.image} size="md">
          <IconPhoto />
        </Avatar>
      );
    },
  },
  {
    title: "Нэр",
    dataIndex: "nameMon",
    width: 260,
    render: (record, index) => {
      return record?.nameMon || "-";
    },
  },
  {
    title: "DeHub Code",
    dataIndex: "refCode",
    width: 240,
    render: (record, index) => {
      return record?.refCode || "-";
    },
  },
  {
    title: "SKU Code",
    dataIndex: "skuCode",
    width: 140,
    render: (record, index) => {
      return record?.skuCode || "-";
    },
  },
  {
    title: "ERP Code",
    dataIndex: "erpCode",
    width: 140,
    render: (record, index) => {
      return record?.erpCode || "-";
    },
  },
  {
    title: "Борлуулах нэгж",
    dataIndex: "unitName",
    width: 180,
    render: (record, index) => {
      return record.unitName || "-";
    },
  },

  {
    title: "Стандарт үнэ",
    dataIndex: "standardPrice",
    width: 180,
    render: (record, index) => {
      return record.standardPrice || "-";
    },
  },
  {
    title: "Tier 1 үнэ",
    dataIndex: "tier1Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 1)?.isSet}
            name={`fields[${index}].tier1Price`}
            placeholder="Минимум тоо"
            noError
          />
        );
      return record.tier1Price || "-";
    },
  },
  {
    title: "Tier 2 үнэ",
    dataIndex: "tier2Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 2)?.isSet}
            name={`fields[${index}].tier2Price`}
            placeholder="Минимум тоо"
            noError
          />
        );
      return record.tier2Price || "-";
    },
  },
  {
    title: "Tier 3 үнэ",
    dataIndex: "tier3Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 3)?.isSet}
            name={`fields[${index}].tier3Price`}
            placeholder="Минимум тоо"
            noError
          />
        );

      return record.tier3Price || "-";
    },
  },
  {
    title: "Tier 4 үнэ",
    dataIndex: "tier4Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 4)?.isSet}
            name={`fields[${index}].tier4Price`}
            placeholder="Минимум тоо"
            noError
          />
        );

      return record.tier4Price || "-";
    },
  },
  {
    title: "Tier 5 үнэ",
    dataIndex: "tier5Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 5)?.isSet}
            name={`fields[${index}].tier5Price`}
            placeholder="Минимум тоо"
            noError
          />
        );

      return record.tier5Price || "-";
    },
  },
  {
    title: "Tier 6 үнэ",
    dataIndex: "tier6Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 6)?.isSet}
            name={`fields[${index}].tier6Price`}
            placeholder="Минимум тоо"
            noError
          />
        );

      return record.tier6Price || "-";
    },
  },
  {
    title: "Tier 7 үнэ",
    dataIndex: "tier7Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 7)?.isSet}
            name={`fields[${index}].tier7Price`}
            placeholder="Минимум тоо"
            noError
          />
        );

      return record.tier7Price || "-";
    },
  },
  {
    title: "Tier 8 үнэ",
    dataIndex: "tier8Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 8)?.isSet}
            name={`fields[${index}].tier8Price`}
            placeholder="Минимум тоо"
            noError
          />
        );
      return record.tier8Price || "-";
    },
  },
  {
    title: "Tier 9 үнэ",
    dataIndex: "tier9Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 9)?.isSet}
            name={`fields[${index}].tier9Price`}
            placeholder="Минимум тоо"
            noError
          />
        );

      return record.tier9Price || "-";
    },
  },
  {
    title: "Tier 10 үнэ",
    dataIndex: "tier10Price",
    width: 180,
    render: (record, index) => {
      if (action[1] === `${index}`)
        return (
          <NumberField
            disabled={!initData?.unitVariants?.find((f: any) => f?.unitVariantId === record?.unitVariantId)?.tiers?.find((f: any) => f?.tierNo === 10)?.isSet}
            name={`fields[${index}].tier10Price`}
            placeholder="Минимум тоо"
            noError
          />
        );

      return record.tier10Price || "-";
    },
  },

  {
    title: "Засвар",
    dataIndex: "needToFix",
    width: 180,
    render: (record, index) => {
      return record.needToFix ? (
        <Button disabled={action[0] === "edit"} onClick={() => onClick("fix", record, index)} variant="outline" size="xs" color="red">
          Need fix
        </Button>
      ) : (
        <Button sx={{ "&:hover": { backgroundColor: "unset" } }} variant="subtle" size="xs" color="green">
          Okay
        </Button>
      );
    },
  },
  {
    title: "Дуусах огноо",
    dataIndex: "endDate",
    width: 240,
    render: (record, index) => {
      return record?.priceEndDate ? dateFormat(record?.priceEndDate) : "-";
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record, index) => {
      if (action[0] === "edit" && action[1] === `${index}`)
        return (
          <Flex gap="sm">
            <Button variant="filled" radius={100} w={30} h={30} p={0} onClick={() => onClick("save", record, index)}>
              <IconCheck />
            </Button>

            <Button variant="default" radius={100} w={30} h={30} p={0} onClick={() => onClick("cancel", record, index)}>
              <IconX />
            </Button>
          </Flex>
        );

      return (
        <Flex gap="sm">
          <Button disabled={action[0] === "edit"} variant="light" radius={100} w={30} h={30} p={0} onClick={() => onClick("edit", record, index)}>
            <IconEdit />
          </Button>
          <Button disabled={action[0] === "edit"} variant="light" color="red" radius={100} w={30} h={30} p={0} onClick={() => onClick("remove", record, index)}>
            <IconTrash />
          </Button>
        </Flex>
      );
    },
    width: "120px",
  },
];
