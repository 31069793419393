import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const add = async (data: any) => {
  return httpRequest.post("/erp/service/variant", data);
};

export const additional = async (data: any) => {
  return httpRequest.post("/erp/service/variant/additional", data);
};

export const remove = async (id: any) => {
  return httpRequest.del(`/erp/service/variant/${id}`);
};
