import { ActionIcon, Avatar, Checkbox, Flex, Text } from "@mantine/core";
import { IconPhoto, IconTrash } from "@tabler/icons-react";
import { ColumnType, Table } from "../table";

export function PackageListForm({ setFieldValue, values }: { setFieldValue: (e1: string, e2: any) => void; values: any }) {
  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "remove":
          setFieldValue("values", []);
          break;

        default:
          break;
      }
    },
  });

  return <Table name="price.change.information" columns={columns} dataSource={values.list || []} />;
}

const useHeader = ({ onClick }: { onClick: (key: string, record: any) => void }): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    render: (record, index) => (
      <>
        <Flex align={"center"} gap={"xs"}>
          <ActionIcon onClick={() => onClick("remove", record)} variant="light" color="">
            <IconTrash size={"1.2rem"} />
          </ActionIcon>
        </Flex>
      </>
    ),
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record.image}>
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Код #",
    sorter: true,
    dataIndex: "nameMon",
    render: (record) => <Text w={"max-content"}>{record?.nameMon || "-"}</Text>,
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => `${record?.skuCode || "-"}`,
  },
  {
    title: "Bar code",
    sorter: true,
    dataIndex: "unitName",
    render: (record) => `${record?.unitName || "-"}`,
  },
  {
    title: "Барааны нэр",
    sorter: true,
    dataIndex: "isBaseUnit",
    render: (record) => <Checkbox checked={record.isBaseUnit || false} readOnly />,
  },
  {
    title: "Багцад орох тоо",
    sorter: true,
    dataIndex: "unitConvertValue",
    render: (record) => record?.unitConvertValue || 0,
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unit",
    render: (record) => "-",
  },
  {
    title: "Нэгжийн үнэ",
    sorter: true,
    dataIndex: "unit",
    render: (record) => "-",
  },
];
