import { Checkbox, Flex, Input } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  direction?: "row" | "column";
  children?: string;
  onChangeCustom?: (e: any) => void;
  required?: boolean;
  data: {
    label: string;
    value: string;
  }[];
  disabled?: boolean;
};

export function MultiCheckboxField({ name, label, direction, onChangeCustom, required = false, data = [], disabled }: Props) {
  const { value, onChange, error } = useField(name);

  return (
    <Input.Wrapper label={label} required={required} error={error}>
      <Flex mt={"sm"} columnGap={"md"} direction={direction}>
        {data.map(
          (
            item: {
              label: string;
              value: string;
            },
            index: number,
          ) => {
            return (
              <div key={index}>
                <Checkbox
                  disabled={disabled}
                  error={!!error}
                  checked={item.value === value}
                  label={item.label}
                  onChange={(e) => {
                    onChange(item.value);
                    onChangeCustom && onChangeCustom(item.value);
                  }}
                />
              </div>
            );
          },
        )}
      </Flex>
    </Input.Wrapper>
  );
}
