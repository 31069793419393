import { NumberInput } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useField } from ".";

type NumberFieldManyProps = {
  names: string[];
  noError?: boolean;
  placeholders: string[];
  rightSection?: React.ReactNode;
  required?: boolean;
};

const NumberFieldMany = React.memo(({ names, placeholders, rightSection, required = false }: NumberFieldManyProps) => {
  const { classes } = useStyle();

  return (
    <div className={classes.container}>
      <label className="mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2" id="mantine-r11-label">
        Үндсэн нэгжийн эзлэхүүн
        <span className="mantine-u5apz8 mantine-InputWrapper-required mantine-TextInput-required" aria-hidden="true">
          {" "}
          {required && "*"}
        </span>
      </label>
      <div className={classes.fieldContainer}>
        {names.map((name: string, index: number) => {
          return (
            <div className={classes.field} key={index}>
              <NumberField name={name} placeholder={placeholders[index]} errorCallback={(e) => {}} />
            </div>
          );
        })}

        {rightSection}
      </div>
    </div>
  );
});

type NumberFieldsProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  noError?: boolean;
  errorCallback: (error: string) => void;
};

const NumberField = React.memo(({ name, label, noError = false, placeholder, disabled = false, errorCallback }: NumberFieldsProps) => {
  const { classes } = useStyle();
  const { value, error, onChange } = useField(name);

  React.useEffect(() => {
    errorCallback(error);
  }, [error, errorCallback]);

  return (
    <NumberInput
      disabled={disabled}
      error={!!error}
      value={value}
      onChange={(v) => onChange(v)}
      placeholder={placeholder}
      hideControls={true}
      classNames={{
        input: classes.input,
      }}
    />
  );
});

NumberFieldMany.displayName = "NumberFieldMany";
NumberField.displayName = "NumberField";

const useStyle = createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: 2,
  },
  fieldContainer: {
    display: "flex",
    borderRadius: 4,
    flexDirection: "row",
    border: "1px solid #ced4da",
  },
  field: {
    flex: 1,
  },
  input: {
    border: "none",
  },
}));

export { NumberFieldMany };
