import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const merchStaff = async (data: { query: string; limit?: number }) => {
  return httpRequest.get(`/erp/warehouse/staff_select`, data);
};

export const select = async (data: { query: string; limit?: number }) => {
  return httpRequest.get(`/erp/warehouse/select`, data);
};
