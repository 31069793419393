import { Button, Checkbox, Flex, Group, Input, LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { ServiceApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import ServiceOrderSettingsTab from "../../components/service-order-settings-tab";
import { ServiceMainForm } from "../../components/service/main-form";
import { ServiceAdditionalInfomationForm } from "../../components/service/service-additional-information-form";
import { ServiceOrderSettingsForm } from "../../components/service/service-order-settings-form";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

const EditService = React.memo(() => {
  const params = useParams();
  const { id, index } = params;
  const { theme } = useStyle();
  const breadcrumbs = useBreadcrumb();
  const [tabIndex, setTabIndex] = React.useState(index || "1");
  const navigate = useNavigate();
  const { data, mutate, isLoading: loadData, isValidating } = useSwr<any>(`/api/service/${id}`, async () => await ServiceApi.get(`${id}`), {});
  const orderSettingsFormRef = useRef<any>(null);
  const orderUnitFormRef = useRef<any>();
  const orderDeliveryFormRef = useRef<any>();
  const orderAdditionalUnitFormRef = useRef<any>();
  const [hasVariant, setHasVariant] = useState<boolean>(false);
  const [hasAdditionalUnit, setHasAdditionalUnit] = useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(false);

  const onMainSubmit = async (data: any) => {
    setLoading(true);

    try {
      await ServiceApi.update(data.id, {
        skuCode: data.skuCode,
        barCode: data.barCode,
        erpCode: data.erpCode,
        nameMon: data.nameMon,
        nameEng: data.nameEng,
        nameBill: data.nameBill,
        nameWeb: data.nameWeb,
        nameApp: data.nameApp,
        brandId: data.brandId,
        supplierId: data.supplierId,
        manufacturerId: data.manufacturerId,
        originCountry: data.originCountry,
        importerCountry: data.importerCountry,
        distributorId: data.distributorId,
        itemTypeId: data.itemTypeId,
        classificationId: data.classificationId,
        subClassificationId: data.subClassificationId,
        categoryId: data.categoryId,
        subCategoryId: data.subCategoryId,
        tagId: data.tagId,
        description: data.description,
        coverImages: data?.coverImages || [
          {
            isMain: true,
            url: "/",
          },
        ],
        detailImages: data?.detailImages || ["/"],
      });
      setLoading(false);
      message.success("Амжилттай бараа засагдлаа!");
      await mutate();
      onTabChange("2");
    } catch (err) {
      setLoading(false);
      message.error((err as HttpHandler)?.message!);
    }
  };

  const onTabChange = (_index: any) => {
    navigate(`/service/edit/${data.id}/${_index}`);
    setTabIndex(_index);
  };

  useEffect(() => {
    setHasVariant(data?.hasVariant || false);
    setHasAdditionalUnit(data?.hasAdditionalUnit || false);
  }, [data, isLoading]);

  useEffect(() => {
    if (tabIndex === "4") mutate();
  }, [tabIndex, mutate]);

  const onSubmitNoVariant = async (isCompleted: boolean) => {
    try {
      let data = await orderSettingsFormRef.current.submit();
      if (data) {
        await ServiceApi.updateVariant(id!, {
          hasVariant: false,
          baseUnitId: data?.baseUnitId,
          unitId: data?.baseUnitId,
          departmentUnitId: data?.departmentUnitId,
          departmentSubUnitId: data?.departmentSubUnitId,
          hasAdditionalUnit: data?.hasAdditionalUnit,
          additionalUnits: data?.additionalUnits?.map((item: any) => {
            return {
              parentId: item?.parentId,
              unitId: item?.unitId,
              convertType: item?.convertType,
              convertValue: item?.convertValue || 0,
              floatValue: item?.floatValue || 0,
            };
          }),
          isCompleted: isCompleted,
        });
        message.success("Амжилттай хадгаллаа!");

        if (isCompleted) navigate("/product");
        else await mutate();
      } else message.error("Талбарыг гүйцэт бөглөнө үү!");
    } catch (error) {
      message.error((error as HttpHandler)?.message || "Хүсэлт амжилтгүй!");
    }
  };

  const onSubmitVariant = async (isCompleted: boolean) => {
    try {
      let data = await orderSettingsFormRef.current.submit();
      if (data) {
        await ServiceApi.updateVariant(id!, {
          hasVariant: true,
          baseUnitId: data?.baseUnitId,
          departmentUnitId: data?.departmentUnitId,
          departmentSubUnitId: data?.departmentSubUnitId,
          hasAdditionalUnit: data?.hasAdditionalUnit || false,
          additionalUnits: data?.additionalUnits || [],
          isCompleted: isCompleted,
        });
        message.success("Амжилттай хадгаллаа!");
        if (isCompleted) navigate("/product");
        else await mutate();
      } else message.error("Талбарыг гүйцэт бөглөнө үү!");
    } catch (error) {
      message.error((error as HttpHandler)?.message || "Хүсэлт амжилтгүй!");
    }
  };

  if (!data && loadData && isValidating)
    return (
      <div>
        <LoadingOverlay visible />
      </div>
    );

  return (
    <PageLayout title="Шинэ бүртгэл засах" breadcrumb={breadcrumbs} bgColor={theme.colors.gray[0]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={tabIndex} onChange={onTabChange}>
          <Tabs.List>
            <Tabs.Tab value="1">Үндсэн мэдээлэл</Tabs.Tab>
            <Tabs.Tab value="2">Нэмэлт мэдээлэл</Tabs.Tab>
            <Tabs.Tab value="3">Захиалгийн тохиргоо</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="1">
            {tabIndex === "1" && <ServiceMainForm loading={isLoading} onSubmit={onMainSubmit} editable={["update", data || {}]} />}
          </Tabs.Panel>
          <Tabs.Panel value="2">
            {tabIndex === "2" && (
              <ServiceAdditionalInfomationForm reload={() => mutate()} tabChange={() => onTabChange("3")} editable={["update", data || {}]} />
            )}
          </Tabs.Panel>
          <Tabs.Panel value="3">
            <Paper my={"lg"}>
              <Group align="center">
                <Text fw={600} fz={"lg"}>
                  Хэмжих нэгж болон хэмжээс
                </Text>
                <Group>
                  <Button
                    onClick={() => {
                      if (!hasVariant) onSubmitNoVariant(false);
                      else onSubmitVariant(false);
                    }}
                    variant="default">
                    Хадгалах
                  </Button>
                  <Button
                    onClick={() => {
                      if (!hasVariant) onSubmitNoVariant(true);
                      else onSubmitVariant(true);
                    }}>
                    Бүртгэл дуусгах
                  </Button>
                </Group>
              </Group>
              <ServiceOrderSettingsForm
                orderSettingsFormRef={orderSettingsFormRef}
                editable={["update", { ...data, hasVariant: false }]}
                productRefCode={data?.refCode}
              />
              <Input.Wrapper label="Хувилбартай эсэх" required>
                <Flex gap={18} align={"center"} my={"sm"}>
                  <Checkbox
                    disabled={data?.variantSelected}
                    onChange={() => {
                      setHasVariant(true);
                    }}
                    checked={hasVariant}
                    label="Хувилбартай"
                  />
                  <Checkbox
                    disabled={data?.variantSelected}
                    onChange={() => {
                      setHasVariant(false);
                    }}
                    checked={!hasVariant}
                    label="Хувилбаргүй"
                  />
                </Flex>
              </Input.Wrapper>
              {tabIndex === "3" &&
                (hasVariant ? (
                  <ServiceOrderSettingsTab
                    onTabChane={(e) => onTabChange(e)}
                    orderDeliveryFormRef={orderDeliveryFormRef}
                    orderUnitFormRef={orderUnitFormRef}
                    orderAdditionalUnitFormRef={orderAdditionalUnitFormRef}
                    hasAdditionalUnit={hasAdditionalUnit}
                    setHasAdditionalUnit={setHasAdditionalUnit}
                  />
                ) : (
                  ""
                ))}
            </Paper>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </PageLayout>
  );
});

EditService.displayName = "EditProduct";

const useStyle = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/product",
    label: "Бүтээгдэхүүн",
  },
  {
    label: "Бараа нэмэх",
  },
];

export { EditService };
