import { MantineSize, TextInput } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  noError?: boolean;
  rightSection?: React.ReactNode;
  classNames?: Partial<Record<string, string>> | undefined;
  w?: any;
  hasError?: boolean;
  type?: string;
  size?: MantineSize | any;
  rightSectionWidth?: any;
  icon?: any;
  required?: boolean;
  defaultValue?: string;
};

export function TextField({
  name,
  label,
  noError = false,
  placeholder,
  disabled = false,
  rightSection,
  classNames,
  w,
  hasError,
  type,
  size,
  rightSectionWidth,
  icon,
  required = false,
  defaultValue,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <TextInput
      leftSection={icon}
      rightSectionWidth={rightSectionWidth}
      size={size}
      w={w}
      type={type}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={hasError ? !!error : error}
      value={value}
      defaultValue={defaultValue}
      rightSection={rightSection}
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
      classNames={classNames}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: noError && error && 0,
        },
      }}
      required={required}
    />
  );
}
