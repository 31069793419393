import { IFieldType } from "../models/General";
import { HttpRequest } from "../utils";
import { IFilter } from "./../interfaces/IFilter";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string; description: string; type: string; itemTypeId: string; parentId?: string }) => {
  return httpRequest.post("/erp/category", data);
};

export const sub = async (data: { name: string; description: string; itemTypeId: string; parentId?: string }) => {
  return httpRequest.post("/erp/category/sub", data);
};

export const createSub = async (data: {
  name: string;
  description: string;
  itemTypeId?: string;
  parentId?: string;
  categoryFields: {
    name: string;
    type: IFieldType;
    numberUnit: string;
    values: {
      name: string;
      isDefault: boolean;
    }[];
  }[];
}) => {
  return httpRequest.post("/erp/category/sub", data);
};

export const updateSub = async (id: string, data: any) => {
  return httpRequest.put(`/erp/category/${id}`, data);
};

export const select = async (data?: { type?: string; itemTypeId?: string; query: string }) => {
  return httpRequest.get("/erp/category/select", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/category", { offset, filter });
};

export const update = async (id: string, data: { name: string; description: string; type?: string; itemTypeId?: string; parentId?: string }) => {
  return httpRequest.put(`/erp/category/${id}`, data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/category/${id}`);
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/category/${id}`);
};
