import { Button, CloseButton, Grid, Group, Input, LoadingOverlay } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { SuppApi } from "../../apis";
import { IBrand } from "../../interfaces/IBrand";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { usePermission } from "../permission";

const schema = yup.object({
  businessId: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  action: string[];
  payload?: IBrand;
  reload?: any;
  dataSource: any;
};

type IFormData = {
  businessId: string;
  description: string;
};

export function SuppFetchForm({ onCancel, action, reload, dataSource }: Props) {
  const { user } = useSelector((state: { auth: any }) => state.auth);
  const { supplierBusinesses } = useSelector((state: { general: IGeneral }) => state.general);
  const ERP_STORE_FETCH = usePermission("ERP_STORE_FETCH");

  const [loading, setLoading] = React.useState(false);

  const [data] = React.useState<IFormData | any>({
    businessId: "",
    description: "",
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      await SuppApi.fetch(`${dataSource.id}`, data);
      message.success("Үйлдэл амжилттай.");
      onCancel && onCancel();
      reload();
    } catch (error: any) {
      message.error(error.message ?? "Үйлдэл амжилтгүй");
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout title="Борлуулах бараанд нэмэх" extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            {ERP_STORE_FETCH.hasAccess ? (
              <>
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Худалдан авалт бизнес">
                      <Input value={user?.currentBusiness?.profileName || "-"} placeholder="авто" disabled readOnly />
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Бүртгэсэн ажилтан">
                      <Input value={`${user?.lastName ? user?.lastName[0] + ". " : ""} ${user?.firstName}`} placeholder="авто" disabled readOnly />
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <Input.Wrapper label="Бүртгэсэн огноо, цаг">
                      <DatePickerInput value={dataSource?.createdAt} placeholder="авто" disabled readOnly />
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <SelectField
                      options={supplierBusinesses?.map((item: any) => {
                        return {
                          label: item?.profileName,
                          value: item?.id,
                        };
                      })}
                      name="businessId"
                      label="Борлуулалт хийх бизнес"
                      placeholder="Сонгоно уу"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                    <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар оруулна уу" required />
                  </Grid.Col>
                </Grid>

                <Group justify="right" mt={20}>
                  <Button variant="default" onClick={() => onCancel && onCancel()}>
                    Болих
                  </Button>
                  <Button loading={loading} type="submit">
                    Хадгалах
                  </Button>
                </Group>
              </>
            ) : (
              ERP_STORE_FETCH.accessDenied()
            )}
          </FormLayout>
        );
      }}
    </Form>
  );
}
