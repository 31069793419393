import { Button, CloseButton, Divider, Grid, Group, LoadingOverlay } from "@mantine/core";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { CategoryApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { updateCategories } from "../../store/general";
import { HttpHandler } from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { DynamicFieldForm, IDynamicRef } from "./dynamic-field-form";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  itemTypeId: yup.string().required("Заавал бөглөнө!").nullable(),
  categoryId: yup.string().required("Заавал бөглөнө!").nullable(),
  classificationId: yup.string().required("Заавал бөглөнө!").nullable(),
  subClassificationId: yup.string().required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  action: any[];
  reload: any;
};

type IFormData = {
  name: string;
  description: string;
  itemTypeId: string;
  classificationId: string;
  subClassificationId: string;
  categoryId: string;
  categoryFields: any[];
};

export function ReferenceSubCategoryForm({ onCancel, action, reload }: Props) {
  const dispatch = useDispatch();
  const formRef = useRef<IFormRef<IFormData>>(null);
  const dynamicRef = useRef<IDynamicRef>(null);
  const [loading, setLoading] = React.useState(false);
  const { itemTypes, categories } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormData>({
    name: "",
    description: "",
    itemTypeId: "",
    classificationId: "",
    subClassificationId: "",
    categoryId: "",
    categoryFields: [],
    ...(action[1] && action[1]),
  });

  const onSubmit = async (data: IFormData) => {
    let valid = await dynamicRef.current?.submit();
    if (!valid) {
      message.error("Динамик талбарууд алдаатай байна!");
    } else {
      try {
        setLoading(true);

        if (action[1]) {
          await CategoryApi.updateSub(action[1].id, {
            name: data.name,
            description: data.description,
          });
        } else
          await CategoryApi.createSub({
            name: data.name,
            description: data.description,
            itemTypeId: data.itemTypeId,
            parentId: data.categoryId,
            categoryFields: (valid?.fields || [])
              .sort((a, b) => a.sort - b.sort)
              .map((field) => ({
                name: field.name!,
                type: field.type!,
                numberUnit: field.numberUnit!,
                values: (field.values || [])?.map((value: string) => ({
                  name: value,
                  isDefault: field.defaultValueId === value,
                })),
              })),
          });

        setLoading(false);

        message.success("Таны хүсэлт амжилттай!");

        onCancel && onCancel();

        let res = await CategoryApi.select();

        dispatch(updateCategories(res));
        await reload();
      } catch (err) {
        setLoading(false);

        message.error((err as HttpHandler)?.message!);
      }
    }
  };

  return (
    <FormLayout
      title="Дэд категорийн мэдээлэл"
      subTitle="Дэд категорийн нэр, тайлбар, талбаруудыг оруулна."
      my={0}
      extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <LoadingOverlay visible={loading} />
      <Form ref={formRef} validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values, setFieldValue }) => {
          return (
            <div>
              <Grid>
                <Grid.Col span={4}>
                  <SelectField
                    name="itemTypeId"
                    label="Нэр төрөл"
                    nothingFound="Нэр төрөл олдсонгүй"
                    placeholder="Нэр төрөл сонгох"
                    options={itemTypes.map((c) => ({ label: c.name, value: c.id }))}
                    disabled={action[1]}
                    onChange={() => {
                      setFieldValue("classificationId", null);
                      setFieldValue("subClassificationId", null);
                      setFieldValue("categoryId", null);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    nothingFound="Ангилал олдсонгүй"
                    disabled={action[1] || !values.itemTypeId}
                    name="classificationId"
                    label="Ангилал"
                    placeholder="Ангилал сонгох"
                    options={categories.filter((f: any) => f.type === "CLASSIFICATION").map((c) => ({ label: c.name, value: c.id }))}
                    onChange={() => {
                      setFieldValue("subClassificationId", null);
                      setFieldValue("categoryId", null);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    nothingFound="Дэд ангилал олдсонгүй"
                    disabled={action[1] || !values.classificationId}
                    name="subClassificationId"
                    label="Дэд ангилал"
                    placeholder="Дэд ангилал сонгох"
                    options={categories
                      .filter((f: any) => f.type === "SUB_CLASSIFICATION" && f.parentId === values.classificationId)
                      .map((c) => ({ label: c.name, value: c.id }))}
                    onChange={() => {
                      setFieldValue("categoryId", null);
                    }}
                  />
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Grid>
                <Grid.Col span={4}>
                  <SelectField
                    nothingFound="Категори олдсонгүй"
                    name="categoryId"
                    label="Категори"
                    placeholder="Категори сонгох"
                    options={categories
                      .filter((f: any) => f.type === "CATEGORY" && f.itemTypeId === values.itemTypeId && f.parentId === values.subClassificationId)
                      .map((c) => ({ label: c.name, value: c.id }))}
                    disabled={action[1] || !values.subClassificationId}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="name" label="Дэд категори нэр" placeholder="Дэд категори нэр" />
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Grid>
                <Grid.Col span={12}>
                  <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
                </Grid.Col>
              </Grid>
            </div>
          );
        }}
      </Form>

      <Divider my="lg" />

      <DynamicFieldForm ref={dynamicRef} init={action[1] && action[1]?.categoryFields} disabled={action[1]} />

      <Divider />

      <Group justify="right" mt={20}>
        <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
          Болих
        </Button>
        <Button key={2} onClick={() => formRef.current?.submit()}>
          Хадгалах
        </Button>
      </Group>
    </FormLayout>
  );
}
