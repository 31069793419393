import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const supplier = async (data: { query: string; limit?: number; buyerBusinessId: string | null; partnerId: string | null }) => {
  return httpRequest.get(`/erp/goods/warehouse/supplier`, data);
};

export const buyer = async (data: { query: string; limit?: number }) => {
  return httpRequest.get(`/erp/goods/warehouse/buyer`, data);
};

export const merchStaff = async (data: { query: string; limit?: number }) => {
  return httpRequest.get(`/erp/goods/warehouse/merch_staff`, data);
};

export const partner = async (data: { query: string; limit?: number; buyerBusinessId: string | null }) => {
  return httpRequest.get(`/erp/goods/warehouse/partner`, data);
};

export const select = async (data: { query: string; limit?: number }) => {
  return httpRequest.get(`/erp/goods/warehouse/select`, data);
};

export const warehouse = async (data: IGoodsWarehouse) => {
  return httpRequest.put("/erp/goods/warehouse", data);
};

export type IGoodsWarehouse = {
  supplierType: string | undefined | null;
  variantId: string | undefined | null;
  confirm: boolean;
  warehouseId: string | undefined | null;
  quantityStartDate: string | undefined | null;
  warehouseQuantity: number | undefined;
  minQuantity: number | undefined | null;
  noticeMinQuantity: number | undefined;
  noticeMaxQuantity: number | undefined;
  deactivateMinQuantity: number | undefined;
  minOrderQuantity: number | undefined | null;
  reOrderQuantityLevel: number | undefined;
  reOrderQuantity: number | undefined;
  merchStaffId: string | undefined | null;
  variantSuppliers: {
    id?: string | undefined | null;
    buyerBusinessId: string | undefined | null;
    supplierBusinessId: string | undefined | null;
    merchStaffId: string | undefined | null;
    isDefault: boolean;
    isDropshipping: boolean;
    partnerId?: string;
    backorderable: boolean;
  }[];
};
