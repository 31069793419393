import { Flex, MantineColor, Paper, SegmentedControl, Text } from "@mantine/core";

export const Segmented = ({
  label,
  value,
  onChange,
  data = [],
  disabled = false,
}: {
  label: string;
  value: string;
  onChange: (e: string) => void;
  data: {
    code: string;
    color?: MantineColor;
    name: string;
  }[];
  disabled?: boolean;
}) => {
  return (
    <div>
      <Text size={"xs"} c="gray">
        {label}
      </Text>
      <SegmentedControl
        disabled={disabled}
        value={value}
        size="xs"
        miw={"400px"}
        onChange={onChange}
        data={data.map(
          (
            item2: {
              code: string;
              color?: MantineColor;
              name: string;
            },
            index2: number,
          ) => {
            return {
              label: (
                <Flex justify={"center"} gap={"sm"} align={"center"} key={index2} miw={"120px"} px={"md"}>
                  {item2?.color && <Paper h={"7px"} w={"7px"} bg={item2.color}></Paper>}
                  <Text size={"xs"}>{item2.name}</Text>
                </Flex>
              ),
              value: item2.code,
            };
          },
        )}
      />
    </div>
  );
};
