import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  Grid,
  Group,
  Input,
  Modal,
  MultiSelect,
  Paper,
  ScrollArea,
  Select,
  Space,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { randomId } from "@mantine/hooks";
import { IconDeviceFloppy, IconEdit, IconPackage, IconPhoto, IconPlus, IconRowInsertBottom, IconSquareRoundedX, IconTrash, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { GeneralApi, OptionApi, ServiceVariantApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { initGeneral } from "../../store/general";
import { Dialog } from "../../utils/confirm-modal";
import { message } from "../../utils/message";
import TableHead from "./head";

const schema = yup.object().shape({
  hasVariant: yup.boolean().optional().nullable(),
  id: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  variants: yup.array().of(
    yup.object().shape({
      isEdit: yup.boolean().optional(),
      skuCode: yup
        .string()
        .nullable()
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required("Заавал бөглөнө!").nullable() : schema.notRequired();
        }),
      barCode: yup
        .string()
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required("Заавал бөглөнө!").nullable() : schema.notRequired();
        })
        .nullable()
        .optional(),
      erpCode: yup
        .string()
        .when("isEdit", (isEdit, schema) => {
          return isEdit ? schema.required("Заавал бөглөнө!").nullable() : schema.notRequired();
        })
        .nullable()
        .optional(),
    }),
  ),
});

const schema2 = yup.object().shape({
  action: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").nullable(),
  isGoods: yup.boolean().optional().nullable(),
  isService: yup.boolean().optional().nullable(),
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  values: yup.array().min(2, "Багадаа 2 -ийг оруулна уу!").max(100, "Хэт их байна.").required("Заавал бөглөнө!").nullable(),
});

type IOptionForm = {
  action: undefined | string | null;
  name: string | null | undefined;
  isGoods: boolean;
  isService: boolean;
  values: string[];
};

function ServiceOrderVariantsForm({ editable, reload, isLoading }: { editable: any; reload: () => void; isLoading: boolean }) {
  const { options: initOptionsRoot } = useSelector((state: { general: IGeneral }) => state.general);
  const [optionLoad, setOptionLoad] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [initOptions, setInitOptions] = useState<any[]>([...initOptionsRoot]);

  const [options, setOptions] = useState<any[]>(
    (editable[1] &&
      editable[1]?.options?.map((item: any) => {
        return {
          ...item,
          values: [...editable[1]?.optionValues?.filter((item2: any) => item2?.optionId === item?.id)],
        };
      })) ||
      [],
  );

  const params = useParams();

  const form = useForm<any>({
    initialValues: {
      hasVariant: true,
      variants:
        (editable[1] &&
          editable[1]?.variants?.map((item: any) => {
            return {
              ...item,
              isEdit: false,
            };
          })) ||
        [],
    },
    validate: yupResolver(schema),
  });

  const optionForm = useForm<IOptionForm>({
    validate: yupResolver(schema2),
    initialValues: {
      action: undefined,
      name: undefined,
      isGoods: false,
      isService: true,
      values: [],
    },
  });

  const onSubmit = async (values: any) => {
    try {
    } catch (error) {}
  };

  const tableActions = async (key: string, rows: any) => {
    let check = rows.id.split(": ")[0];
    let res = await form.validate();

    switch (key) {
      case "save":
        if (!res.hasErrors) {
          try {
            await ServiceVariantApi.add({
              serviceId: params.id!,
              id: check === "new" ? "" : rows.id,
              image: rows?.image,
              optionValues: rows?.optionValues.map((item: any) => {
                return {
                  optionId: item.optionId,
                  name: item.name,
                };
              }),
              barCode: rows?.barCode,
              erpCode: rows?.erpCode,
              skuCode: rows?.skuCode,
            });
            message.success("Үйлдэл амжилттай.");
            await reload();
            // form.reset();
            console.log(check === "new" ? "created...." : rows.id + " updated.... ");
          } catch (error: any) {
            error.message && message.error(error.message);
            console.log(check === "new" ? "fiailed created...." : rows.id + " fiailed updated.... ");
          }
        } else message.error("Гүйцэт бөглөнө үү!");
        break;
      case "edit":
        break;
      case "remove":
        Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
          switch (key) {
            case "confirm": {
              if (check !== "new") {
                try {
                  await ServiceVariantApi.remove(rows.id);
                  form.setFieldValue(
                    `variants`,
                    form?.values?.variants?.filter((item: any) => item.id !== rows.id),
                  );
                  message.success("Үйлдэл амжилттай.");
                  await reload();
                  form.reset();
                } catch (error: any) {
                  message.error(error?.message || "Үйлдэл амжилтгүй.");
                }
              }
              break;
            }
            default:
          }
        });

        break;
      default:
    }
  };

  const optionCreateSubmit = async (values: any) => {
    setOptionLoad(true);

    try {
      await OptionApi.create({
        isGoods: false,
        isService: true,
        name: values.name,
        values: values?.values || [],
      });

      const res2 = await GeneralApi.init();
      dispatch(initGeneral(res2));
      message.success("Үйлдэл амжилттай.");
    } catch (error: any) {
      message.error(error?.message || "Үйлдэл амжилтгүй.");
    }

    onCloseModal();
  };

  function cartesianProduct(arrays: any) {
    if (arrays?.length === 0) return [[]];

    const [first, ...rest] = arrays?.map((item: any) => {
      return item;
    });

    const productOfRest = cartesianProduct(rest?.length > 0 ? rest?.map((i: any) => i) : []);

    if (first?.values?.length > 0)
      return first?.values?.flatMap((item: any) =>
        productOfRest.map((p: any) => {
          return [item, ...p];
        }),
      );
    else return [];
  }

  function removeDuplicateObjects(arr: any) {
    console.log(
      "arr: ",
      arr.map((item: any) => {
        return {
          id: item.id,
          optionValues: item.optionValues,
        };
      }),
    );

    const uniqueObjects = arr.filter((obj: any, index: number, array: any) => {
      const firstIndex = array?.findIndex((o: any) =>
        arrayEquals(
          o.optionValues?.map((item1: any) => item1.id),
          obj.optionValues?.map((item1: any) => item1.id),
        ),
      );

      return index === firstIndex;
    });

    return uniqueObjects;
  }

  function arrayEquals(arr1: any, arr2: any) {
    if (arr1?.length === arr2?.length) {
      const result = arr1?.every((val: any, index: number) => val === arr2[index]);
      return result;
    } else return false;
  }

  useEffect(() => {
    let allCombinations = cartesianProduct(options);

    if (options?.length > 0) {
      let combine = allCombinations.map((optionValues: any, index: number) => {
        return {
          id: `new: ${index}`,
          optionValues: optionValues || [],
          order: index + 1,
          skuCode: "",
          barCode: "",
          erpCode: "",
          image: "",
        };
      });

      let COMBINED = [...((editable[1] && editable[1]?.variants) || []), ...combine];
      form.setFieldValue("variants", removeDuplicateObjects(COMBINED));
    } else {
      form.setFieldValue("variants", [...((editable[1] && editable[1]?.variants) || [])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, editable]);

  const onCloseModal = () => {
    setOptionLoad(true);

    optionForm.setValues({
      action: undefined,
      name: undefined,
      isGoods: false,
      isService: true,
      values: [],
    });
    setOptionLoad(false);
  };

  useEffect(() => {
    setInitOptions(initOptionsRoot);
  }, [initOptionsRoot]);

  useEffect(() => {
    if (editable[1] && editable[0] === "update") {
      setOptions(
        (editable[1] &&
          editable[1]?.options?.map((item: any) => {
            return {
              ...item,
              values: [...editable[1]?.optionValues?.filter((item2: any) => item2?.optionId === item?.id)],
            };
          })) ||
          [],
      );
    }
  }, [editable]);

  return (
    <Paper my={"lg"}>
      <Box maw="100%">
        <form onSubmit={form.onSubmit(onSubmit)}>
          <div>
            <Grid>
              {form.values.hasVariant && (
                <>
                  <Grid.Col>
                    <Group align="apart">
                      <Text fw={600} size={"lg"}>
                        Хувилбарын мэдээлэл
                      </Text>
                      <Group align="right">
                        <ActionIcon
                          variant="gradient"
                          disabled={options?.length === initOptions?.filter((f: any) => !f.isGoods)?.length}
                          onClick={async () => {
                            setOptions([
                              ...options,
                              {
                                id: undefined,
                                name: undefined,
                                type: undefined,
                                values: [],
                              },
                            ]);
                          }}>
                          <IconRowInsertBottom />
                        </ActionIcon>
                        <Button
                          onClick={() => optionForm.setFieldValue("action", "option-new")}
                          leftSection={<IconPlus size={20} />}
                          key={0}
                          size="xs"
                          variant="gradient">
                          Хувилбар
                        </Button>
                      </Group>
                    </Group>
                  </Grid.Col>
                  <Grid.Col>
                    {options?.length > 0 ? (
                      options?.map((item: any, index: number) => {
                        return (
                          <Grid key={index}>
                            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                              <Input.Wrapper label="Хувилбарын нэр" required>
                                <Select
                                  error={!options[index]?.id}
                                  rightSection={
                                    <CloseButton
                                      onClick={() => {
                                        setOptions(
                                          options?.map((item2: any, index2: number) => {
                                            if (item.id === item2.id)
                                              return {
                                                id: null,
                                                values: [],
                                              };
                                            else return item2;
                                          }),
                                        );
                                      }}
                                      size={"sm"}
                                      variant="transparent"
                                    />
                                  }
                                  value={options[index]?.id || ""}
                                  searchable
                                  nothingFoundMessage="Олдсонгүй."
                                  onChange={(id: string | null) => {
                                    let itemUpdate = initOptions.filter((f: any) => !f.isGoods)?.find((f: any) => f.id === id);

                                    setOptions(
                                      options?.map((item2: any, index2: number) => {
                                        if (index === index2) return { ...itemUpdate, values: [] };
                                        else return item2;
                                      }),
                                    );
                                  }}
                                  data={initOptions
                                    .filter((f: any) => !f.isGoods)
                                    ?.map((item3: any) => {
                                      return {
                                        value: item3.id,
                                        label: item3.name,
                                        disabled: options?.some((som: any) => som?.id === item3?.id),
                                      };
                                    })}
                                  placeholder="Сонгох"
                                />
                              </Input.Wrapper>
                            </Grid.Col>
                            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                              <Flex align={"end"} gap={8}>
                                <Input.Wrapper label="Хувилбар дахь сонголтууд" required w={"100%"}>
                                  <MultiSelect
                                    clearable
                                    error={!options[index]?.values?.length}
                                    disabled={!options[index]?.id}
                                    value={options[index]?.values?.map((i: any) => i?.id) || []}
                                    data={
                                      initOptions
                                        .filter((f: any) => !f.isGoods)
                                        .find((fin: any) => fin.id === item.id)
                                        ?.values?.map((item: any) => {
                                          return {
                                            item,
                                            label: item.name,
                                            value: item.id,
                                          };
                                        }) || []
                                    }
                                    onChange={(e) => {
                                      let f: any = initOptions
                                        ?.filter((f: any) => !f.isGoods)
                                        ?.find((fin: any) => fin.id === item.id)
                                        ?.values.filter((f: any) => e.some((som: any) => som === f.id));

                                      setOptions(
                                        options?.map((item2: any, index2: number) => {
                                          if (item.id === item2.id) return { ...item2, values: f };
                                          else return item2;
                                        }),
                                      );
                                    }}
                                    placeholder="Сонгох"
                                    w={"100%"}
                                    searchable
                                    nothingFoundMessage
                                    onOptionSubmit={(query) => {
                                      const start = Date.now();
                                      const randId = randomId();
                                      const createValue = {
                                        id: randId,
                                        isGoods: false,
                                        createdAt: start,
                                        updatedAt: null,
                                        deletedAt: null,
                                        optionId: item.id,
                                        name: query,
                                      };

                                      setInitOptions(
                                        initOptions?.map((item2: any, index2: number) => {
                                          if (item.id === item2.id)
                                            return {
                                              ...item2,
                                              values: [...item2.values, createValue],
                                            };
                                          return item2;
                                        }),
                                      );

                                      return randId;
                                    }}
                                  />
                                </Input.Wrapper>
                                <ActionIcon
                                  variant="light"
                                  size={36}
                                  color="red"
                                  onClick={() => {
                                    setOptions(options?.filter((f: any) => f.id !== item.id));
                                  }}>
                                  <IconX size={18} />
                                </ActionIcon>
                              </Flex>
                            </Grid.Col>
                          </Grid>
                        );
                      })
                    ) : (
                      <Center my={"xs"} bg={"gray.0"} py={"lg"}>
                        <Text size={"sm"} c="dimmed">
                          Хувилбарын сонголт оо нэмнэ үү.
                        </Text>
                      </Center>
                    )}
                  </Grid.Col>
                  <Grid.Col>
                    {form.values.hasVariant && (
                      <ScrollArea>
                        <Table>
                          <thead>
                            <TableHead />
                          </thead>
                          <tbody>
                            {form?.values?.variants?.length === 0 ? (
                              <tr>
                                <td colSpan={14}>
                                  <Center w={"100%"} py={"xl"} bg={"gray.0"}>
                                    <Flex direction={"column"} gap={8} align="center">
                                      <IconPackage size={22} color="gray" />
                                      <Text c="dimmed" size="sm">
                                        Одоогоор юу ч алга.
                                      </Text>
                                    </Flex>
                                  </Center>
                                </td>
                              </tr>
                            ) : (
                              <>
                                {[...form?.values?.variants]?.map((element: any, index: number) => (
                                  <tr key={index}>
                                    <td>
                                      <Flex gap={8}>
                                        {element.isEdit ? (
                                          <>
                                            <Tooltip label="Хадгалах">
                                              <ActionIcon
                                                loading={isLoading}
                                                onClick={async () => {
                                                  let res = await form.validate();

                                                  if (!res.hasErrors) {
                                                    form.setFieldValue(`variants.${index}.isEdit`, false);
                                                    tableActions("save", element);
                                                  } else message.error("Гүйцэт бөглөнө үү!");
                                                }}
                                                color="primary">
                                                <IconDeviceFloppy />
                                              </ActionIcon>
                                            </Tooltip>
                                            <Tooltip label="Болих">
                                              <ActionIcon
                                                onClick={() => {
                                                  form.setFieldValue(`variants.${index}.isEdit`, false);
                                                }}
                                                color="gray">
                                                <IconSquareRoundedX />
                                              </ActionIcon>
                                            </Tooltip>
                                          </>
                                        ) : (
                                          <>
                                            <Tooltip label="Засах">
                                              <ActionIcon
                                                onClick={() => {
                                                  form.setFieldValue(
                                                    `variants`,
                                                    form?.values?.variants?.map((item1: any, index1: number) => {
                                                      return { ...item1, isEdit: index === index1 };
                                                    }),
                                                  );
                                                  tableActions("edit", element);
                                                }}
                                                color="blue">
                                                <IconEdit />
                                              </ActionIcon>
                                            </Tooltip>
                                            {element.id.split(": ")[0] !== "new" && (
                                              <Tooltip label="Хасах">
                                                <ActionIcon
                                                  disabled={form?.values?.variants?.some((some: any) => some?.isEdit)}
                                                  onClick={() => {
                                                    form.setFieldValue(`variants.${index}.isEdit`, false);
                                                    tableActions("remove", element);
                                                  }}
                                                  color="red">
                                                  <IconTrash />
                                                </ActionIcon>
                                              </Tooltip>
                                            )}
                                          </>
                                        )}
                                      </Flex>
                                    </td>
                                    <td>
                                      {element?.id?.split(": ")[0] === "new" ? (
                                        <Badge variant="dot" color="gray">
                                          Идэвхгүй
                                        </Badge>
                                      ) : (
                                        <Badge variant="dot" color="green">
                                          Идэвхтэй
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      <Avatar src={form?.values?.variants[index]?.image}>
                                        <IconPhoto />
                                      </Avatar>
                                    </td>
                                    <td>
                                      <Flex gap={8}>
                                        {element?.optionValues?.map((optionValue: any, index2: number) => {
                                          return (
                                            <Badge key={index2}>
                                              <Flex>
                                                {optionValue?.name} <CloseButton size={"xs"} variant="transparent" />
                                              </Flex>
                                            </Badge>
                                          );
                                        })}
                                      </Flex>
                                    </td>
                                    <td>
                                      <TextInput
                                        disabled={!element.isEdit}
                                        w={"220px"}
                                        size="xs"
                                        placeholder="SKU код"
                                        {...form.getInputProps(`variants.${index}.skuCode`)}
                                      />
                                    </td>
                                    <td>
                                      <TextInput
                                        disabled={!element.isEdit}
                                        w={"220px"}
                                        size="xs"
                                        placeholder="Barcode"
                                        {...form.getInputProps(`variants.${index}.barCode`)}
                                      />
                                    </td>
                                    <td>
                                      <TextInput
                                        disabled={!element.isEdit}
                                        w={"220px"}
                                        size="xs"
                                        placeholder="ERP код"
                                        {...form.getInputProps(`variants.${index}.erpCode`)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>
                        </Table>
                        <Space h={"lg"} />
                      </ScrollArea>
                    )}
                  </Grid.Col>
                </>
              )}
            </Grid>
          </div>
        </form>
      </Box>
      <Modal
        centered
        title={
          <Text fw={600} size={"lg"}>
            Хувилбар нэмэх
          </Text>
        }
        opened={optionForm.values.action === "option-new"}
        onClose={onCloseModal}
        size={"lg"}>
        <form onSubmit={optionForm.onSubmit(optionCreateSubmit)}>
          <Grid>
            <Grid.Col>
              <Input.Wrapper label="Хувилбарын нэр" required>
                <TextInput placeholder="Хувилбарын нэр" {...optionForm.getInputProps("name")} />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col>
              <Input.Wrapper label="Хувилбарын сонголт" required>
                <MultiSelect
                  data={optionForm.values.values}
                  placeholder="Хувилбарын сонголт оруулна уу."
                  searchable
                  error={optionForm.errors.values}
                  label={`Сонголт нэмэх`}
                  onSubmit={(query: any) => {
                    optionForm.setFieldValue(`values`, [...optionForm.values.values, query]);
                    return query;
                  }}
                  onChange={(e) => {
                    optionForm.setFieldValue(`values`, e);
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col>
              <Group align="right">
                <Button onClick={onCloseModal} variant="default">
                  Болих
                </Button>
                <Button loading={optionLoad} type="submit">
                  Хадгалах
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
      </Modal>
    </Paper>
  );
}

export default ServiceOrderVariantsForm;
