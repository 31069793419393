import { Button, CloseButton, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { ItemTypeApi } from "../../apis";
import { IItemType } from "../../interfaces/IItemType";
import { updateItemTypes } from "../../store/general";
import { HttpHandler } from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  title: string;
  subTitle: string;
  action: string[];
  payload?: IItemType;
  reloadTable?: any;
};

export function ItemTypeForm({ onCancel, title, subTitle, action, payload, reloadTable }: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IItemType>({
    id: "",
    name: "",
    description: "",
    isService: false,
    isGoods: false,
    ...(payload ? payload : {}),
  });

  const onSubmit = async (data: IItemType) => {
    try {
      setLoading(true);

      if (payload) {
        await ItemTypeApi.update(payload.id, {
          name: data.name,
          description: data.description,
          isGoods: data.isGoods,
          isService: data.isService,
        });
      } else {
        await ItemTypeApi.create({
          name: data.name,
          description: data.description,
          isGoods: !action[0] ? true : data.isGoods,
          isService: !action[0] ? false : data.isService,
        });
      }

      if (action.length !== 0) reloadTable();

      setLoading(false);

      message.success("Таны хүсэлт амжилттай!");

      onCancel && onCancel();

      let res = await ItemTypeApi.select({ isGoods: true });

      dispatch(updateItemTypes(res));
    } catch (err) {
      setLoading(false);

      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Flex gap={15} direction="column">
              <TextField name="name" label="Нэр" placeholder="Нэр" />
              {action[0] ? <CheckboxField name="isGoods" label="Бараа" /> : null}
              {action[0] ? <CheckboxField name="isService" label="Үйлчилгээ" /> : null}
              <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
            </Flex>
            <Group justify="right" mt={20}>
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
}
