import { Button, CloseButton, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { TierPriceApi } from "../../apis";
import { message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

type IForm = {
  name: string | undefined;
};
const schema = yup.object({
  name: yup.string().trim().required("Заавал бөглөнө үү!"),
});
export function TierPriceForm({ onClose, reload, action }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data] = React.useState<IForm>({
    name: undefined,
    ...(action && { name: action[1]?.name }),
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      await TierPriceApi.update(action[1]?.id, values);
      message.success("Мэдээлэл амжилттай хадгалагдлаа");
      setLoading(false);
      onClose();
      reload();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message || "Алдаа гарлаа");
    }
  };

  return (
    <Form validationSchema={schema} initialValues={data} onSubmit={onSubmit}>
      {() => {
        return (
          <FormLayout title={"Барааны стандарт нэр засах"} subTitle={""} my={0} extra={[<CloseButton key="cancel" onClick={() => onClose && onClose()} />]}>
            <LoadingOverlay visible={loading} />

            <Flex gap={15} direction="column">
              <TextField name="name" label="Нэр" placeholder="Нэр" />
            </Flex>

            <Group justify="right" mt={20}>
              <Button key={1} variant="default" onClick={() => onClose && onClose()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
}
