import { Paper } from "@mantine/core";
import { useParams } from "react-router";
import { PageLayout } from "../../components/layout";
import { AdjustmentPage } from "../../components/reference/adjustment-list";
import { BrandList } from "../../components/reference/brand-list";
import { CategoryList } from "../../components/reference/category-list";
import { ClassificationList } from "../../components/reference/classification-list";
import { DeliveryList } from "../../components/reference/delivery-list";
import { DistributorList } from "../../components/reference/distributor-list";
import { InactiveTypeList } from "../../components/reference/inactive-type-list";
import { ItemTypeList } from "../../components/reference/item-type-list";
import { ManufacturerList } from "../../components/reference/manufacturer-list";
import { OptionPage } from "../../components/reference/option-list";
import { PackageList } from "../../components/reference/package-list";
import { SectionList } from "../../components/reference/section-list";
import { SubCategoryList } from "../../components/reference/sub-category-list";
import { SubClassificationList } from "../../components/reference/sub-classification-list";
import { TagList } from "../../components/reference/tag-list";
import { TierPriceList } from "../../components/reference/tier-price-list";
import { UnitList } from "../../components/reference/unit-list";

export const ReferenceType = () => {
  const params = useParams();

  const titles = (val: string) => {
    switch (val) {
      case "BRAND": {
        return "Брэнд";
      }
      case "SECTION": {
        return "Барааны нэмэлт мэдээлэл";
      }
      case "TAG": {
        return "Таг";
      }
      case "DISTRIBUTOR": {
        return "Дистрибьютер";
      }
      case "MANUFACTURER": {
        return "Үйлдвэрлэгч";
      }
      case "UNIT": {
        return "Нэгж";
      }
      case "PACKAGE_TYPE": {
        return "Сав, баглаа, боодол";
      }
      case "DELIVERY_TYPE": {
        return "Хүргэлтийн нөхцөл";
      }
      case "ITEM_TYPE": {
        return "Нэр төрөл";
      }
      case "CLASSIFICATION": {
        return "Ангилал";
      }
      case "SUB_CLASSIFICATION": {
        return "Дэд ангилал";
      }
      case "CATEGORY": {
        return "Категори";
      }
      case "SUB_CATEGORY": {
        return "Бараа бүтээгдэхүүн, ажил үйлчилгээний дэд категорийн жагсаалт";
      }
      case "TIER_PRICE": {
        return "Барааны стандарт үнийн жагсаалт";
      }
      case "ADJUSTMENT_NOTE": {
        return "Нөөцийн хөдөлгөөний тайлбар";
      }
      case "OPTION": {
        return "Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбар";
      }
    }
  };

  const breadcrumbs = useBreadcrumb(titles(params.type as string));

  const itemType = (val: string) => {
    switch (val) {
      case "BRAND": {
        return <BrandList />;
      }
      case "TAG": {
        return <TagList />;
      }
      case "DISTRIBUTOR": {
        return <DistributorList />;
      }
      case "MANUFACTURER": {
        return <ManufacturerList />;
      }
      case "UNIT": {
        return <UnitList />;
      }
      case "DELIVERY_TYPE": {
        return <DeliveryList />;
      }
      case "ITEM_TYPE": {
        return <ItemTypeList />;
      }
      case "CLASSIFICATION": {
        return <ClassificationList />;
      }
      case "SUB_CLASSIFICATION": {
        return <SubClassificationList />;
      }
      case "CATEGORY": {
        return <CategoryList />;
      }
      case "SUB_CATEGORY": {
        return <SubCategoryList />;
      }
      case "TIER_PRICE": {
        return <TierPriceList />;
      }
      case "PACKAGE_TYPE": {
        return <PackageList />;
      }
      case "SECTION": {
        return <SectionList />;
      }
      case "INACTIVE_TYPE": {
        return <InactiveTypeList />;
      }
      case "ADJUSTMENT_NOTE": {
        return <AdjustmentPage />;
      }
      case "OPTION": {
        return <OptionPage />;
      }
    }
  };

  return (
    <PageLayout title="" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        {itemType(params.type as string)}
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/reference",
    label: "Лавлах мэдээлэл",
  },
  { label: type },
];
