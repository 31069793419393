import { ActionIcon, Avatar, Checkbox, Flex } from "@mantine/core";
import { IconArrowUpRight, IconEdit, IconPhoto } from "@tabler/icons-react";
import { ReferenceApi } from "../../apis";
import { ColumnType, Table } from "../../components/table";

export const MovementList = () => {
  const columns = useHeader();

  return <Table name="reference.list" columns={columns} filters={{ type: "ITEM_REFERENCE" }} loadData={(data) => ReferenceApi.list(data!)} />;
};

const useHeader = (): ColumnType<any>[] => [
  {
    title: "Сонгох",
    render: (record, index) => (
      <div>
        <Checkbox value={record?.id} />
      </div>
    ),
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "item.image",
    render: (record) => (
      <Avatar src={`${record?.image || ""}`}>
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => `${record?.skuCode || "-"}`,
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => `${record?.skuCode || "-"}`,
  },
  {
    title: "Барааны нэр",
    sorter: true,
    dataIndex: "category",
    render: (record) => `${record?.nameMon || "-"}`,
  },
  {
    title: "Бодит үлдэгдэл",
    sorter: true,
    dataIndex: "price",
    render: (record) => `${record?.price || "-"}`,
  },
  {
    title: "Нөөцөнд",
    sorter: true,
    dataIndex: "price",
    render: (record) => `Qty_Reserved`,
  },
  {
    title: "Боломжит үлдэгдэл",
    sorter: true,
    dataIndex: "price",
    render: (record) => `Qty_Available`,
  },
  {
    title: "Борлуулалтын захиалгад",
    sorter: true,
    dataIndex: "hasTax",
    render: (record) => `Qty_on_SalesOrder`,
  },
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Flex gap={10}>
          <ActionIcon variant="filled" color={""}>
            <IconArrowUpRight />
          </ActionIcon>
          <ActionIcon variant="filled" color={""}>
            <IconEdit />
          </ActionIcon>
        </Flex>
      );
    },
  },
];
