import { MantineSize, Select } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  noError?: boolean;
  options: { value: string | number; label: string; disabled?: boolean }[];
  clearable?: boolean;
  classNames?: Partial<Record<string, string>> | undefined;
  defaultValue?: any;
  rightSection?: React.ReactNode;
  styles?: any;
  searchable?: boolean;
  required?: boolean;
  size?: MantineSize;
  onSearchChange?: any;
  searchValue?: string;
  w?: any;
  nothingFound?: string;
  icon?: any;
  iconWidth?: number;
};

export function SelectField({
  name,
  label,
  placeholder,
  noError = false,
  onChange: onChangeValue,
  options = [],
  disabled = false,
  classNames,
  clearable = true,
  defaultValue,
  rightSection,
  styles,
  searchable,
  required = false,
  size,
  onSearchChange,
  searchValue,
  w,
  nothingFound,
  icon,
  iconWidth,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Select
      leftSectionWidth={iconWidth}
      w={w}
      leftSection={icon}
      nothingFoundMessage={nothingFound}
      size={size}
      label={label}
      searchable={searchable}
      placeholder={placeholder}
      value={defaultValue ? defaultValue : value}
      disabled={disabled}
      searchValue={searchValue}
      onChange={(value) => {
        onChange(value);
        onChangeValue && onChangeValue(value);
      }}
      onSearchChange={(e) => {
        onSearchChange && onSearchChange(e);
      }}
      rightSection={rightSection}
      error={error}
      clearable={clearable}
      data={options.map((option) => ({
        ...option,
        value: option.value != null ? option.value.toString() : "",
      }))}
      style={{ flex: 1 }}
      classNames={classNames}
      required={required}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: noError && error && 0,
        },
        ...styles,
      }}
    />
  );
}
