import { Text } from "@mantine/core";

function TableHead() {
  return (
    <tr>
      <th>
        <Text w={"max-content"}> Үйлдэл </Text>
      </th>
      <th>
        <Text w={"max-content"}> Төлөв </Text>
      </th>
      <th>
        <Text w={"max-content"}> Зураг </Text>
      </th>
      <th>
        <Text w={"max-content"}> Хувилбарын сонголт </Text>
      </th>
      <th>
        <Text w={"max-content"}> SKU код </Text>
      </th>
      <th>
        <Text w={"max-content"}> Barcode </Text>
      </th>
      <th>
        <Text w={"max-content"}> ERP код </Text>
      </th>
    </tr>
  );
}

export default TableHead;
