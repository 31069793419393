import { Alert } from "@mantine/core";
import { IconUserShield } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";

export type IPermissionCode =
  | "ERP_DASH"
  | "ERP_GDS"
  | "ERP_GDS_PRICE_SET"
  | "ERP_GDS_WRH_SET"
  | "ERP_GDS_STATUS"
  | "ERP_STD_PRICE"
  | "ERP_PG"
  | "ERP_NET_PRICE"
  | "ERP_NET_PRICE_SET"
  | "ERP_STOCK"
  | "ERP_STOCK_ADJ"
  | "ERP_STOCK_MVM"
  | "ERP_STORE"
  | "ERP_STORE_FETCH"
  | "ERP_REF_BRND"
  | "ERP_REF_ITM_CLS"
  | "ERP_REF_DIST"
  | "ERP_REF_MANUFACT"
  | "ERP_REF_TAG"
  | "ERP_REF_UNIT"
  | "ERP_REF_SCTN"
  | "ERP_REF_PCK"
  | "ERP_REF_DEL_TYPE"
  | "ERP_REF_OPTION"
  | "ERP_REF_TIER"
  | "ERP_REF_INCT_TYPE"
  | "ERP_REF_ADJ_NOTE";

export function usePermission(code: IPermissionCode) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { permissions } = useSelector((state: { general: IGeneral }) => state.general);

  function accessDenied() {
    return (
      <Alert title={"Хандах эрх хүрэлцэхгүй байна!"} icon={<IconUserShield />} color="red">
        {permissions?.find((item: any) => item.code === code)?.name || "Хандах эрх хүрэлцэхгүй байна!"}
      </Alert>
    );
  }

  const hasAccess = user?.permissions?.some((som: any) => som?.code === code) || false;
  const isCreate = user?.permissions?.some((item: any) => item.code === code && item?.isCreate) || false;
  const isDelete = user?.permissions?.some((item: any) => item.code === code && item?.isDelete) || false;
  const isEdit = user?.permissions?.some((item: any) => item.code === code && item?.isEdit) || false;
  const isReview = user?.permissions?.some((item: any) => item.code === code && item?.isReview) || false;
  const isView = user?.permissions?.some((item: any) => item.code === code && item?.isView) || false;

  const text = permissions?.find((item: any) => item.code === code)?.name || "Хандах эрх хүрэлцэхгүй байна!";

  return {
    hasAccess,
    isCreate,
    isDelete,
    isEdit,
    isReview,
    isView,
    accessDenied,
    text,
  };
}
