import { ActionIcon, Alert, Collapse, Paper, Space, Text } from "@mantine/core";
import { randomId } from "@mantine/hooks";
import { IconExclamationCircle, IconRowInsertBottom, IconX } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { Reference } from "../../models/Reference";
import { Unit } from "../../models/Unit";
import { CheckboxField } from "../form/checkbox-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { ColumnType, Table } from "../table";

interface IProps {
  setFieldValue: (e1: string, e2: any) => void;
  values: any;
  error: boolean;
}

const NoVariantAddUnit: React.FC<IProps> = React.memo(({ setFieldValue, values, error }) => {
  const { units, unitSpaceLabels, unitWeightLabels, unitConvertTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({
    unitSpaceLabels,
    unitWeightLabels,
    units,
    unitConvertTypes,
    values,
    onClick: (key, record) => {
      switch (key) {
        case "remove":
          setFieldValue("additionalUnits", values?.additionalUnits?.filter((item: any) => item.id !== record.id) || []);
          break;

        default:
          break;
      }
    },
  });

  return (
    <div>
      <Paper bg="gray.0" p="xs">
        <ActionIcon
          onClick={() => {
            setFieldValue("additionalUnits", [...(values?.additionalUnits || []), { ...initialUnit, id: randomId() }]);
          }}
          variant="filled"
          color="">
          <IconRowInsertBottom />
        </ActionIcon>
      </Paper>
      <Table minWidth={2500} name="additionalUnits" columns={columns} dataSource={values?.additionalUnits || []} pagination={false} />
      <Collapse in={error}>
        <Space h="sm" />
        <Alert icon={<IconExclamationCircle />} color="red" title="Заавал бөглөнө!">
          Нэмэлт хэмжих нэгж багадаа 1 -ийг оруулна уу.
        </Alert>
      </Collapse>
    </div>
  );
});

const useHeader = ({
  unitSpaceLabels,
  unitWeightLabels,
  units,
  unitConvertTypes,
  values,
  onClick,
}: {
  unitSpaceLabels: any;
  unitWeightLabels: any;
  units: any;
  unitConvertTypes: any;
  values: any;
  onClick: (key: string, record: any) => void;
}): ColumnType<Unit[]>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: (
      <Text>
        Хэмжих нэгжийн нэр{" "}
        <Text component="span" color="red">
          *
        </Text>
      </Text>
    ),
    render: (_r, index) => {
      return (
        <>
          <SelectField
            noError
            name={`additionalUnits[${index}].unitId`}
            placeholder="Сонгох/Нэмэх"
            options={units
              ?.filter((f: any) => f?.id !== values?.baseUnitId)
              ?.map((it: any) => ({ label: it.name, value: it.id, disabled: values.additionalUnits.some((som: any) => som.unitId === it.id) }))}
          />
        </>
      );
    },
  },
  {
    title: (
      <Text>
        Хөрвөх арга{" "}
        <Text component="span" color="red">
          *
        </Text>
      </Text>
    ),
    render: (_r, index) => {
      return (
        <SelectField
          noError
          name={`additionalUnits[${index}].convertType`}
          placeholder="Сонгох"
          options={
            unitConvertTypes!.map((item: Reference) => {
              return {
                label: item.name,
                value: item.code,
              };
            }) as { value: string; label: string }[]
          }
        />
      );
    },
  },
  {
    title: (
      <Text>
        Хөрвүүлэх тоо{" "}
        <Text component="span" color="red">
          *
        </Text>
      </Text>
    ),
    render: (_r, index) => {
      return <NumberField noError name={`additionalUnits[${index}].convertValue`} placeholder="Тоо" />;
    },
  },
  {
    title: "Бутархай орон",
    render: (_r, index) => {
      return <NumberField noError name={`additionalUnits[${index}].floatValue`} placeholder="Тоо" />;
    },
  },
  {
    title: "Ачилт",
    render: (_r, index) => {
      return <CheckboxField name={`additionalUnits[${index}].isForLoad`} />;
    },
  },
  {
    title: "Эзлэхүүн нэгж",
    render: (_r, index) => {
      return (
        <SelectField
          noError
          name={`additionalUnits[${index}].spaceLabel`}
          placeholder="Сонгох"
          options={
            unitSpaceLabels!.map((item: Reference) => {
              return {
                label: item.name,
                value: item.code,
              };
            }) as { value: string; label: string }[]
          }
        />
      );
    },
  },
  {
    title: "Өндөр",
    render: (_r, index) => {
      return <NumberField noError name={`additionalUnits[${index}].height`} placeholder="Өндөр" />;
    },
  },
  {
    title: "Өргөн",
    render: (_r, index) => {
      return <NumberField noError name={`additionalUnits[${index}].width`} placeholder="Өргөн" />;
    },
  },
  {
    title: "Урт",
    render: (_r, index) => {
      return <NumberField noError name={`additionalUnits[${index}].length`} placeholder="Урт" />;
    },
  },
  {
    title: "Жин",
    render: (_r, index) => {
      return <NumberField noError name={`additionalUnits[${index}].weight`} placeholder="Жин" />;
    },
  },
  {
    title: "Жин нэгж",
    render: (_r, index) => {
      return (
        <SelectField
          name={`additionalUnits[${index}].weightLabel`}
          noError
          placeholder=" | кг"
          options={
            unitWeightLabels!.map((item: Reference) => {
              return {
                label: item.name,
                value: item.code,
              };
            }) as { value: string; label: string }[]
          }
        />
      );
    },
  },
  {
    title: "Үйлдэл",
    render: (_r, index) => {
      return (
        <ActionIcon variant="outline" onClick={() => onClick("remove", _r)}>
          <IconX />
        </ActionIcon>
      );
    },
    width: "30px",
  },
];

const initialUnit = {
  id: null,
  unitId: "",
  convertType: "",
  convertValue: "",
  floatValue: null,
  spaceLabel: null,
  height: null,
  width: null,
  length: null,
  weight: null,
  weightLabel: null,
  packageTypeId: null,
  isForLoad: false,
};

NoVariantAddUnit.displayName = "NoVariantAddUnit";

export { NoVariantAddUnit };
