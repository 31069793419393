import { ActionIcon, Center, CloseButton, Group, NumberInput, Paper, Select, Table, Text, Tooltip } from "@mantine/core";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { IconCalendar, IconCurrencyTugrik, IconPlus, IconRowInsertBottom, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";

const start = Date.now();

const elements = [
  {
    title: "#",
    tierNo: 0,
    placeholder: "",
    type: "INDEX",
    key: "",
    isAction: false,
  },
  {
    title: "Үйлдэл",
    tierNo: 0,
    placeholder: "",
    type: "INDEX",
    key: "",
    isAction: false,
  },
  {
    title: "Хэмжих нэгж",
    tierNo: 0,
    placeholder: "",
    type: "KEY",
    key: "name",
    isAction: false,
  },
  {
    title: "Тоо ширхэг",
    tierNo: 0,
    placeholder: "",
    type: "KEY",
    key: "convertValue",
    isAction: false,
  },
  {
    title: "Үндсэн нэгжээр тоо",
    tierNo: 0,
    placeholder: "",
    type: "KEY",
    key: "convertValue",
    isAction: false,
  },
  {
    title: "Валют",
    tierNo: 0,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Хөрвөх аргаар тооцсон",
    tierNo: 0,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Эхлэх хугацаа",
    tierNo: 0,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Дуусах хугацаа",
    tierNo: 0,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 1 үнэ",
    tierNo: 1,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 2 үнэ",
    tierNo: 2,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 3 үнэ",
    tierNo: 3,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 4 үнэ",
    tierNo: 4,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 5 үнэ",
    tierNo: 5,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 6 үнэ",
    tierNo: 6,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 7 үнэ",
    tierNo: 7,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 8 үнэ",
    tierNo: 8,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 9 үнэ",
    tierNo: 9,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
  {
    title: "Tier 10 үнэ",
    tierNo: 10,
    placeholder: "",
    type: "NUMBER_INPUT",
    key: "",
    isAction: false,
  },
];

export function SetAdditionalUnits({ form, data }: { form: any; data: any }) {
  const { tiers, currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const [selected, setSelected] = useState<any>({ variantId: null });

  return (
    <Paper>
      <Group align="apart">
        <Text my={"lg"} fw={600} fz="lg">
          Нэмэлт хэмжих нэгж
        </Text>
        <Group>
          <Select
            nothingFoundMessage={<Center>Хэмжих нэгж олдсонгүй.</Center>}
            leftSection={<IconRowInsertBottom size={18} />}
            value={selected?.variantId}
            placeholder="Хэмжих нэгж сонгох"
            data={
              data?.additionalUnits
                ?.filter((t: any) => !form.values.unitPrices?.some((som: any) => som.variantId === t.id))
                .map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                }) || []
            }
            size="xs"
            w={"200px"}
            onChange={(e) => {
              if (e) {
                let find = data?.additionalUnits.find((f: any) => f.id === e);

                let test2 = {
                  variantId: find.id,
                  unitId: find.unitId,
                  standardPrice: data?.standardPrice,
                  convertType: find.convertType,
                  convertValue: find.convertValue,
                  name: find.name,
                  startDate: null,
                  endDate: null,
                  tier1Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 1)?.isSet || false,
                  },
                  tier2Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 2)?.isSet || false,
                  },
                  tier3Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 3)?.isSet || false,
                  },
                  tier4Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 4)?.isSet || false,
                  },
                  tier5Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 5)?.isSet || false,
                  },
                  tier6Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 6)?.isSet || false,
                  },
                  tier7Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 7)?.isSet || false,
                  },
                  tier8Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 8)?.isSet || false,
                  },
                  tier9Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 9)?.isSet || false,
                  },
                  tier10Price: {
                    value: null,
                    isSet: tiers.find((find: any) => find.tierNo === 10)?.isSet || false,
                  },
                  unitName: data?.baseUnit?.name,
                  currency: data.currency,
                  isSet: true,
                };
                setSelected(test2);
              } else setSelected({ variantId: null });
            }}
            clearable
            searchable
          />
          <ActionIcon
            disabled={selected?.variantId === null}
            onClick={() => {
              form.setFieldValue("unitPrices", [...(form.values?.unitPrices || []), { ...selected }]);

              setSelected({
                variantId: null,
              });
            }}
            variant="outline">
            <IconPlus size={18} />
          </ActionIcon>
        </Group>
      </Group>

      <div style={{ overflowX: "auto" }}>
        <Table horizontalSpacing="sm" verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              {elements.map((item: any, index: number) => {
                return (
                  <Table.Th key={index}>
                    <Text style={{ whiteSpace: "nowrap" }}>{tiers.find((item2: any) => item2.tierNo === item.tierNo)?.name || item.title}</Text>
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {form.values.unitPrices?.map((item: any, index: number) => {
              return (
                <Table.Tr key={index}>
                  <Table.Td>{index + 1}</Table.Td>
                  <Table.Td>
                    <Tooltip label="Устгах">
                      <ActionIcon
                        onClick={() => {
                          form.setFieldValue(`unitPrices`, [...form.values.unitPrices.filter((item2: any, index2: number) => index !== index2)]);
                        }}
                        variant="light"
                        color="red">
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                  </Table.Td>
                  <Table.Td>{form.values?.unitPrices[index]?.name ?? "-"}</Table.Td>
                  <Table.Td>{item.convertValue}</Table.Td>
                  <Table.Td>{item.convertValue}</Table.Td>
                  <Table.Td>{currencies?.find((item2: any) => item2.code === item?.currency)?.name ?? "-"} </Table.Td>
                  <Table.Td>{"Бодогдох"}</Table.Td>
                  <Table.Td>
                    <DatePickerInput
                      leftSection={<IconCalendar size={18} />}
                      style={{ width: 200 }}
                      size="xs"
                      placeholder="Эхлэх хугацаа"
                      minDate={new Date(start)}
                      clearable
                      defaultValue={form.values?.unitPrices[index]?.startDate as string}
                      {...form.getInputProps(`unitPrices.${index}.startDate`)}
                      value={form.values?.unitPrices[index]?.startDate ? new Date(dayjs(form.values?.unitPrices[index]?.startDate).format()) : null}
                      onChange={(e) => {
                        form.setFieldValue(`unitPrices.${index}.startDate`, e);
                        form.setFieldValue(`unitPrices.${index}.endDate`, null);
                      }}
                      valueFormat="YYYY-MM-DD"
                    />
                  </Table.Td>
                  <Table.Td>
                    <DatePicker
                      leftSection={<IconCalendar size={18} />}
                      style={{ width: 200 }}
                      size="xs"
                      minDate={
                        form.values?.unitPrices[index]?.stardate
                          ? new Date(dayjs(form.values?.unitPrices[index]?.startDate).add(1, "day").format())
                          : new Date(start)
                      }
                      placeholder="Дуусах хугацаа"
                      disabled={!form.values.unitPrices[index].startDate}
                      {...form.getInputProps(`unitPrices.${index}.endDate`)}
                      value={form.values?.unitPrices[index]?.endDate ? new Date(dayjs(form.values?.unitPrices[index]?.endDate).format()) : null}
                      onChange={(e) => form.setFieldValue(`unitPrices.${index}.endDate`, e)}
                      valueFormat="YYYY-MM-DD"
                    />
                  </Table.Td>
                  {tiers.map((it: any, index2: number) => {
                    return (
                      <Table.Td key={index2}>
                        {it.isSet ? (
                          <NumberInput
                            size="xs"
                            min={0}
                            leftSection={<IconCurrencyTugrik size={18} stroke="1.5" color="gray" opacity={0.8} />}
                            placeholder="0.00"
                            rightSection={
                              <CloseButton
                                mr={"6px"}
                                onClick={() => form.setFieldValue(`unitPrices.${index}.tier${[it.tierNo]}Price.value`, undefined)}
                                variant="transparent"
                                size={"sm"}
                                color="gray.6"
                              />
                            }
                            {...form.getInputProps(`unitPrices.${index}.tier${[it.tierNo]}Price.value`)}
                          />
                        ) : (
                          "-"
                        )}
                      </Table.Td>
                    );
                  })}
                </Table.Tr>
              );
            })}
            <Table.Tr>
              <Table.Td colSpan={elements.length}>
                {form.values.unitPrices?.length === 0 && (
                  <Center p={"lg"}>
                    <Text fz={"sm"} c="gray.9">
                      Одоогоор юу ч алга
                    </Text>
                  </Center>
                )}
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </div>
    </Paper>
  );
}
