import { ActionIcon, Center, Group, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { currencyFormat } from "../../utils";
import { ColumnType, Table } from "../table";

function PriceGroupSelectList({ variantList, setFieldValue, values }: { variantList: any; setFieldValue: (e1: string, e2: any) => void; values: any }) {
  const { tiers } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "add":
          setFieldValue("variants", [
            ...(values?.variants || []),
            {
              id: record.id,
              image: record.image,
              variantId: record.id,
              skuCode: record?.skuCode,
              refCode: record?.refCode || "Авто үүснэ",
              name: record?.name || "Авто үүснэ",
              barCode: record?.barCode,
              erpCode: record?.erpCode,
              nameMon: record?.nameMon,
              nameEng: record?.nameEng,
              nameBill: record?.nameBill,
              nameWeb: record?.nameWeb,
              nameApp: record?.nameApp,
              unitId: record?.unitId,
              unitName: record?.unit?.name,
              standardPrice: record?.standardPrice,
              currency: record?.currency,
              tier1Price: {
                value: record?.tier1Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 1)?.isSet || false,
              },
              tier2Price: {
                value: record?.tier2Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 2)?.isSet || false,
              },
              tier3Price: {
                value: record?.tier3Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 3)?.isSet || false,
              },
              tier4Price: {
                value: record?.tier4Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 4)?.isSet || false,
              },
              tier5Price: {
                value: record?.tier5Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 5)?.isSet || false,
              },
              tier6Price: {
                value: record?.tier6Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 6)?.isSet || false,
              },
              tier7Price: {
                value: record?.tier7Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 7)?.isSet || false,
              },
              tier8Price: {
                value: record?.tier8Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 8)?.isSet || false,
              },
              tier9Price: {
                value: record?.tier9Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 9)?.isSet || false,
              },
              tier10Price: {
                value: record?.tier10Price || null,
                isSet: tiers.find((item: any, index: number) => item.tierNo === 10)?.isSet || false,
              },
            },
          ]);
          break;

        default:
          break;
      }
    },
    tiers,
  });

  return (
    <Table
      minWidth={"max-content"}
      name={`variants.list.${JSON.stringify(variantList.map((item: any) => item.id))}`}
      columns={columns}
      pagination={false}
      dataSource={variantList.filter((item1: any) => !values?.variants?.some((item2: any) => item2.id === item1.id)) || []}
    />
  );
}

const useHeader = ({ onClick, tiers }: { onClick: (key: string, record: any) => void; tiers: any }): ColumnType<any>[] => [
  {
    title: "Нэмэх",
    render: (record) => (
      <Center>
        <ActionIcon onClick={() => onClick("add", record)} variant="light" color="">
          <IconPlus size={"1.2rem"} />
        </ActionIcon>
      </Center>
    ),
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unitName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unit?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Бараа, бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "nameMon",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.nameMon || "-"}
      </Text>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Стандарт үнэ",
    sorter: true,
    dataIndex: "standardPrice",
    render: (record) => (
      <Group justify="right">
        <Text size="sm">{record.standardPrice ? currencyFormat(record?.standardPrice || 0) : "-"}</Text>
      </Group>
    ),
  },
];

export default PriceGroupSelectList;
