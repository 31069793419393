import { Alert, Button, Flex, LoadingOverlay } from "@mantine/core";
import { IconCheck, IconDeviceFloppy } from "@tabler/icons-react";
import React, { useRef } from "react";
import { PriceGroupApi } from "../../apis";
import { message } from "../../utils/message";
import { PageLayout } from "../layout";
import { DynamicFieldForm } from "./group-dynamic-form";

export function PolicyDetail({ id }: { id: string }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const dynamicFormRef = useRef<any>(null);
  const tableRef = useRef<any>(null);
  const [preAlert, setPreAlert] = React.useState<boolean>(true);

  const [data, setData] = React.useState<any>(null);

  const onSubmit = async (type: boolean) => {
    setLoading(true);
    let valid = await dynamicFormRef.current?.submit();

    try {
      if (valid) {
        if (valid?.fields?.length > 0) {
          try {
            await PriceGroupApi.selectVariants(id, {
              unitVariants: valid?.fields?.map((c: any) => ({
                unitVariantId: c?.unitVariantId,
                tier1Price: c?.tier1Price,
                tier2Price: c?.tier2Price,
                tier3Price: c?.tier3Price,
                tier4Price: c?.tier4Price,
                tier5Price: c?.tier5Price,
                tier6Price: c?.tier6Price,
                tier7Price: c?.tier7Price,
                tier8Price: c?.tier8Price,
                tier9Price: c?.tier9Price,
                tier10Price: c?.tier10Price,
              })),
              confirm: type,
            });
            await tableRef.current.reload();

            message.success("Амжилттай хадгаллаа");
          } catch (error: any) {
            message.error(error.message || "Жагсаалтын мэдээлэл буруу байна!");
          }
        } else {
          message.error("Жагсаалт хоосон байж болохгүй!");
        }
      } else {
        message.error("Жагсаалтын мэдээлэл буруу байна!!");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message || "");
    }
  };

  React.useEffect(() => {
    setLoading(true);
    if (id) {
      (async () => {
        try {
          const res = await PriceGroupApi?.get(id as string);

          setData(res);
          setLoading(false);
        } catch (error: any) {
          message?.error(error?.message);
          setLoading(false);
        }
      })();
    }
  }, [id]);

  return (
    <PageLayout
      title={"Жагсаалтын үнийн тохиргоо хийх"}
      subTitle="Жагсаалтан дахь барааны үнийн тохиргоо хийх"
      extra={[
        <Flex key={1} gap={"md"}>
          <Button onClick={() => onSubmit(false)} leftSection={<IconDeviceFloppy size={20} />} variant="outline">
            Хадгалах
          </Button>
          <Button onClick={() => onSubmit(true)} leftSection={<IconCheck size={20} />}>
            Батлах
          </Button>
        </Flex>,
      ]}>
      <LoadingOverlay visible={loading} />
      <Flex direction="column" gap="md">
        {preAlert ? (
          <Alert
            styles={{ root: { backgroundColor: "#FCDDEC" }, message: { color: "#44566c", fontWeight: 500 } }}
            onClose={() => setPreAlert(false)}
            radius="md"
            color="red"
            withCloseButton
            title="Анхаар!">
            Та жагсаалтанд үнийн тохиргоо хийхийн тулд үнэн зөв бүртгэн оруулах шаардлагатай ба алдаатай мэдээлэл буюу "Need fix"-тэй мэдээлэл жагсаалтанд
            үлдээж болохгүйг анхаарна уу.
          </Alert>
        ) : null}

        {data ? <DynamicFieldForm tableRef={tableRef} ref={dynamicFormRef} initData={data} /> : null}
      </Flex>
    </PageLayout>
  );
}
