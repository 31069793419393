import { Flex, Group, Text, Textarea } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  max?: number;
  minRows?: number;
  maxRows?: number;
  h?: any;
  w?: any;
};

export function TextareaField({ name, label, placeholder, disabled = false, required = false, max, maxRows, minRows, h, w }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <>
      <Textarea
        w={w}
        h={h}
        maxLength={max}
        minRows={minRows}
        maxRows={maxRows}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        error={!!error}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        withAsterisk
      />
      <Group align="apart" mt={"2px"}>
        <Text c="red" fz={"xs"}>
          {error && <Text>{error}</Text>}
        </Text>
        <Flex align="center" gap={4}>
          <Text c="dimmed" fz={"xs"}>
            {value?.length || 0}
          </Text>
          {max && (
            <Text c="dimmed" fz={"xs"}>
              / {max}
            </Text>
          )}
        </Flex>
      </Group>
    </>
  );
}
