import { Badge, Button, CloseButton, Divider, Flex, Group, LoadingOverlay, Modal, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { AdjustmentNoteApi } from "../../apis";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { FormLayout, PageLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";

import * as yup from "yup";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { usePermission } from "../permission";

type ISectionData = {
  id: string;
  name: string;
  isPublic: boolean;
  isDehub: boolean;
  createdAt: string;
};

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна.").nullable(),
});

export function AdjustmentPage() {
  const navigate = useNavigate();
  const [filters] = React.useState({ query: "" });
  const [action, setAction] = React.useState<any[]>([]);
  const [payload, setPayload] = React.useState<ISectionData | null>();
  const ref = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState(false);
  const ERP_REF_ADJ_NOTE = usePermission("ERP_REF_ADJ_NOTE");

  const [data] = React.useState<any>({
    name: "",
    isPublic: false,
    ...(payload ? payload : {}),
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (action[1]) {
        await AdjustmentNoteApi.update(action[1]?.id, { name: data.name });
      } else await AdjustmentNoteApi.create({ name: data.name });
      message.success("Үйлдэл амжилттай");
      reloadTable && reloadTable();
      onCancel && onCancel();
    } catch (error: any) {
      message.error(error.message || "Үйлдэл амжилтгүй.");
    }
    setLoading(false);
  };

  const columns = useHeader({
    actionAccess: ERP_REF_ADJ_NOTE,
    onClick: (key, item: any) => {
      switch (key) {
        case "edit": {
          setAction(["update", item]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await AdjustmentNoteApi.remove(item.id);
                  ref.current?.reload();
                  message.success("Амжилттай устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
    setPayload(null);
  };

  const reloadTable = () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Барааны нөөцийн хөдөлгөөний тайлбар"
        subTitle="Stock Adjustment хийхэд барааны үлдэгдэл өөрчлөгдсөн шалтгааныг сонгодог. Тэдгээр сонголтуудыг урьдчилан энд бүртгээрэй. "
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference")}>
            Буцах
          </Button>,
          <Button
            hidden={!ERP_REF_ADJ_NOTE.isCreate}
            key={2}
            onClick={() => {
              setPayload(null);
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Divider my="lg" />
        {ERP_REF_ADJ_NOTE.isView ? (
          <Table
            minWidth={"max-content"}
            ref={ref}
            name="inactivetype.list"
            columns={columns}
            filters={filters}
            loadData={(data) => AdjustmentNoteApi.list(data!)}
          />
        ) : (
          ERP_REF_ADJ_NOTE.accessDenied()
        )}
      </PageLayout>

      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} size="40rem" centered>
        {action[0] && (
          <Form validationSchema={schema} onSubmit={onSubmit} initialValues={{ ...data, ...(action[1] && action[1]) }}>
            {({ values }) => {
              return (
                <FormLayout
                  title="Барааны нөөцийн хөдөлгөөний тайлбар"
                  subTitle="Барааны нөөцийн хөдөлгөөний тайлбар мэдээлэл оруулна уу."
                  my={0}
                  extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
                  <LoadingOverlay visible={loading} />
                  <Flex gap={15} direction="column">
                    <TextField name="name" label="Нэр" placeholder="Нэр" />
                  </Flex>

                  <Group justify="right" mt={20}>
                    <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                      Болих
                    </Button>
                    <Button key={2} type="submit">
                      Хадгалах
                    </Button>
                  </Group>
                </FormLayout>
              );
            }}
          </Form>
        )}
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: ISectionData) => void;
  actionAccess: {
    isDelete: boolean;
    isEdit: boolean;
    isView: boolean;
  };
};

const useHeader = ({ onClick, actionAccess }: HeaderProps): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return <Text size="sm">{record.name || "-"}</Text>;
    },
  },
  {
    title: "Нээлттэй",
    dataIndex: "isPublic",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isPublic ? "green.5" : "gray.5"}>
          {record.isPublic ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "DeHub",
    dataIndex: "isDehub",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isDehub ? "green.5" : "gray.5"}>
          {record.isDehub ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    width: 120,
    render: (record) => {
      return (
        <Group gap={10}>
          <Tooltip label="Засах">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)} hidden={!actionAccess.isEdit}>
              <IconEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Устгах">
            <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)} hidden={!actionAccess.isDelete}>
              <IconTrash />
            </Button>
          </Tooltip>
        </Group>
      );
    },
  },
];
