import { Button, CloseButton, Divider, Grid, Group, LoadingOverlay } from "@mantine/core";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { CategoryApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { updateCategories } from "../../store/general";
import { HttpHandler } from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { DynamicFieldForm, IDynamicRef } from "./dynamic-field-form";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  action: string[]; // ['CLASSIFICATkION', itemTypeId, parentId, classificationId, subClassificationId, categoryId]
};

type IFormData = {
  name: string;
  description: string;
  itemTypeId: string;
  classificationId: string;
  subClassificationId: string;
  categoryId: string;
  // categoryFields: IField[];
};

export function SubCategoryForm({ onCancel, action: init }: Props) {
  const dispatch = useDispatch();
  const formRef = useRef<IFormRef<IFormData>>(null);
  const dynamicRef = useRef<IDynamicRef>(null);
  const [loading, setLoading] = React.useState(false);
  const { itemTypes, categories } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormData>({
    name: "",
    description: "",
    itemTypeId: init[1],
    classificationId: init[3],
    subClassificationId: init[4],
    categoryId: init[5],
  });

  const onSubmit = async (data: IFormData) => {
    let valid = await dynamicRef.current?.submit();
    if (!valid) return message.error("Динамик талбарууд алдаатай байна!");

    try {
      setLoading(true);

      await CategoryApi.createSub({
        name: data.name,
        description: data.description,
        itemTypeId: init[1],
        parentId: init[2],
        categoryFields: (valid?.fields || [])
          .sort((a, b) => a.sort - b.sort)
          .map((field) => ({
            name: field.name!,
            type: field.type!,
            numberUnit: field.numberUnit!,
            values: (field.values || [])?.map((value: string) => ({
              name: value,
              isDefault: field.defaultValueId === value,
            })),
          })),
      });

      setLoading(false);

      message.success("Таны хүсэлт амжилттай!");

      onCancel && onCancel();

      let res = await CategoryApi.select();

      dispatch(updateCategories(res));
    } catch (err) {
      setLoading(false);

      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <FormLayout
      title="Дэд категорийн мэдээлэл"
      subTitle="Дэд категорийн нэр, тайлбар, талбаруудыг оруулна."
      my={0}
      extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <LoadingOverlay visible={loading} />
      <Form ref={formRef} validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {() => {
          return (
            <>
              <Grid>
                <Grid.Col span={4}>
                  <SelectField
                    name="itemTypeId"
                    label="Нэр төрөл"
                    placeholder="Нэр төрөл"
                    options={itemTypes.map((c) => ({ label: c.name, value: c.id }))}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    name="classificationId"
                    label="Ангилал"
                    placeholder="Ангилал"
                    options={categories.map((c) => ({ label: c.name, value: c.id }))}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    name="subClassificationId"
                    label="Дэд ангилал"
                    placeholder="Дэд ангилал"
                    options={categories.map((c) => ({ label: c.name, value: c.id }))}
                    disabled
                  />
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Grid>
                <Grid.Col span={4}>
                  <SelectField
                    name="categoryId"
                    label="Категори"
                    placeholder="Категори"
                    options={categories.map((c) => ({ label: c.name, value: c.id }))}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="name" label="Дэд категори нэр" placeholder="Дэд категори нэр" />
                </Grid.Col>
              </Grid>

              <Divider my="lg" />

              <Grid>
                <Grid.Col span={12}>
                  <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
                </Grid.Col>
              </Grid>
            </>
          );
        }}
      </Form>

      <Divider my="lg" />

      <DynamicFieldForm ref={dynamicRef} />

      <Divider />

      <Group justify="right" mt={20}>
        <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
          Болих
        </Button>
        <Button key={2} onClick={() => formRef.current?.submit()}>
          Хадгалах
        </Button>
      </Group>
    </FormLayout>
  );
}
