import { ActionIcon, Center, CloseButton, Flex, NumberInput, Table, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalculator, IconCalendar, IconCopy, IconCurrencyTugrik } from "@tabler/icons-react";
import dayjs from "dayjs";
import { currencyFormat } from "../../utils";

const start = Date.now();

const columns = [
  { title: "#" },
  { title: "Барааны үнийн бүлэг" },
  { title: "Үндсэн нэгж" },
  { title: "Тоо ширхэг" },
  { title: "Валют" },
  { title: "Борлуулах нэгж үнэ (НӨАТ-гүй)" },
  { title: "Эхлэх хугацаа" },
  { title: "Дуусах хугацаа" },
  { title: "Стандарт үнэ" },
  { title: "Өөрчлөлт" },
];

export function TierPrices({ form }: { form: any }) {
  return (
    <Table w={"max-content"} horizontalSpacing="sm" verticalSpacing="sm">
      <Table.Thead>
        <Table.Tr>
          {columns.map((item: any, index: number) => {
            return <Table.Th key={index}>{item.title}</Table.Th>;
          })}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>
            <DatePickerInput
              styles={{
                calendarBase: {
                  maxWidth: "max-content",
                },
              }}
              valueFormat="YYYY-MM-DD"
              size="xs"
              placeholder="Эхлэх хугацаа"
              minDate={new Date(start)}
              rightSectionWidth={35}
              rightSection={
                <ActionIcon
                  onClick={() => {
                    form.setFieldValue(
                      "tierPrices",
                      form.values.tierPrices.map((item: any) => {
                        return {
                          ...item,
                          startDate: form.values?.startDate,
                        };
                      }),
                    );
                  }}
                  disabled={!form.values.startDate}
                  color="violet"
                  variant="transparent">
                  <IconCopy />
                </ActionIcon>
              }
              {...form.getInputProps("startDate")}
            />
          </Table.Td>
          <Table.Td>
            <DatePickerInput
              styles={{
                calendarBase: {
                  maxWidth: "max-content",
                },
              }}
              valueFormat="YYYY-MM-DD"
              size="xs"
              minDate={new Date(start)}
              placeholder="Дуусах хугацаа"
              rightSectionWidth={35}
              rightSection={
                <ActionIcon
                  onClick={() => {
                    form.setFieldValue(
                      "tierPrices",
                      form.values.tierPrices.map((item: any) => {
                        return {
                          ...item,
                          endDate: form.values?.endDate,
                        };
                      }),
                    );
                  }}
                  disabled={!form.values.endDate}
                  color="violet"
                  variant="transparent">
                  <IconCopy />
                </ActionIcon>
              }
              {...form.getInputProps(`endDate`)}
            />
          </Table.Td>
          <Table.Td>-</Table.Td>
          <Table.Td>
            <Flex align="center" gap={10}>
              <ActionIcon color="violet">
                <IconCalculator />
              </ActionIcon>
              <Text fz={"sm"} fw={500}>
                Бодох
              </Text>
            </Flex>
          </Table.Td>
        </Table.Tr>
        {form.values.tierPrices.map((element: any, index: number) => {
          return (
            <Table.Tr key={index}>
              <Table.Td>{index + 1}</Table.Td>
              <Table.Td>{element?.name ?? "-"}</Table.Td>
              <Table.Td>{element?.unitName ?? "-"}</Table.Td>
              <Table.Td>{element?.tierNo ?? "-"}</Table.Td>
              <Table.Td>{element?.currency ?? "-"}</Table.Td>
              <Table.Td>
                <NumberInput
                  size="xs"
                  min={0}
                  leftSection={<IconCurrencyTugrik size={18} stroke="1.5" color="gray" opacity={0.8} />}
                  placeholder="0.00"
                  rightSection={
                    <CloseButton
                      mr={"6px"}
                      onClick={() => form.setFieldValue(`tierPrices.${index}.price`, undefined)}
                      variant="transparent"
                      size={"xs"}
                      color="gray.6"
                    />
                  }
                  {...form.getInputProps(`tierPrices.${index}.price`)}
                />
              </Table.Td>
              <Table.Td>
                <DatePickerInput
                  icon={<IconCalendar size={18} />}
                  styles={{
                    calendarBase: {
                      maxWidth: "max-content",
                    },
                  }}
                  size="xs"
                  placeholder="Эхлэх хугацаа"
                  minDate={new Date(start)}
                  clearable
                  defaultValue={form.values?.tierPrices[index]?.startDate as string}
                  {...form.getInputProps(`tierPrices.${index}.startDate`)}
                  value={form.values?.tierPrices[index]?.startDate ? new Date(dayjs(form.values?.tierPrices[index]?.startDate).format()) : null}
                  onChange={(e) => {
                    form.setFieldValue(`tierPrices.${index}.startDate`, e);
                    form.setFieldValue(`tierPrices.${index}.endDate`, null);
                  }}
                  valueFormat="YYYY-MM-DD"
                />
              </Table.Td>
              <Table.Td>
                <DatePickerInput
                  icon={<IconCalendar size={18} />}
                  styles={{
                    calendarBase: {
                      maxWidth: "max-content",
                    },
                  }}
                  size="xs"
                  minDate={
                    form.values?.quantityPrices[index]?.startDate
                      ? new Date(dayjs(form.values?.quantityPrices[index]?.startDate).add(1, "day").format())
                      : new Date(start)
                  }
                  placeholder="Дуусах хугацаа"
                  disabled={!form.values.tierPrices[index].startDate}
                  {...form.getInputProps(`tierPrices.${index}.endDate`)}
                  value={form.values?.tierPrices[index]?.endDate ? new Date(dayjs(form.values?.tierPrices[index]?.endDate).format()) : null}
                  onChange={(e) => form.setFieldValue(`tierPrices.${index}.endDate`, e)}
                  valueFormat="YYYY-MM-DD"
                />
              </Table.Td>
              <Table.Td>
                <Text ta="end">{currencyFormat(form.values?.standardPrice ?? 0)}</Text>
              </Table.Td>
              <Table.Td>
                <Text c={(form.values?.tierPrices[index]?.price || 0) - (form.values?.standardPrice || 0) < 0 ? "red" : "green"} ta="end">
                  {currencyFormat((form.values?.tierPrices[index]?.price || 0) - (form.values?.standardPrice || 0))}
                </Text>
              </Table.Td>
            </Table.Tr>
          );
        })}
        <Table.Tr>
          <Table.Td colSpan={columns.length}>
            {form.values.tierPrices.length === 0 && (
              <Center bg={"gray.0"} p={"lg"}>
                <Text fz={"sm"} c="gray.9">
                  Одоогоор юу ч алга
                </Text>
              </Center>
            )}
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
}
