import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/goods", { offset, filter });
};

export const create = async (data: { name: string }) => {
  return httpRequest.post("/erp/goods", data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/erp/goods/${id}`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/goods/${id}`);
};

export const additionalInfo = async (id: string, data: any) => {
  return httpRequest.put(`/erp/goods/${id}/additional_info`, data);
};

export const price = async (data: any) => {
  return httpRequest.put(`/erp/goods/price`, data);
};

export const priceOld = async (id: string, data: any) => {
  return httpRequest.put(`/erp/goods/${id}/price`, data);
};

export const updateVariant = async (id: string, data: any) => {
  return httpRequest.put(`/erp/goods/${id}/variant`, data);
};

export const unitSelect = async (data: any) => {
  return httpRequest.get(`/erp/goods/unit`, data);
};

export const setPrice = async (data: any) => {
  return httpRequest.put(`/erp/goods/set_price`, data);
};

export const unit = async (data: any) => {
  return httpRequest.post(`/erp/goods/unit`, data);
};

export const statusChange = async (id: string, data: { status: "ACTIVE" | "INACTIVE"; inactiveTypeId?: string }) => {
  return httpRequest.put(`/erp/goods/variant/${id}/status_change`, data);
};
