import { Button, CloseButton, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { CategoryApi } from "../../apis";
import { IClassification } from "../../interfaces/IClassification";
import { IGeneral } from "../../interfaces/IGeneral";
import { updateCategories } from "../../store/general";
import { HttpHandler } from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object().shape({
  itemType: yup.string().required("Заавал бөглөнө!").nullable(),
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  title: string;
  subTitle: string;
  action: string[]; // ['CLASSIFICATION', itemTypeId, parentId]
  payload?: IClassification;
  reloadTable?: any;
};

export function ReferenceCategoryForm({ title, subTitle, onCancel, action, payload, reloadTable }: Props) {
  const dispatch = useDispatch();
  const { itemTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IClassification>({
    id: "",
    itemType: "",
    name: "",
    description: "",
    ...(payload ? { ...payload, itemType: payload.itemType?.id as string } : {}),
  });

  const onSubmit = async (data: IClassification) => {
    try {
      setLoading(true);

      if (payload) {
        await CategoryApi.update(payload.id, {
          name: data.name,
          description: data.description,
        });
      } else {
        await CategoryApi.create({
          name: data.name,
          description: data.description,
          type: "CLASSIFICATION",
          itemTypeId: data.itemType,
        });
      }
      reloadTable();
      setLoading(false);

      message.success("Таны хүсэлт амжилттай!");

      onCancel && onCancel();

      let res = await CategoryApi.select();

      dispatch(updateCategories(res));
    } catch (err) {
      setLoading(false);

      message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue, errors }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Flex gap={15} direction="column">
              {action[0] === "new" && (
                <SelectField name="itemType" label="Нэр төрөл" placeholder="Нэр төрөл" options={itemTypes.map((c) => ({ label: c.name, value: c.id }))} />
              )}
              <TextField name="name" label="Нэр" placeholder="Нэр" />
              <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
            </Flex>

            <Group justify="right" mt={20}>
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
}
